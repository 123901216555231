import { Box, Grow } from "@mui/material";
import { DestinationMenuItemMobile } from "components/DestinationMenuItemMobile";
import { IDestination } from "types/Destination";
type DestinationMenuMobileProps = {
  onClose: () => void;
  isTransparent: boolean;
  destinations: IDestination[];
};

export const DestinationMenuMobile = ({
  onClose,
  isTransparent,
  destinations
}: DestinationMenuMobileProps) => {
  return (
    <Grow in style={{ transformOrigin: "0 0 0" }}>
      <Box
        sx={{
          backgroundColor: isTransparent ? "#79797980" : "common.white",
          width: 147,
          mt: -5,
          mb: 2
        }}>
        {destinations.map(d => (
          <DestinationMenuItemMobile
            onClose={onClose}
            isTransparent={isTransparent}
            key={d.id}
            label={d.name}
            routes={`destinos/${d.id}/paquetes`}
          />
        ))}
      </Box>
    </Grow>
  );
};
