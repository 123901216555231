import { Box, Typography } from "@mui/material";
import { IconName } from "types/Common";
import { Icon } from "components/icon";

type LabelModalProps = {
  icon?: IconName;
  label: string;
};

export const LabelQuote = ({ icon, label }: LabelModalProps) => {
  return (
    <Box display="flex" alignItems="center" gap={1}>
      {icon && <Icon name={icon} size={20} />}
      <Typography color="black" fontWeight={500} sx={{ fontSize: { lg: 15, sm: 14, xs: 12 } }}>
        {label}
      </Typography>
    </Box>
  );
};
