import React from "react";
import { Box, Card, IconButton, Modal, Typography } from "@mui/material";
import { Icon } from "components/icon";
import { TitleComing } from "../TitleComing";
import { IQuote } from "types/Quote";
import { Subtitle } from "../Subtitle";
import { Location } from "icons";
import { Reactions } from "../Reactions";
import { LabelQuote } from "../LabelQuote";
import { TourPlaceList } from "../TourPlaceList";
import { useSection } from "pages/Quote/components/SecondSection/hooks/useSection";
type ModalParams = {
  quote: IQuote;
  index: number;
  open: boolean;
  onClose(): void;
};

export const TourDescriptionModal = ({ open, onClose, quote, index }: ModalParams) => {
  const tour = quote.tours[index];
  const destination = tour.destination.name;
  const { date, showDate, title } = useSection(quote, index);
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
      }}>
      <Card
        sx={{
          display: "flex",
          position: "relative",
          flexDirection: "column",
          maxHeight: "90%",
          width: { lg: "77%", md: "96%", sm: "89%", xs: "92%" }
        }}>
        <IconButton
          disableRipple
          disableFocusRipple
          disableTouchRipple
          style={{ paddingLeft: 0 }}
          sx={{ position: "absolute", top: 7, right: 7 }}
          onClick={onClose}>
          <Icon name="CloseModal" size={20} />
        </IconButton>
        <Box flex={1} sx={{ overflowY: "scroll" }} p={{ md: 5, xs: 1 }}>
          <TitleComing>{title}</TitleComing>
          <Subtitle icon="Culture" label="Cultural" />
          <Box display="flex" alignItems="center">
            <Box p={1}>
              <Location sx={{ fontSize: 42 }} />
            </Box>
            <Typography variant="h2" color="secondary">
              {tour.name} {destination}
            </Typography>
          </Box>
          {showDate && (
            <Box mt={4}>
              <LabelQuote icon="Calendar" label={date.format("DD MMM [,] YYYY")} />
            </Box>
          )}
          <TourPlaceList places={tour.places} />
          <Box width="100%" display="flex" mt={5} flexDirection="column" gap={3}>
            <Typography variant="body1" p={2} whiteSpace="pre-line">
              {tour.description?.specificDescription}
            </Typography>
          </Box>
        </Box>
        <Reactions />
      </Card>
    </Modal>
  );
};
