import { Box, Link } from "@mui/material";
import { Icon } from "components/icon";
import { IconName } from "types/Common";

type ContactLabelProps = {
  icon?: IconName;
  label: string;
  link?: string;
};

export const ContactLabel = ({ icon, label, link }: ContactLabelProps) => {
  return (
    <Box display="flex" gap={1} alignItems="center">
      {icon && <Icon name={icon} size={20} />}
      <Link
        aria-label="ContactLabel"
        href={link}
        variant="body1"
        underline="none"
        color="secondary">
        {label}
      </Link>
    </Box>
  );
};
