import { SvgIcon, SvgIconProps } from "@mui/material";
export const Plane = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.65047 20.4462V19.6252H21.5869V20.4462H2.65047Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1534 8.4038L8.76561 13.7144C8.13234 14.0099 7.09223 14.2571 6.11663 14.3446C5.11924 14.4339 4.58604 14.3219 4.40801 14.2265C4.44475 14.1763 4.51316 14.0974 4.63921 13.9861L4.93463 13.7261L3.30009 11.6957L4.0403 11.3506L7.04384 12.494L11.2915 10.4758L7.25622 6.38555L8.45107 6.33418L14.0952 9.17275L17.6427 7.51857C18.9385 6.91472 20.0297 6.86147 20.887 7.36129C21.103 7.48735 21.1788 7.59743 21.1768 7.64029C21.1768 7.64335 21.1517 7.93818 20.1534 8.4038ZM21.3016 6.64984C19.9263 5.84847 18.3635 6.27421 17.2947 6.77216L14.1093 8.25818L8.6303 5.5022L5.36718 5.64229L9.89176 10.2292L7.008 11.599L4.00774 10.4574L1.99951 11.394L3.81448 13.6477C3.62301 13.8766 3.44916 14.1989 3.60718 14.5372C3.81328 14.9795 4.42206 15.2016 5.42095 15.2016C5.62586 15.2016 5.84751 15.1925 6.08468 15.1735C7.18155 15.0866 8.37042 14.807 9.11361 14.4602L20.5014 9.15012C21.4853 8.6919 21.9752 8.20986 21.9991 7.67763C22.012 7.4017 21.9011 6.99918 21.3016 6.64984Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
