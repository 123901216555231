import { SvgIcon, SvgIconProps } from "@mui/material";
export const WalletMoney = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.013 15.386H10.3939V16.005H9.7749V17.9185H11.013V18.5938H9.7749V19.2128H10.3939V19.8882H11.013V19.2128H11.6883V17.2994H10.3939V16.6804H11.6883V16.005H11.013V15.386Z"
          fill="#606A78"
        />
        <path
          d="M18.1069 14.0918H3.3623V21.1264H18.1069V14.0918ZM16.5312 20.5074C16.6437 20.0572 16.9814 19.7195 17.4316 19.607V20.5074H16.5312ZM17.4316 15.6676C16.9814 15.555 16.6437 15.2173 16.5312 14.7671H17.4316V15.6676ZM15.8558 14.7108C15.9684 15.4987 16.6437 16.174 17.4316 16.2866V18.9316C16.6437 19.0442 15.9684 19.7195 15.8558 20.5074H12.7043C13.6048 19.8883 14.2238 18.8191 14.2238 17.6373C14.2238 16.4554 13.6048 15.3861 12.7043 14.7671H15.8558V14.7108ZM10.7346 20.5074C9.15887 20.5074 7.86448 19.213 7.86448 17.6373C7.86448 16.0615 9.15887 14.7671 10.7346 14.7671C12.3104 14.7671 13.6048 16.0615 13.6048 17.6373C13.6048 19.213 12.3104 20.5074 10.7346 20.5074ZM4.88179 14.7108C4.76924 15.161 4.43159 15.4987 3.98137 15.6113V14.7108H4.88179ZM3.98137 19.607C4.43159 19.7195 4.76924 20.0572 4.88179 20.5074H3.98137V19.607ZM5.55713 20.5074C5.44457 19.7195 4.76925 19.0442 3.98137 18.9316V16.2866C4.76925 16.174 5.44457 15.4987 5.55713 14.7108H8.70863C7.8082 15.3299 7.18918 16.3991 7.18918 17.581C7.18918 18.7628 7.8082 19.8321 8.70863 20.4511H5.55713V20.5074Z"
          fill="#606A78"
        />
        <path
          d="M21.3142 2.55469H9.77736C9.4397 2.55469 9.15832 2.83606 9.15832 3.17372V11.5027H6.56955V9.58933H5.27519C4.54358 9.58933 3.98082 10.1521 3.98082 10.8837V12.7971H2.06738V22.4205H19.4007V21.1261H20.6951V14.0915H21.3142C21.6518 14.0915 21.9332 13.8101 21.9332 13.4724V3.17372C21.9332 2.83606 21.6518 2.55469 21.3142 2.55469ZM14.5609 3.17372H16.4744V4.63691L16.1367 4.29927L15.5176 4.9183L14.8986 4.29927L14.5609 4.63691V3.17372ZM9.77736 3.17372H13.9419V6.2127L14.8986 5.25598L15.5176 5.87502L16.1367 5.25598L17.0934 6.2127V3.17372H21.2579V7.67589H16.1367V11.5027H9.77736V3.17372ZM5.27519 10.2646H5.89422V11.559H5.27519C4.93752 11.559 4.65615 11.2776 4.65615 10.94C4.65615 10.6023 4.88125 10.2646 5.27519 10.2646ZM18.7254 21.8014H2.68645V13.4724H18.7254V21.8014ZM20.0198 20.5071H19.4007V12.7971H4.65615V11.9529C4.82498 12.0655 5.05008 12.1218 5.27519 12.1218H20.0198V20.5071ZM20.6388 13.4724V11.559H16.812V8.35123H21.3142V13.4724H20.6388Z"
          fill="#606A78"
        />
        <path d="M20.6385 10.2649H17.4307V10.884H20.6385V10.2649Z" fill="#606A78" />
        <path d="M18.0497 8.97046H17.4307V9.58953H18.0497V8.97046Z" fill="#606A78" />
        <path d="M20.6395 8.97046H18.7261V9.58953H20.6395V8.97046Z" fill="#606A78" />
      </svg>
    </SvgIcon>
  );
};
