import { SvgIcon, SvgIconProps } from "@mui/material";
export const PaperPlane = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.69094 19.5988L9.79118 19.6441C12.0309 16.0825 14.2695 12.5208 16.5625 8.87408C16.4405 8.92862 16.427 8.9312 16.4189 8.93931C14.2211 11.2914 12.0221 13.6434 9.82951 16.0007C9.75411 16.0821 9.69796 16.2163 9.69688 16.3268C9.68735 17.4174 9.69094 18.5083 9.69094 19.5988ZM4.02723 15.2481C4.20143 15.2956 4.26531 15.3281 4.32901 15.3281C5.74022 15.331 7.15142 15.3369 8.56245 15.3214C8.70264 15.32 8.87539 15.216 8.97527 15.1066C11.4601 12.3738 13.9373 9.63365 16.4141 6.89313C16.4862 6.81353 16.5368 6.71365 16.5969 6.62335C16.5667 6.59497 16.5364 6.5666 16.5058 6.53785C12.3699 9.42469 8.23493 12.3115 4.02723 15.2481ZM20.4884 6.9204C20.5105 6.75124 20.4796 6.4951 20.1596 6.39744C19.8512 6.30346 19.6761 6.47925 19.5183 6.71992C17.3759 9.97677 15.2282 13.2303 13.0835 16.4857C12.7916 16.9294 12.8517 17.1561 13.3275 17.4137C15.0047 18.3218 16.682 19.2277 18.3612 20.1317C18.9084 20.4266 19.1933 20.2865 19.2559 19.6651C19.4745 17.5058 19.6804 15.345 19.8895 13.185C20.0869 11.1344 20.2816 9.08378 20.4884 6.9204ZM19.598 21.97C19.3972 21.8727 19.2365 21.8042 19.0842 21.7209C16.8055 20.4745 14.5245 19.2317 12.2524 17.9724C11.937 17.7977 11.771 17.8047 11.5663 18.1357C10.8662 19.2627 10.1347 20.3702 9.40464 21.4777C9.31592 21.6118 9.16062 21.7017 9.03609 21.8119C8.9974 21.6608 8.9274 21.5097 8.9256 21.3579C8.90562 19.7536 8.88241 18.1493 8.89429 16.5458C8.89789 16.1193 8.76112 16.0195 8.35028 16.018C6.44187 16.0121 4.53327 15.973 2.62484 15.9347C2.47818 15.9318 2.33296 15.8455 2.18701 15.798C2.28653 15.6797 2.36571 15.5326 2.48807 15.4468C8.76274 11.0316 15.0412 6.62115 21.3223 2.21442C21.4485 2.12634 21.6102 2.09023 21.7558 2.02979C21.7753 2.18494 21.8255 2.34342 21.8093 2.49489C21.4967 5.38024 21.1749 8.2645 20.857 11.1491C20.5647 13.8004 20.2735 16.4518 19.9817 19.1031C19.891 19.9272 19.8013 20.7513 19.7052 21.575C19.6912 21.6918 19.6441 21.8046 19.598 21.97Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
