import { combineReducers, ActionFromReducersMapObject, StateFromReducersMapObject } from "redux";
import { PackageReducer } from "./PackageReducer";
const allReducers = {
  PackageReducer
};
const reducers = combineReducers<
  StateFromReducersMapObject<typeof allReducers>,
  ActionFromReducersMapObject<typeof allReducers>
>(allReducers);
export default reducers;
