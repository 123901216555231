import { Container } from "../../components/Container";
import { Body, Content } from "../../components/Body";
import { Title } from "../../components/Title";
import content from "./Text.json";
import { useTitleChange } from "hooks/useTitleChange";

export const PrivacyPolicy = () => {
  useTitleChange("Políticas de Privacidad");
  return (
    <Container>
      <Title>Políticas de Privacidad</Title>
      <Body content={content as Content[]} />
    </Container>
  );
};
