import { Box, BoxProps, Breadcrumbs, IconButton, Link, useTheme } from "@mui/material";
import { BreadCrumbItem } from "types/BreadCrumbItem";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ArrowLeft } from "icons";
import { CustomTheme } from "helpers/Theme";

type BreadCrumbsProps = {
  items: BreadCrumbItem[];
} & BoxProps;

export const BreadCrumbs = ({ items, ...boxProps }: BreadCrumbsProps) => {
  const { palette } = useTheme<CustomTheme>();
  const navigate = useNavigate();
  const lastIndex = items.length - 1;
  return (
    <Box mt={10} flexDirection="row" display="flex" alignItems="center" {...boxProps}>
      <IconButton
        aria-label="go back button"
        size="small"
        sx={{ mr: 2 }}
        onClick={() => navigate(-1)}>
        <ArrowLeft />
      </IconButton>
      <Breadcrumbs aria-label="breadcrumb">
        {items.map((i, index) => (
          <Link
            aria-label="BreadCrumbs"
            key={index}
            component={RouterLink}
            underline="none"
            color={index === lastIndex ? palette.primary.main["500"] : palette.neutral["700"]}
            to={i.to}>
            {i.label}
          </Link>
        ))}
      </Breadcrumbs>
    </Box>
  );
};
