import { useRef, useEffect } from "react";

type AutoScrollProps = {
  time?: number;
};

export const AutoScroll = ({ time }: AutoScrollProps) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  let scrollTimeout: NodeJS.Timeout | null = null;

  const scrollDown = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
      scrollTimeout = null;
    }
  };
  useEffect(() => {
    if (scrollRef.current) {
      const scrollTarget = scrollRef.current.offsetTop;
      scrollTimeout = setTimeout(() => {
        window.scrollTo({ top: scrollTarget, behavior: "smooth" });
        scrollTimeout = null;
      }, time);
    }
    window.addEventListener("scroll", scrollDown);
    return () => {
      window.removeEventListener("scroll", scrollDown);
      scrollDown();
    };
  }, []);

  return <div ref={scrollRef} />;
};
