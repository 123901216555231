import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";
export const HeartOutline = (props: CustomSvgProps) => {
  const SvgSize = props.size || 14;
  return (
    <SvgIcon
      {...props}
      width={props.width || 14}
      height={props.height || 14}
      fill="none"
      viewBox="0 0 14 14"
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        fill={props.htmlColor || "#fff"}
        d="M7 2.988l-.627-.645C4.9.829 2.2 1.352 1.225 3.255.767 4.15.664 5.442 1.5 7.092c.805 1.588 2.48 3.49 5.5 5.562 3.02-2.072 4.694-3.974 5.5-5.562.836-1.65.733-2.942.275-3.837C11.8 1.352 9.1.828 7.627 2.342L7 2.988zm0 10.72C-6.416 4.843 2.869-2.077 6.846 1.583c.052.049.104.098.154.15.05-.051.1-.101.154-.149C11.13-2.078 20.416 4.842 7 13.708z"></path>
    </SvgIcon>
  );
};
