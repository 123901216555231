import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { CardPost } from "components/CardPost";
import { CustomTheme } from "helpers/Theme";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { PageBlogPostCollection } from "types/Posts";
dayjs.locale("es");
dayjs.extend(localizedFormat);
const getStyles = makeStyles((theme: CustomTheme) => ({
  sliderContainer: {
    width: "100%",
    paddingBlock: 20
  },
  slider: {
    display: "flex",
    gap: 20,
    [theme.breakpoints.only("xs")]: {
      marginLeft: 300
    }
  }
}));
type RelatedPostsProps = {
  item?: PageBlogPostCollection;
};
export const RelatedPosts = ({ item }: RelatedPostsProps) => {
  const styles = getStyles();
  const post = [0, 1];
  const [controller, setController] = useState(0);
  const carrousel = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setController((carrousel.current?.offsetWidth ?? 0) / post.length);
  }, []);
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4">Noticias relacionadas</Typography>
      <motion.div
        ref={carrousel}
        className={styles.sliderContainer}
        whileTap={{ cursor: "grabbing" }}>
        <motion.div
          className={styles.slider}
          drag="x"
          dragConstraints={{ right: 0, left: -controller }}>
          {item?.items.map(item => (
            <CardPost
              key={item.sys.id}
              id={item.sys.id}
              avatar={item.author.avatar.url}
              author={item.author.name}
              date={dayjs(item?.publishedDate).format("DD/MM/YYYY")}
              image={item.featuredImage.url}
              title={item.title}
            />
          ))}
        </motion.div>
      </motion.div>
    </Box>
  );
};
