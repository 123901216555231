import { Box, Button, styled, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CustomTheme } from "helpers/Theme";
import logo from "assets/Logo.png";
import logoTransparent from "assets/LogoTransparent.png";
import { Link as RouterLink, useMatch } from "react-router-dom";
import { useState } from "react";
import { DestinationMenu } from "components/DestinationMenu";
import { useHideAfterTimeOut } from "hooks/useHideAfterTimeOut";
const getStyles = makeStyles<CustomTheme>(theme => ({
  headerContent: {
    display: "flex",
    flex: 1,
    height: 75,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.only("sm")]: {
      paddingInline: 40
    },
    [theme.breakpoints.only("md")]: {
      paddingInline: 100
    },
    [theme.breakpoints.only("lg")]: {
      maxWidth: "77%",
      margin: "auto"
    },
    [theme.breakpoints.up("xl")]: {
      maxWidth: "85vw",
      margin: "auto"
    }
  }
}));

const Line = styled("div", {
  skipSx: true,
  skipVariantsResolver: true
})(({ theme }: { theme?: CustomTheme }) => ({
  height: 2,
  width: "auto",
  position: "relative",
  top: -3,
  left: 1,
  backgroundColor: theme?.palette.primary.main
}));
export const Desktop = ({ isTransparent }: { isTransparent: boolean }) => {
  const styles = getStyles();
  const match = useMatch(location.pathname);
  const isAboutUs = match?.pathname === "/nosotros";
  const isDestination = match?.pathname === "/destinos";
  const isTour = match?.pathname === "/tour";
  const isBlog = match?.pathname === "/blog";
  const color = isTransparent ? "common.white" : "common.black";

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const { leaveAnchor, overDialog } = useHideAfterTimeOut(handleClose, 100);
  return (
    <Box display="flex" width="100%" bgcolor={isTransparent ? "#79797980" : "common.white"}>
      <Box component="nav" className={styles.headerContent}>
        <Link component={RouterLink} to="/" aria-label="logo">
          <img
            src={isTransparent ? logoTransparent : logo}
            width={111}
            height={50}
            alt="logo-desktop"
          />
        </Link>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Link
            component={RouterLink}
            to="/nosotros"
            aria-label="nosotros"
            underline="none"
            variant="body1"
            mr={10}
            color={color}>
            Nosotros
            {isAboutUs && <Line />}
          </Link>

          <Link
            component={RouterLink}
            to="/destinos"
            aria-label="destinos"
            underline="none"
            variant="body1"
            mr={10}
            sx={{ paddingBlock: isDestination ? 5.375 : 5.626 }}
            color={color}
            onMouseLeave={leaveAnchor}
            onMouseOver={openMenu}>
            Destinos
            {isDestination && <Line />}
          </Link>

          <DestinationMenu
            anchorEl={anchorEl}
            onClose={handleClose}
            mouseOver={overDialog}
            open={open}
            isTransparent={isTransparent}
          />

          <Link
            component={RouterLink}
            to="/tour"
            underline="none"
            variant="body1"
            aria-label="tour"
            mr={10}
            color={color}>
            Tours
            {isTour && <Line />}
          </Link>

          <Link
            component={RouterLink}
            to="/blog"
            underline="none"
            variant="body1"
            aria-label="blog"
            mr={10}
            color={color}>
            Blog
            {isBlog && <Line />}
          </Link>

          <Link component={RouterLink} to="/contactanos" underline="none">
            <Button
              color="secondary"
              variant="outlined"
              aria-label="contactanos"
              sx={{ borderColor: color, borderRadius: 0, width: 160, color: color }}>
              Contacto
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
