import { SvgIcon, SvgIconProps } from "@mui/material";

export const TikTok = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      fontSize="small"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.40425 0.0151883C9.40854 0 10.4071 0.00900048 11.4044 0C11.4649 1.14812 11.8873 2.31762 12.7471 3.12935C13.6052 3.96134 14.819 4.34217 16 4.47099V7.49121C14.8933 7.45577 13.7814 7.23076 12.7771 6.76499C12.3397 6.57148 11.9322 6.32227 11.5334 6.06745C11.5282 8.25907 11.5426 10.4479 11.519 12.6305C11.4591 13.679 11.1052 14.7225 10.4813 15.5866C9.4776 17.025 7.73549 17.9627 5.94619 17.992C4.84867 18.0533 3.7523 17.7608 2.81707 17.2219C1.26719 16.3286 0.176571 14.6933 0.0177267 12.9382C-0.00228147 12.5665 -0.00535399 12.1941 0.0085183 11.8221C0.146644 10.395 0.868926 9.02973 1.99004 8.10099C3.2608 7.01925 5.04089 6.50397 6.70761 6.80886C6.72315 7.91986 6.67768 9.02973 6.67768 10.1407C5.91626 9.89997 5.0265 9.96747 4.3612 10.4192C3.87445 10.7326 3.5066 11.1937 3.31432 11.7316C3.15548 12.1118 3.20094 12.5343 3.21015 12.9382C3.39259 14.169 4.60349 15.2035 5.89612 15.0916C6.75307 15.0826 7.57434 14.5965 8.02095 13.8849C8.16541 13.6357 8.32713 13.3809 8.33576 13.0878C8.41115 11.7462 8.38123 10.4102 8.39044 9.06855C8.39677 6.04495 8.38065 3.02922 8.40425 0.0151883Z"
        fill={props.htmlColor || "white"}
      />
    </SvgIcon>
  );
};
