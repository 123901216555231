/* eslint-disable @typescript-eslint/no-explicit-any */
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document, BLOCKS } from "@contentful/rich-text-types";
import { Typography } from "@mui/material";
import { resolveRichContent } from "helpers/Contentful";
import { PageBlogPost } from "types/Posts";

const options = {
  renderNode: {
    [BLOCKS.HEADING_2]: (_: any, children: any) => {
      return <Typography variant="h2">{children}</Typography>;
    },
    [BLOCKS.PARAGRAPH]: (_: any, children: any) => {
      return <Typography variant="body1">{children}</Typography>;
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      return (
        <img
          src={node.data.target.fields.url}
          height={node.data.target.fields.height}
          width={node.data.target.fields.width}
          alt={node.data.target.fields.title}
        />
      );
    }
  }
};

type PostRichContentProps = {
  data: PageBlogPost | undefined;
};

export const PostRichContent = ({ data }: PostRichContentProps) => {
  return (
    <>{documentToReactComponents(resolveRichContent(data?.content).json as Document, options)}</>
  );
};
