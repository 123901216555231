import { SvgIcon, SvgIconProps } from "@mui/material";
export const Passport = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.58503 8.68613C6.32134 8.68613 4.48674 10.5209 4.48674 12.7846C4.48674 15.0475 6.32135 16.8822 8.58503 16.8822C10.8487 16.8822 12.6837 15.0475 12.6837 12.7846C12.6837 10.5209 10.8487 8.68613 8.58503 8.68613ZM8.48523 9.37896C8.51902 9.37818 8.55124 9.36934 8.58503 9.36934C8.61961 9.36934 8.65104 9.37818 8.68562 9.37896C8.94142 9.77248 9.2538 10.3946 9.44201 11.2476H7.72804C7.91783 10.3914 8.23021 9.77013 8.48523 9.37896ZM9.55636 11.9308C9.58701 12.1995 9.60941 12.4788 9.60941 12.7846C9.60941 13.0896 9.58701 13.3688 9.55558 13.6384H7.61449C7.58306 13.3688 7.56066 13.0887 7.56066 12.7846C7.56066 12.4788 7.58306 12.1995 7.61449 11.9308H9.55636ZM7.61606 9.52622C7.39169 9.96641 7.17126 10.5394 7.02941 11.2476H5.55198C5.97281 10.4211 6.71349 9.79587 7.61606 9.52622ZM5.16966 12.7846C5.16966 12.4877 5.21956 12.2052 5.29147 11.9308H6.92567C6.89777 12.2012 6.87734 12.4821 6.87734 12.7846C6.87734 13.0864 6.89777 13.3672 6.92646 13.6384H5.29147C5.21956 13.3632 5.16966 13.0807 5.16966 12.7846ZM5.55198 14.3208H7.03138C7.17362 15.0306 7.39484 15.6075 7.61528 16.0421C6.71349 15.7726 5.97281 15.1464 5.55198 14.3208ZM8.68405 16.1894C8.65026 16.1902 8.61883 16.199 8.58503 16.199C8.55124 16.199 8.51981 16.1902 8.48523 16.1894C8.24082 15.8127 7.92215 15.19 7.72883 14.3208H9.44123C9.2483 15.1891 8.92964 15.812 8.68405 16.1894ZM9.55558 16.0421C9.77602 15.6075 9.99645 15.0306 10.1391 14.3208H11.6181C11.1973 15.1464 10.457 15.7726 9.55558 16.0421ZM12.0004 12.7846C12.0004 13.0807 11.9505 13.3632 11.879 13.6384H10.2436C10.2727 13.3672 10.2927 13.0864 10.2927 12.7846C10.2927 12.4821 10.2727 12.2012 10.2444 11.9308H11.879C11.9505 12.2052 12.0004 12.4877 12.0004 12.7846ZM10.1414 11.2476C9.99998 10.5394 9.77915 9.96728 9.554 9.52621C10.4562 9.79498 11.1972 10.4211 11.6181 11.2476H10.1414Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.17106 19.6162V18.9338H12V19.6162H5.17106Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0624 15.5092L16.404 15.3326L16.7576 14.0146L17.416 14.1913L17.0624 15.5092Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4695 21.4437L14.8111 21.2673L16.049 16.648L16.7074 16.8245L15.4695 21.4437Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.5498 5.12763L12.633 3.00637C12.5148 2.97494 12.3953 2.95972 12.2778 2.95972C11.6754 2.95972 11.1234 3.36208 10.9599 3.97201L10.2452 6.63727H3.1209C2.74369 6.63727 2.43799 6.94306 2.43799 7.32048V20.9807C2.43799 21.3581 2.74369 21.6639 3.1209 21.6639H11.0098L15.7769 22.941C15.8952 22.9733 16.0142 22.9885 16.1309 22.9885C16.7345 22.9885 17.2857 22.5853 17.45 21.9754L21.5153 6.80064C21.7109 6.07233 21.2787 5.32321 20.5498 5.12763ZM3.1209 20.9807V7.32048H13.3658C13.7427 7.32048 14.0492 7.62628 14.0492 8.00369V20.2983C14.0492 20.6741 13.7427 20.9807 13.3658 20.9807L3.1209 20.9807ZM20.8563 6.62362L16.7903 21.7983C16.7105 22.0969 16.4394 22.3053 16.1309 22.3053C16.0716 22.3053 16.0119 22.2972 15.9533 22.2812L13.5721 21.643C14.2279 21.5433 14.7325 20.9824 14.7325 20.2983V14.8834L15.0826 14.9767L15.4358 13.6577L14.7757 13.4807L14.7325 13.644V8.21539C14.945 8.27246 15.191 8.33847 15.4291 8.40281L16.2374 11.0431L16.8028 11.1953L16.749 8.75616C17.1207 8.85587 17.4999 8.95734 17.7478 9.02414L18.1871 9.79822L18.5644 9.89881L18.4995 8.82129L19.0948 7.91999L18.7176 7.81862L17.9506 8.2701C17.7019 8.2033 17.3227 8.10193 16.951 8.00212L18.217 5.91702L17.6512 5.76496L15.6311 7.64878C15.2346 7.54181 14.8008 7.42588 14.5659 7.36312C14.3356 6.93344 13.8873 6.63727 13.3659 6.63727H10.9525L11.6189 4.14902C11.6994 3.85127 11.9706 3.64204 12.2778 3.64204C12.3376 3.64204 12.3973 3.65099 12.4558 3.66621L20.3726 5.78746C20.5491 5.83412 20.6964 5.94768 20.788 6.10534C20.8787 6.26389 20.9031 6.44818 20.8563 6.62362Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0514 13.04L16.4061 12.8692L16.5805 12.21L17.2259 12.3808L17.0514 13.04Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.3459 13.3816L17.7008 13.2109L17.875 12.5524L18.5202 12.7232L18.3459 13.3816Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7562 12.6971L15.103 12.5245L15.2772 11.8652L15.9304 12.0378L15.7562 12.6971Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
