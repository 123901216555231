import { Box } from "@mui/material";
import { LabelItems } from "../LabelItems";

export const LabelItemsList = () => {
  return (
    <Box display="flex" flexDirection="column" pt={10} px={{ lg: 10, xs: 1 }}>
      <LabelItems icon="Plane" description="Contamos con salidas diarias." />
      <LabelItems
        icon="Money"
        description="Nuestras tarifas están representadas en dólares americanos y se entiende que el valor es por persona, salvo que Inka Planet Adventure lance alguna promoción, en cuyo caso se especificarán las condiciones para cada plan."
      />
      <LabelItems
        icon="Passport"
        description="Recuerda que portar pasaporte y documento de identidad es importante si sales a hacer turismo. Para efectos de reserva, Inka Planet Adventure podrá solicitarte copia de uno de estos documentos."
      />
      <LabelItems
        icon="Clothing"
        description="Es importante que lleves ropa cómoda para tus salidas, incluye dentro de tu maleta de viaje artículos como, chaqueta impermeable, gafas del sol, un protector solar mayor a 35FPS y una botella de agua, esto garantizará que, así llueva o haga calor, estés siempre protegido."
      />
      <LabelItems
        icon="CustomerSupport"
        description="Para mayor información puedes consultar nuestra sección de Términos y Condiciones"
      />
    </Box>
  );
};
