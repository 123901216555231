import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";
export const People = (props: CustomSvgProps) => {
  const SvgSize = props.size || 24;
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        d="M11.9741 11.4547C13.7736 11.4547 15.1731 9.99443 15.1731 8.20232C15.1731 6.41022 13.7069 5.01636 11.9075 5.01636C10.1081 5.01636 8.7085 6.47659 8.7085 8.20232C8.7085 9.99443 10.1747 11.4547 11.9741 11.4547ZM11.9075 6.34384C11.9741 6.34384 11.9741 6.34384 11.9075 6.34384C12.9738 6.34384 13.8402 7.20671 13.8402 8.2687C13.8402 9.33068 12.9738 10.1272 11.9075 10.1272C10.8412 10.1272 10.0414 9.26431 10.0414 8.2687C10.0414 7.20671 10.9078 6.34384 11.9075 6.34384Z"
        fill="#606A78"
      />
      <path
        d="M21.8387 11.0564C20.5724 9.92808 18.9063 9.33071 17.1735 9.39708H16.6403C16.507 9.92808 16.3071 10.3927 16.0405 10.7909C16.4404 10.7246 16.7736 10.7246 17.1735 10.7246C18.4398 10.6582 19.706 11.0564 20.7057 11.7866V16.5655H22.0387V11.2556L21.8387 11.0564Z"
        fill="#606A78"
      />
      <path
        d="M15.6401 5.14903C15.9733 4.35254 16.9063 3.95429 17.7727 4.28616C18.5725 4.61803 18.9724 5.54727 18.6391 6.41014C18.3726 7.00751 17.7727 7.40575 17.1729 7.40575C17.0396 7.40575 16.8397 7.40575 16.7064 7.33938C16.7731 7.67125 16.7731 8.00312 16.7731 8.26862V8.66686C16.9063 8.66686 17.0396 8.73324 17.1729 8.73324C18.8391 8.73324 20.172 7.40575 20.172 5.81277C20.172 4.15341 18.8391 2.82593 17.2396 2.82593C16.1732 2.82593 15.2402 3.35692 14.707 4.28616C15.0403 4.48528 15.3735 4.75078 15.6401 5.14903Z"
        fill="#606A78"
      />
      <path
        d="M8.04256 10.8574C7.77597 10.4591 7.57604 9.99449 7.44274 9.4635H6.90958C5.17679 9.39713 3.51064 9.99449 2.24437 11.0565L2.04443 11.2556V16.5655H3.37735V11.7866C4.44368 11.0565 5.64331 10.6582 6.90958 10.7246C7.30945 10.7246 7.70933 10.791 8.04256 10.8574Z"
        fill="#606A78"
      />
      <path
        d="M6.91018 8.66693C7.04347 8.66693 7.17676 8.66693 7.31005 8.60056V8.20231C7.31005 7.87044 7.31005 7.53857 7.3767 7.27308C7.24341 7.33945 7.04347 7.33945 6.91018 7.33945C6.04378 7.33945 5.31068 6.60933 5.31068 5.74647C5.31068 4.8836 6.04378 4.15349 6.91018 4.15349C7.57664 4.15349 8.17645 4.55173 8.44303 5.1491C8.70962 4.81723 9.10949 4.48536 9.44272 4.21986C8.57633 2.826 6.77689 2.36138 5.37733 3.22425C3.97776 4.08711 3.51124 5.87922 4.37764 7.27308C4.91081 8.13594 5.84385 8.66693 6.91018 8.66693Z"
        fill="#606A78"
      />
      <path
        d="M17.4394 15.039L17.3062 14.8399C15.9732 13.3797 14.1072 12.5168 12.1078 12.5832C10.1084 12.5168 8.17568 13.3797 6.84276 14.8399L6.70947 15.039V20.0835C6.70947 20.6808 7.17599 21.2118 7.84245 21.2118H16.3731C16.9729 21.2118 17.5061 20.6808 17.5061 20.0835V15.039H17.4394ZM16.1065 19.8843H8.04239V15.5036C9.10872 14.4416 10.5749 13.9106 12.1078 13.9106C13.574 13.8443 15.0402 14.4416 16.1065 15.5036V19.8843Z"
        fill="#606A78"
      />
    </SvgIcon>
  );
};
