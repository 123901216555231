import { IPackage } from "types/package";

export enum EPackagesType {
  SetPackages = "SetPackages",
  SetPackageSelected = "SetPackageSelected"
}

export const SetPackages = (packs: IPackage[]) => ({
  type: EPackagesType.SetPackages,
  payload: packs
});
export const SetPackageSelected = (pack: IPackage) => ({
  type: EPackagesType.SetPackageSelected,
  payload: pack
});

export const PackageActions = {
  SetPackages,
  SetPackageSelected
};
