import { Arrival, IncludeArrivalOptions, IncludeOutletOptions, Outlet } from "helpers/Consts";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "state/store";
import { IPackage } from "types/package";
type ItineraryItem = {
  id: string;
  title: string;
  subtitle: string;
  description: string;
  img?: string;
};

const ApplyIncludeArrival = (
  items: ItineraryItem[],
  tours: IPackage["tours"],
  includeArrival: IPackage["includedIn"]
) => {
  if (includeArrival.included && includeArrival.typeIncluded.id === IncludeArrivalOptions.SameDay) {
    const destination = tours[0].destination.name;
    const description = `Bienvenida a ${destination}.\n${tours[0].saleDescription}`;
    const subtitle = `Bienvenida + ${tours[0].name}`;
    items[0] = { ...items[0], subtitle, description };
  } else if (
    includeArrival.included &&
    includeArrival.typeIncluded.id === IncludeArrivalOptions.NextDay
  ) {
    const destination = tours[0].destination.name;
    const subtitle = `Bienvenida + ${destination}`;
    items.unshift({
      id: tours[0].id + "_copy",
      title: destination,
      description: Arrival.description,
      subtitle,
      img: Arrival.image
    });
  }
};
const ApplyIncludeOutlet = (
  items: ItineraryItem[],
  tours: IPackage["tours"],
  includeOutlet: IPackage["includedOut"]
) => {
  const lastTour = tours[tours.length - 1];
  const destination = lastTour.destination.name;
  if (includeOutlet.included && includeOutlet.typeIncluded.id === IncludeOutletOptions.SameDay) {
    const description = `${lastTour.saleDescription}\nDespedida de ${destination}`;
    const subtitle = `${lastTour.name} + despedida`;
    items[items.length - 1] = { ...items[items.length - 1], subtitle, description };
  } else if (
    includeOutlet.included &&
    includeOutlet.typeIncluded.id === IncludeOutletOptions.NextDay
  ) {
    const subtitle = `Despedida de ${destination}`;
    items.push({
      id: lastTour.id + "_copy",
      title: destination,
      description: Outlet.description,
      subtitle,
      img: Outlet.image
    });
  }
};
export const useItineraryDetail = () => {
  const pack = useSelector((state: RootState) => state.PackageReducer.pack);
  const tours = pack?.tours as IPackage["tours"];
  const data = useMemo<ItineraryItem[]>(() => {
    const items: ItineraryItem[] = tours.map(t => ({
      id: t.id,
      title: t.destination.name,
      description: t.saleDescription,
      subtitle: t.name,
      img: t.photos?.[0]?.url
    }));
    ApplyIncludeArrival(items, tours, pack?.includedIn as IPackage["includedIn"]);
    ApplyIncludeOutlet(items, tours, pack?.includedOut as IPackage["includedOut"]);
    return items;
  }, [pack?.tours, pack?.includedIn, pack?.includedOut]);
  return data;
};
