import { SvgIcon, SvgIconProps } from "@mui/material";
export const Cellphone = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.6 2H17.4C18.5594 2 19.5 2.88184 19.5 3.96875V21.0312C19.5 22.1182 18.5594 23 17.4 23H7.6C6.44063 23 5.5 22.1182 5.5 21.0312V3.96875C5.5 2.88184 6.44063 2 7.6 2ZM12.5 21.6875C13.2744 21.6875 13.9 21.101 13.9 20.375C13.9 19.649 13.2744 19.0625 12.5 19.0625C11.7256 19.0625 11.1 19.649 11.1 20.375C11.1 21.101 11.7256 21.6875 12.5 21.6875ZM7.6 17.2578C7.6 17.5285 7.83625 17.75 8.125 17.75H16.875C17.1637 17.75 17.4 17.5285 17.4 17.2578V4.46094C17.4 4.19023 17.1637 3.96875 16.875 3.96875H8.125C7.83625 3.96875 7.6 4.19023 7.6 4.46094V17.2578Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
