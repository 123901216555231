import { SvgIcon, SvgIconProps } from "@mui/material";
type Props = SvgIconProps & { active?: boolean };
export const Like = ({ active, ...props }: Props) => {
  return (
    <SvgIcon
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0235 2.88688C10.6447 1.9357 11.3861 1.14763 12.3848 0.630334C15.8863 -1.18154 19.8943 1.32516 19.8361 5.26537C19.7994 7.72363 18.9898 9.83225 17.1673 11.5025C15.4703 13.0562 13.7402 14.574 12.0279 16.1106C11.3485 16.7211 10.677 17.3397 9.98222 17.9718C7.45688 15.6713 4.88406 13.4273 2.43051 11.0605C0.457364 9.15718 -0.136951 6.69532 0.306786 4.04521C0.628623 2.12215 1.85316 0.820397 3.73394 0.270829C5.77603 -0.326247 7.56631 0.214357 9.05535 1.72322C9.39688 2.06927 9.68107 2.47181 10.0235 2.88688"
        fill={active ? "#D80027" : "#606A78"}
      />
    </SvgIcon>
  );
};
