import { PublicApi } from "../helpers/API";
import { IDestination } from "types/Destination";
import { IPackage } from "types/package";

export const GetAllDestinations = () => {
  return new Promise<IDestination[]>(async (res, rej) => {
    try {
      const response = await PublicApi.get<IDestination[]>("destination-get-all");
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
};

export const GetPackagesByDestination = (id: string, tour = false) => {
  return new Promise<IPackage[]>(async (res, rej) => {
    try {
      const response = await PublicApi.get<IPackage[]>(
        `package-by-destination-id?id=${id}&tour=${Number(tour)}`
      );
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
};
