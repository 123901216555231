import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { CustomTheme } from "helpers/Theme";

type ContentTitleParams = {
  title?: string;
  img: string;
};

export const ContentTitle: React.FC<ContentTitleParams> = ({ title, img, children }) => {
  const { breakpoints } = useTheme<CustomTheme>();
  const isMobile = useMediaQuery(breakpoints.only("xs"));
  const isMediumOrMore = useMediaQuery(breakpoints.up("md"));
  const marginTop = isMobile ? "62px" : "75px";
  return (
    <Box
      sx={{
        backgroundImage: `url(${img})`,
        height: { xs: "61vh", md: "75vh" },
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        display: "flex",
        justifyContent: "center",
        marginTop,
        alignItems: "center",
        flexDirection: "column"
      }}>
      {children || (
        <Typography
          width={{ lg: 1109, md: 881, sm: 621, xs: "95%" }}
          variant={isMediumOrMore ? "h1" : "subtitle1"}
          color="common.white"
          align="center">
          {title}
        </Typography>
      )}
    </Box>
  );
};
