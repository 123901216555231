import { Avatar, Box, Divider, Typography } from "@mui/material";

type AuthorPros = {
  name?: string;
  date?: string;
  avatar?: string;
};

export const Author = ({ name, date, avatar }: AuthorPros) => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: "row", sm: "column", md: "row" }}
      gap={5}
      alignItems="flex-start">
      <Avatar
        aria-label="Author avatar"
        alt="Author avatar"
        src={avatar}
        sx={{ width: 63, height: 63 }}
      />
      <Box display="display" width={{ lg: 190, sm: 200, xs: "100%" }} flexDirection="column">
        <Box display="display" flexDirection="column">
          <Typography color="text.secondary" paragraph>
            Escrito por:
          </Typography>
          <Typography paragraph>{name}</Typography>
        </Box>
        <Divider sx={{ borderBottomWidth: 2, mb: 2, mt: 2 }} />
        <Typography color="text.secondary" lineHeight="0.875rem" paragraph>
          {date}
        </Typography>
      </Box>
    </Box>
  );
};
