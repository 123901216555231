import { Typography, Box } from "@mui/material";
import { Title } from "../../components/Title";
import { Container } from "../../components/Container";
import { TourCard } from "../../components/TourCard";
import Ofert from "../../assets/Ofert.png";
import { ContentTitle } from "../../components/ContentTitle";
import HomeBackground from "../../assets/HomeBackground.png";
import { useEffect, useState } from "react";
import { GetPackagesByDestination } from "services/Destination";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch, RootState } from "state/store";
import { PackageActions } from "state/actions/PackageActions";
import { LoaderIndicator } from "components/LoaderIndicator";
import { useTitleChange } from "hooks/useTitleChange";

export const Tour = () => {
  useTitleChange("Tours");
  const getInverted = (index: number) => !((index + 1) % 2);
  const [loading, setLoading] = useState(true);
  const { packages } = useSelector((state: RootState) => state.PackageReducer);
  const dispatch = useDispatch<RootDispatch>();
  useEffect(() => {
    const GetData = async () => {
      try {
        const data = await GetPackagesByDestination("6271e890982eff59cc3a4ed0", true);
        dispatch(PackageActions.SetPackages(data));
      } catch (e) {
        console.error(e, "Tour->GetData");
      }
      setLoading(false);
    };
    GetData();
  }, []);
  return (
    <>
      <LoaderIndicator isLoading={loading} />
      {!loading && (
        <>
          <ContentTitle title="Tours" img={HomeBackground} />
          <Container offsetHeader={false}>
            <Title>Tours</Title>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography sx={{ lineHeight: "39px", textAlign: "center" }}>
                Somos especialistas en ofrecerte aventura diaria gracias a nuestros tours. Haz tu
                recorrido por Perú con <b>Inka Planet Adventure</b> y enamórate de cada rincón de
                nuestro país. Llévate los mejores recuerdos en tu corazón y las fotos más
                sorprendentes en tu teléfono móvil, nosotros nos encargamos de hacerte vivir al
                máximo una experiencia cada día.
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 10, mt: 30, mb: 30 }}>
              {packages?.map((p, index) => (
                <TourCard
                  key={p.id}
                  title={p.name}
                  id={p.id}
                  inverted={getInverted(index)}
                  description={p.description.generalDescription}
                  image={p.tours?.[0]?.photos[0]?.url ?? Ofert}
                />
              ))}
            </Box>
          </Container>
        </>
      )}
    </>
  );
};
