import { ContentTitle } from "components/ContentTitle";
import { NewPostContent } from "./components/NewPostContent";
import { BreadCrumbs } from "components/BreadCrumbs";
import HomeBackground from "assets/HomeBackground.png";

export const NewsPost = () => {
  return (
    <>
      <ContentTitle title="Blog" img={HomeBackground} />
      <BreadCrumbs
        items={[
          {
            label: "Home",
            to: "/"
          },
          {
            label: "Noticias",
            to: "/blog"
          }
        ]}
        pl={{ lg: 41, md: 4, sm: 10, xs: 4 }}
      />
      <NewPostContent />
    </>
  );
};
