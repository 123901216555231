import { SvgIcon, SvgIconProps } from "@mui/material";
export const Truck = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66617 19.166C5.56319 19.166 4.6664 18.2692 4.6664 17.1663C4.6664 16.0634 5.56319 15.1666 6.66617 15.1666C7.76893 15.1666 8.66572 16.0634 8.66572 17.1663C8.66572 18.2692 7.76893 19.166 6.66617 19.166ZM6.66617 15.833C5.93107 15.833 5.33284 16.4307 5.33284 17.1663C5.33284 17.9014 5.93107 18.4991 6.66617 18.4991C7.40127 18.4991 7.99928 17.9014 7.99928 17.1663C7.99928 16.4307 7.40127 15.833 6.66617 15.833Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9986 19.166C16.8954 19.166 15.9986 18.2692 15.9986 17.1663C15.9986 16.0634 16.8954 15.1666 17.9986 15.1666C19.1011 15.1666 19.9979 16.0634 19.9979 17.1663C19.9979 18.2692 19.1011 19.166 17.9986 19.166ZM17.9986 15.833C17.263 15.833 16.6655 16.4307 16.6655 17.1663C16.6655 17.9014 17.263 18.4991 17.9986 18.4991C18.7332 18.4991 19.3315 17.9014 19.3315 17.1663C19.3315 16.4307 18.7332 15.833 17.9986 15.833Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6666 17.5016H19.6668V16.8344H21.3332V13.5982L18.4846 9.16559H15.3335V16.8344H16.3334V17.5016H15.0005C14.816 17.5016 14.6671 17.3527 14.6671 17.168V8.83247C14.6671 8.64827 14.816 8.4989 15.0005 8.4989H18.6669C18.7801 8.4989 18.8859 8.55664 18.9473 8.65194L21.9468 13.3201C21.9817 13.3737 22.0001 13.4364 22.0001 13.5006V17.168C22.0001 17.3527 21.8507 17.5016 21.6666 17.5016Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.3304 13.5004H16.3294C16.1451 13.5004 15.9963 13.3514 15.9963 13.1668V10.1654C15.9963 9.98112 16.1451 9.83172 16.3294 9.83172H19.3301V10.499H16.663V12.8331H21.3304L21.3304 13.5004Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9987 17.5016H8.33301V16.8347H14.6653V6.83501H2.66691V16.8347H5.00026V17.5016H2.33392C2.14965 17.5016 2.00049 17.3527 2.00049 17.1681V6.50155C2.00049 6.31787 2.14965 6.16855 2.33392 6.16855H14.9987C15.1828 6.16855 15.3322 6.31787 15.3322 6.50155V17.1681C15.3322 17.3527 15.1828 17.5016 14.9987 17.5016Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.33303 16.1674V15.4977H4.33091V16.1674H2.33303Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.00049 16.1674V15.4977H14.667V16.1674H9.00049Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3291 16.1674V15.4977H21.6628V16.1674H20.3291Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.3339 8.16606V7.50103H14.9994V8.16606H3.3339Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.33323 17.5012V16.8316H6.99737V17.5012H6.33323Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6669 17.5012V16.8316H18.331V17.5012H17.6669Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.3339 5.49926V4.83423H7.99918V5.49926H3.3339Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.00049 5.49926V4.83423H2.66463V5.49926H2.00049Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
