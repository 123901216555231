import { Box, BoxProps, Card, CardContent, CardMedia, Link, Typography } from "@mui/material";
type OtherTourProps = {
  boxProps?: BoxProps;
  description: string;
  title: string;
  image: string;
};

export const OtherTour = ({ boxProps, description, title, image }: OtherTourProps) => {
  return (
    <Box {...boxProps}>
      <Card elevation={0} sx={{ width: { xs: 210, sm: 260, md: 310, lg: 350 } }}>
        <CardMedia
          sx={{
            borderRadius: 5,
            objectFit: "fill",
            height: { xs: 230, sm: 280, md: 342, lg: 386 }
          }}
          component="img"
          alt="OtherTour"
          aria-label="OtherTour"
          image={image}
        />
        <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography textAlign="center" variant="body1" color="#070707B2">
            {description}
          </Typography>
          <Typography textAlign="center" my={7.5} variant="h5" component="div">
            {title}
          </Typography>
          <Link
            href="#"
            aria-label="OtherTour"
            variant="body2"
            underline="hover"
            sx={{ textUnderlineOffset: 10 }}>
            Ver Itinerario
          </Link>
        </CardContent>
      </Card>
    </Box>
  );
};
