import { PublicApi } from "helpers/API";
import { INewContact } from "../types/Client";

export const SaveContact = (val: INewContact) => {
  return new Promise<never>(async (res, rej) => {
    try {
      const response = await PublicApi.post<never>("contact-save", val);
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
};
