import { SvgIcon, SvgIconProps } from "@mui/material";
export const Location = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="14"
        height="25"
        viewBox="0 0 14 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4886_120569)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1217 8.2228C11.1263 6.16978 9.46432 4.48751 7.40239 4.45727C5.33655 4.42738 3.60945 6.14167 3.61266 8.2196C3.61587 10.2787 5.27396 11.9588 7.33229 11.9883C9.39526 12.0182 11.1171 10.3071 11.1217 8.2228ZM7.36612 22.3566C6.42248 20.3669 5.50594 18.5456 4.68865 16.6808C3.68954 14.4029 2.73526 12.1033 1.83257 9.78552C0.851226 7.26496 2.21718 4.83442 3.64932 3.67378C5.46073 2.2057 8.03644 1.9545 10.1083 3.02905C12.2777 4.15412 13.9906 7.02871 12.9078 9.7478C11.6753 12.8419 10.3161 15.8862 8.9875 18.9416C8.50969 20.0407 7.96138 21.1092 7.36612 22.3566Z"
            fill="#606A78"
          />
        </g>
        <defs>
          <clipPath id="clip0_4886_120569">
            <rect width="13" height="24" fill="white" transform="translate(0.5 0.362549)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
