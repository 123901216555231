import { Card, Box, Typography, CardMedia } from "@mui/material";
import { TitleComing } from "../TitleComing/TitleComing";
import { ContactLabel } from "./components/ContactLabel";
import { Path } from "icons/Path";
import exitCusco from "assets/ExitCusco.jpg";
import { IQuote } from "types/Quote";
import {
  IncludeArrivalOptions,
  IncludeOutletOptions,
  OutletDescription,
  sharedData
} from "helpers/Consts";
import { LabelQuote } from "../LabelQuote";
import dayjs from "dayjs";

export const FourthSection = ({ quote }: { quote: IQuote }) => {
  const lastTour = quote.tours[quote.tours.length - 1];
  const destination = lastTour.destination.name;
  const arrivalDay =
    quote.includedIn.included && quote.includedIn.typeIncluded.id === IncludeArrivalOptions.NextDay;
  const outletDay =
    quote.includedOut.included &&
    quote.includedOut.typeIncluded.id === IncludeOutletOptions.NextDay;
  const cantOfDays = quote.tours.length + Number(arrivalDay) + Number(outletDay);
  const num = arrivalDay ? quote.tours.length + 1 : quote.tours.length;
  const showDate = !!quote.dateIn;
  const date = dayjs.utc(quote.dateIn).add(num, "day");
  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: { md: "row", xs: "column" },
        width: { lg: "77%", md: "96.8%", sm: "89%", xs: "92%" }
      }}>
      <Box flex={1}>
        <Box p={5}>
          {outletDay && (
            <>
              <TitleComing>
                Día {cantOfDays}: {destination}
              </TitleComing>
              <Box display="flex" alignItems="center">
                <Path sx={{ m: 1, fontSize: 42 }} />
                <Typography variant="h2" color="secondary">
                  ¡Despedida de {destination}!
                </Typography>
              </Box>
              {showDate && (
                <Box mt={4}>
                  <LabelQuote icon="Calendar" label={date.format("DD MMM [,] YYYY")} />
                </Box>
              )}
            </>
          )}
          <Box ml={3}>
            {outletDay && (
              <Typography variant="body1" color="secondary" mt={12}>
                {OutletDescription}
              </Typography>
            )}
            <Typography variant="h4" color="secondary" mt={12}>
              RESERVAS E INFORMES
            </Typography>
            <Box display="flex" flexDirection="column" gap={4} mt={4}>
              <ContactLabel
                icon="Email"
                link={`mailto:${sharedData.email}`}
                label={sharedData.email}
              />
              <ContactLabel icon="Cellphone" label={`${sharedData.tel}`} />
              <ContactLabel icon="Whatsapp" label={`${sharedData.tel}`} />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box flex={1}>
        <CardMedia
          component="img"
          image={exitCusco}
          alt="exitCusco"
          sx={{
            width: { lg: "100%" },
            marginTop: { xs: 30, md: 0 },
            height: { lg: 698, md: 775, sm: 593, xs: 666 }
          }}
        />
      </Box>
    </Card>
  );
};
