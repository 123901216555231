import { Box, IconButton, Typography, Modal } from "@mui/material";
import { CloseModal } from "icons/CloseModal";
import { Sms } from "icons/Sms";
type ModalSuccessProps = {
  onClose: () => void;
  open: boolean;
  description?: string;
  showVideo?: boolean;
  children?: React.ReactNode | undefined;
};
export const ModalSuccess = ({
  onClose,
  open,
  description,
  showVideo,
  children
}: ModalSuccessProps) => {
  return (
    <Modal
      open={open}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        display: "flex"
      }}>
      {showVideo ? (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%"
          }}>
          <Box position="absolute" top={50} zIndex={999}>
            <IconButton size="small" onClick={onClose}>
              <CloseModal color="#ffffff" size={40} />
            </IconButton>
          </Box>
          {children}
        </Box>
      ) : (
        <Box
          sx={{
            maxWidth: 576,
            height: 209,
            bgcolor: "common.white",
            borderRadius: "20px",
            padding: "10px 30px 10px"
          }}>
          <Box display="flex" justifyContent="flex-end" mb={2}>
            <IconButton size="small" onClick={onClose}>
              <CloseModal />
            </IconButton>
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Sms />
            <Typography variant="subtitle1" textAlign="center" mt={4}>
              {description}
            </Typography>
          </Box>
        </Box>
      )}
    </Modal>
  );
};
