import { Card, CardMedia, Typography, Box } from "@mui/material";
import { CustomTheme } from "../../../helpers/Theme";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material";
import { AboutImages } from "helpers/Consts";

const getStyles = makeStyles((theme: CustomTheme) => ({
  card: {
    width: "90%",
    display: "flex",
    gap: 20,
    [theme.breakpoints.only("sm")]: {
      width: 600
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      justifyContent: "center"
    },
    [theme.breakpoints.only("xl")]: {
      width: "calc(50% - 40px)"
    }
  },
  line: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      position: "relative",
      bottom: 10
    }
  },
  boxOne: {
    width: 216,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "auto"
    }
  },
  boxTwo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  title: {
    width: 363,
    fontFamily: "Zilla Slab",
    fontSize: "20px",
    fontWeight: "500",
    height: "auto",
    [theme.breakpoints.only("sm")]: {
      width: "454"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "260px",
      textAlign: "center",
      margin: "auto",
      marginTop: 20
    }
  },
  description: {
    position: "relative",
    textAlign: "left",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.secondary.light,
    [theme.breakpoints.only("sm")]: {
      width: 360
    },
    [theme.breakpoints.down("sm")]: {
      left: 0,
      width: 270,
      margin: "auto"
    }
  },
  imgCeo: {
    width: 162,
    height: 210,
    filter: "grayscale(1)",
    transition: ".4s ease",
    "&:hover": {
      filter: "grayscale(0)"
    }
  },
  names: {
    width: 216,
    fontSize: 13,
    fontWeight: 600,
    textAlign: "center"
  }
}));

const Line = styled("div", {
  skipSx: true,
  skipVariantsResolver: true
})(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.primary.main,
  height: 2,
  width: 127,
  marginTop: 20
}));

export const OneCard = () => {
  const styles = getStyles();
  return (
    <Card elevation={0} className={styles.card}>
      <Box className={styles.boxOne}>
        <CardMedia component="img" className={styles.imgCeo} image={AboutImages.PersonOne} />
        <Typography className={styles.names}>CEO & Fundator</Typography>
        <Typography className={styles.names}>Marianela A. Mendoza Vásquez.</Typography>
      </Box>
      <Box className={styles.boxTwo}>
        <Typography className={styles.title}>
          ”El verdadero valor de una experiencia descansa en los recuerdos que esta crea.”
          <Box className={styles.line}>
            <Line />
          </Box>
        </Typography>
        <Box>
          <Typography className={styles.description} variant="body1">
            ¡Somos Logística, Puntualidad y Seguridad a tu servicio, en todos nuestros servicios!
            Queremos -de corazón- que no tengas queja alguna, estar mucho tiempo en tu memoria
            viajera y.… ¡Que Quieras Regresar!
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
