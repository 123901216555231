import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { ImageUrl } from "types/Hotel";
import { Icon } from "../../icon";
const arrowColors = ["white", "#0000007a"];
export const TooltipCarrousel = ({ urlImages }: { urlImages: ImageUrl[] }) => {
  const [like, setLike] = useState(false);
  const [step, setStep] = useState(0);
  const isMin = (v: number) => v <= 0;
  const isLimit = (v: number) => v >= urlImages.length - 1;
  const backgroundLeft = arrowColors[Number(isMin(step))];
  const colorLeft = arrowColors[Number(!isMin(step))];
  const backgroundRight = arrowColors[Number(isLimit(step))];
  const colorRight = arrowColors[Number(!isLimit(step))];
  const paddingItems = 8;
  const borderRadiusImage = 20;
  const topPositionArrows = "45%";
  const steppingItem = 1;
  const isMoreOnePic = urlImages.length > 1;
  return (
    <Box width={300} height={180} sx={{ overflowY: "hidden" }} position="absolute">
      <SwipeableViews axis="x" index={step}>
        {urlImages.map((item, index) => (
          <Box
            key={index}
            sx={{
              overflow: "hidden",
              borderTopLeftRadius: borderRadiusImage,
              borderTopRightRadius: borderRadiusImage
            }}>
            <Box
              component="img"
              sx={{
                objectFit: "cover",
                width: "100%",
                height: 180,
                display: "flex",
                flexGrow: 1,
                overflow: "hidden"
              }}
              src={item.url}
              alt={item.url}
            />
          </Box>
        ))}
      </SwipeableViews>
      {isMoreOnePic && (
        <>
          <IconButton
            size="large"
            sx={{
              position: "absolute",
              top: topPositionArrows,
              left: paddingItems
            }}
            style={{ backgroundColor: backgroundLeft }}
            onClick={() => setStep(s => (isMin(s) ? s : s - steppingItem))}>
            <Icon name="ArrowFillLeft" htmlColor={colorLeft} />
          </IconButton>
          <IconButton
            size="large"
            sx={{
              position: "absolute",
              top: topPositionArrows,
              right: paddingItems
            }}
            style={{ backgroundColor: backgroundRight }}
            onClick={() => setStep(s => (isLimit(s) ? s : s + steppingItem))}>
            <Icon name="ArrowFillRight" htmlColor={colorRight} />
          </IconButton>
        </>
      )}
      <IconButton
        size="large"
        sx={{
          position: "absolute",
          top: "5%",
          right: paddingItems
        }}
        style={{ backgroundColor: "#0000007a" }}
        onClick={() => setLike(s => !s)}>
        <Icon name={like ? "Heart" : "HeartOutline"} size={15} htmlColor="white" />
      </IconButton>
    </Box>
  );
};
