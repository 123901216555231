import { SvgIcon, SvgIconProps } from "@mui/material";
export const Translation = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.1613 18.2104C21.1613 18.6812 20.7781 19.0643 20.3075 19.0643H19.1819C18.9674 19.0643 18.7937 19.238 18.7937 19.4525V20.7292L16.8765 19.1607C16.7708 19.0643 16.6781 19.0643 16.4702 19.0643H10.9913C10.5207 19.0643 10.1375 18.6812 10.1375 18.2104V14.6532C12.4336 14.0329 14.2227 12.1659 14.7295 9.82606H20.3075C20.7781 9.82606 21.1613 10.2091 21.1613 10.6804L21.1613 18.2104ZM20.3075 9.04952H14.848C14.8652 8.85825 14.875 8.66439 14.875 8.46846C14.875 4.93649 12.0008 2.06299 8.4696 2.06299C7.34447 2.06299 6.23747 2.35897 5.26868 2.91892C5.08338 3.02614 5.01943 3.26333 5.12668 3.44894C5.23393 3.63459 5.47185 3.698 5.6574 3.59075C6.50811 3.09937 7.48067 2.83898 8.4696 2.83898C11.5731 2.83898 14.0985 5.36445 14.0985 8.46845C14.0985 11.5719 11.5731 14.0974 8.4696 14.0974C7.17025 14.0974 5.9497 13.6668 4.93962 12.8527C4.83816 12.7712 4.70397 12.747 4.58186 12.7846L3.55165 13.0538L3.95522 12.1669C4.01312 12.0396 3.99802 11.8911 3.91569 11.7776C3.21226 10.8114 2.84016 9.66672 2.84016 8.46845C2.84016 7.47691 3.10149 6.50189 3.59595 5.64958C3.7032 5.46396 3.64026 5.22627 3.45471 5.11902C3.26916 5.01126 3.03175 5.07467 2.92399 5.26029C2.36155 6.23068 2.06396 7.34027 2.06396 8.46846C2.06396 9.75541 2.44086 10.9882 3.15662 12.0473L2.50707 13.475C2.44463 13.6112 2.46679 13.771 2.56321 13.886C2.65964 14.0005 2.81321 14.0494 2.95823 14.0113L4.60904 13.5797C5.71555 14.4155 7.07834 14.8739 8.46959 14.8739C8.77221 14.8739 9.06979 14.8522 9.36159 14.8115V18.2104C9.36159 19.1091 10.0927 19.8408 10.9913 19.8408H16.481L18.9359 21.8491C19.0067 21.9069 19.0938 21.9367 19.1819 21.9367C19.238 21.9367 19.2947 21.9244 19.3478 21.8991C19.4835 21.8352 19.5696 21.6985 19.5696 21.5485V19.8408H20.3075C21.2061 19.8408 21.9377 19.1091 21.9377 18.2104V10.6804C21.9377 9.78118 21.2061 9.04952 20.3075 9.04952Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8898 14.8553L15.6494 13.1244L16.409 14.8553H14.8898ZM17.5809 15.5942C17.7124 15.5323 17.8037 15.3988 17.8037 15.2436C17.8037 15.0291 17.6298 14.8553 17.4153 14.8553H17.2567L16.0046 12.0025C15.9427 11.8612 15.8035 11.7699 15.6494 11.7699C15.4953 11.7699 15.3561 11.8612 15.2942 12.0025L14.0421 14.8553H13.8835C13.669 14.8553 13.4951 15.0291 13.4951 15.2436C13.4951 15.3988 13.5865 15.5323 13.7179 15.5942L13.2519 16.6569C13.1658 16.8533 13.2549 17.0823 13.4508 17.1684C13.6474 17.2545 13.8762 17.1648 13.9623 16.9688L14.5492 15.6313H16.7496L17.3365 16.9688C17.3999 17.1142 17.5427 17.2008 17.6922 17.2008C17.7438 17.2008 17.797 17.1905 17.8481 17.1684C18.0439 17.0823 18.1336 16.8533 18.047 16.6569L17.5809 15.5942Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.21582 4.60083C4.31753 4.60083 4.41724 4.55919 4.48933 4.48718C4.56141 4.41518 4.6031 4.31544 4.6031 4.21362C4.6031 4.1118 4.56141 4.01202 4.48933 3.94005C4.41724 3.86805 4.31754 3.8264 4.21582 3.8264C4.11334 3.8264 4.01414 3.86805 3.94206 3.94005C3.86997 4.01202 3.82828 4.1118 3.82828 4.21362C3.82828 4.31544 3.86997 4.41518 3.94206 4.48718C4.01414 4.55918 4.11384 4.60083 4.21582 4.60083Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.1544 10.8321C5.96611 10.9352 5.89763 11.1708 6.00085 11.359C6.07133 11.4874 6.2045 11.5606 6.34144 11.5606C6.40438 11.5606 6.46832 11.5451 6.52773 11.5126C7.3932 11.0378 8.0588 10.2995 8.44874 9.42919C8.83843 10.2995 9.50402 11.0378 10.3695 11.5126C10.4289 11.5451 10.4928 11.5606 10.5558 11.5606C10.6927 11.5606 10.8259 11.4874 10.8964 11.359C10.9996 11.1708 10.9306 10.9352 10.7428 10.8321C9.72933 10.2763 9.04762 9.28331 8.8782 8.16142H10.6016C10.8161 8.16142 10.9893 7.98716 10.9893 7.77321C10.9893 7.55872 10.8161 7.38499 10.6016 7.38499H8.83692V5.76764C8.83692 5.55315 8.66297 5.37942 8.44874 5.37942C8.23425 5.37942 8.0603 5.55315 8.0603 5.76764V7.38499H6.65863C6.44415 7.38499 6.27045 7.55872 6.27045 7.77321C6.27045 7.98716 6.44415 8.16142 6.65863 8.16142H8.01852C7.8496 9.28332 7.16739 10.2763 6.1544 10.8321Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
