import { Container } from "../../components/Container";
import { Body, Content } from "../../components/Body";
import { Title } from "../../components/Title";
import content from "./Text.json";
import { useTitleChange } from "hooks/useTitleChange";
export const TipsRecommendations = () => {
  useTitleChange("Consejos y Recomendaciones");
  return (
    <Container>
      <Title>Consejos y Recomendaciones</Title>
      <Body content={content as Content[]} />
    </Container>
  );
};
