import { Card, CardMedia, Typography, Box } from "@mui/material";
import { CustomTheme } from "../../../helpers/Theme";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material";
import { AboutImages } from "helpers/Consts";

const getStyles = makeStyles((theme: CustomTheme) => ({
  card: {
    width: "90%",
    height: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "row-reverse",
    gap: 20,
    [theme.breakpoints.only("sm")]: {
      width: 600
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      justifyContent: "center"
    },
    [theme.breakpoints.only("xl")]: {
      width: "calc(50% - 40px)"
    }
  },
  line: {
    display: "flex",
    justifyContent: "right",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      position: "relative",
      bottom: 10
    }
  },
  boxOne: {
    width: 216,
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "auto"
    }
  },
  boxTwo: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    fontFamily: "Zilla Slab",
    fontSize: "20px",
    fontWeight: "500",
    height: "auto",
    textAlign: "right",
    [theme.breakpoints.only("sm")]: {
      width: "454",
      textAlign: "right"
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "260px",
      textAlign: "center",
      margin: "auto",
      marginTop: 20
    }
  },
  description: {
    display: "flex",
    justifyContent: "center",
    textAlign: "right",
    color: theme.palette.secondary.light,
    [theme.breakpoints.only("sm")]: {
      width: 360
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: 270
    }
  },
  imgCeo: {
    width: 162,
    height: 210,
    filter: "grayscale(1)",
    transition: ".4s ease",
    "&:hover": {
      filter: "grayscale(0)"
    }
  },
  names: {
    width: 216,
    fontSize: 13,
    fontWeight: 600,
    textAlign: "center"
  }
}));

const Line = styled("div", {
  skipSx: true,
  skipVariantsResolver: true
})(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.primary.main,
  height: 2,
  width: 127,
  marginTop: 20,
  marginBottom: 20
}));

export const TwoCard = () => {
  const styles = getStyles();
  return (
    <Card elevation={0} className={styles.card}>
      <Box className={styles.boxOne}>
        <CardMedia component="img" className={styles.imgCeo} image={AboutImages.PersonTwo} />
        <Typography className={styles.names}>CEO & Fundator</Typography>
        <Typography className={styles.names}>Jean Piere Bellota Mendoza.</Typography>
      </Box>

      <Box className={styles.boxTwo}>
        <Typography className={styles.title}>
          ”La experiencia se crea desde el primer contacto.”
          <Box className={styles.line}>
            <Line />
          </Box>
        </Typography>
        <Box>
          <Typography className={styles.description} variant="body1">
            En IPA sabemos que lo que empieza bien... ¡Resulta mucho mejor! Nos esforzamos por ser
            referente Peruano de Hospitalidad, Para marcar la diferencia en toda tu experiencia,
            ¡Desde la primera impresión!
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};
