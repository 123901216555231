import { SvgIcon, SvgIconProps } from "@mui/material";
export const PeopleSolid = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4241 18.5463C8.91178 18.5468 7.40023 18.5563 5.88796 18.5439C4.60517 18.5327 3.60079 17.9147 3.31762 16.8386C3.15381 16.2171 3.15208 15.5336 3.18571 14.8837C3.25195 13.5918 3.48908 12.3335 4.22328 11.2206C4.73579 10.4448 5.46292 10.0073 6.36973 9.9257C6.66532 9.89908 7.0283 10.0162 7.27727 10.1876C8.19476 10.8191 9.16306 11.2975 10.2927 11.3265C11.3356 11.3531 12.2921 11.0498 13.1605 10.4585C14.3812 9.62708 15.6399 9.8636 16.5313 11.0633C17.2828 12.0751 17.5345 13.2535 17.6563 14.4657C17.7149 15.0416 17.7125 15.6276 17.6871 16.2065C17.625 17.6504 16.4238 18.5332 15.005 18.5433C13.478 18.5539 11.951 18.5457 10.4241 18.5463Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.46426 6.62368C6.46311 4.40059 8.21536 2.64625 10.4505 2.63383C12.6236 2.62141 14.4302 4.44257 14.4325 6.6491C14.4355 8.84027 12.6248 10.6455 10.4257 10.6354C8.11476 10.6248 6.34433 8.63634 6.46426 6.62368Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.88073 5.97607C5.80343 7.13557 5.94996 8.2454 6.60268 9.13645C5.90684 9.43623 5.12822 9.58405 4.62087 10.0364C3.98401 10.6046 3.3349 10.6874 2.58584 10.633C2.30462 10.6123 2.01518 10.5934 1.74709 10.5141C0.862178 10.254 0.457509 9.71591 0.503519 8.7876C0.540869 8.02899 0.636487 7.27097 0.748107 6.51946C0.788919 6.24747 0.922466 5.97726 1.06293 5.73601C1.32237 5.29196 1.70267 5.21687 2.16819 5.44333C3.35134 6.01746 3.8222 6.34739 5.88073 5.97607Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2646 9.29649C14.4845 8.72913 14.7446 8.21852 14.8759 7.67658C15.0071 7.13583 15.0094 6.56316 15.0644 6.04131C15.7566 6.04131 16.4978 6.10632 17.2201 6.01826C17.6918 5.96093 18.1487 5.70681 18.5903 5.49583C19.2363 5.18792 19.6684 5.27184 19.909 5.94734C20.136 6.58384 20.2163 7.27471 20.3429 7.94489C20.3807 8.14818 20.3435 8.3639 20.3624 8.57193C20.5006 10.1085 19.4987 10.5523 18.4053 10.6298C18.0034 10.6587 17.5973 10.6475 17.1941 10.6327C17.0723 10.6286 16.9134 10.589 16.8376 10.5051C16.149 9.74622 15.2837 9.36918 14.2646 9.29649Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.0156 2.68554C19.0239 4.17667 17.8803 5.34058 16.3941 5.3536C14.9053 5.36661 13.6959 4.17963 13.6876 2.6956C13.6781 1.2098 14.8697 0.0441109 16.3617 0.000915508C17.7531 -0.0393154 19.1333 1.25241 19.0156 2.68554Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.15842 2.72812C7.14889 4.17228 5.96196 5.3529 4.52303 5.34876C3.02738 5.34404 1.8126 4.15279 1.81491 2.69327C1.81794 1.1652 3.02378 -0.00419223 4.58148 0.00998937C6.02344 0.0229892 7.1678 1.2302 7.15842 2.72812Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
