import { Dispatch, SetStateAction, useRef } from "react";

export const useHideAfterTimeOut = (setOpen: Dispatch<SetStateAction<boolean>>, timeOut = 1000) => {
  const ctOut = useRef<NodeJS.Timeout>();
  const leaveAnchor = () => {
    if (ctOut.current === undefined) {
      ctOut.current = setTimeout(() => {
        setOpen(false);
        ctOut.current = undefined;
      }, timeOut);
    }
  };
  const overDialog = () => {
    if (ctOut.current) {
      clearTimeout(ctOut.current);
      ctOut.current = undefined;
    }
  };
  return { leaveAnchor, overDialog };
};
