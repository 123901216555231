import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { CustomTheme } from "../../helpers/Theme";
const Line = styled("div", {
  skipSx: true,
  skipVariantsResolver: true
})(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.primary.main,
  height: 2,
  width: 88,
  marginTop: 10
}));
export const Title: React.FC<{ mt?: number }> = ({ children, mt }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={mt ?? 25} mb={8}>
      <Typography variant="subtitle1" textAlign="center">
        {children}
      </Typography>
      <Line />
    </Box>
  );
};
