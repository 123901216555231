import { Card, CardMedia, Typography, CardContent, Box, CardActions, Avatar } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

type CardPostProps = {
  author?: string;
  image?: string;
  date?: string;
  title?: string;
  avatar?: string;
  slug?: string;
  id?: string;
};

export const CardPost = ({ author, image, date, title, avatar, slug, id }: CardPostProps) => {
  return (
    <Card
      sx={{
        textDecoration: "none",
        minWidth: { lg: 350, md: 311, sm: 215, xs: 290 },
        width: { lg: 350, md: 311, sm: 215, xs: "90%" },
        borderRadius: 5,
        border: "1px solid #CDCDCD",
        boxShadow: "0px 0px 10px 3px rgba(0,0,0,0.2)"
      }}
      component={RouterLink}
      to={`/blog/${slug}/${id}`}>
      <CardMedia
        sx={{ height: { lg: 210, md: 145, sm: 130, xs: 250 } }}
        image={image}
        title={title}
      />
      <CardContent>
        <Typography variant="h4" lineHeight="1.3rem">
          {title}
        </Typography>
        <CardActions
          sx={{
            height: "42px",
            marginTop: 5,
            display: "flex",
            padding: 0,
            flexWrap: "wrap",
            justifyContent: {
              md: "space-between",
              xs: "center"
            },
            alignItems: "center"
          }}>
          <Box display="flex" alignItems="center" gap={1.5}>
            <Avatar alt="Author avatar" src={avatar} sx={{ width: 24, height: 24 }} />
            <Typography
              variant="caption"
              sx={{ width: { lg: 100, md: 48, sm: 48, xs: 100 } }}
              noWrap={true}>
              {author}
            </Typography>
          </Box>
          <Typography noWrap={true} variant="caption">
            {date}
          </Typography>
        </CardActions>
      </CardContent>
    </Card>
  );
};
