import { SvgIcon, SvgIconProps } from "@mui/material";
export const Drinks = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5197 17.5986H9.88037V18.238H10.5197V17.5986Z" fill="#606A78" />
        <path d="M11.8405 16.9592H11.2012V17.5985H11.8405V16.9592Z" fill="#606A78" />
        <path d="M15.8044 11.9727H15.165V12.612H15.8044V11.9727Z" fill="#606A78" />
        <path d="M14.8229 13.2939H14.1836V13.9333H14.8229V13.2939Z" fill="#606A78" />
        <path d="M15.8044 14.3167H15.165V14.956H15.8044V14.3167Z" fill="#606A78" />
        <path
          d="M14.8232 3.0647H13.502C13.3315 3.0647 13.161 3.19256 13.161 3.40566V7.02848H6.89565C6.72517 7.02848 6.55469 7.15634 6.55469 7.36945V9.33005C6.55469 9.50053 6.68255 9.67101 6.89565 9.67101H7.57759L8.85623 22.5853C8.85623 22.7558 9.02671 22.8836 9.19719 22.8836H15.8035C15.974 22.8836 16.1019 22.7558 16.1445 22.5853L17.4231 9.67101H18.1051C18.2756 9.67101 18.446 9.54315 18.446 9.33005V7.36945C18.446 7.19896 18.3182 7.02848 18.1051 7.02848H15.1216V3.40566C15.1642 3.23518 14.9937 3.0647 14.8232 3.0647ZM13.8429 3.74663H14.4823V7.02848H13.8429V3.74663ZM15.5052 22.2017H9.49555L8.47265 12.0578C9.02673 12.2283 9.58081 12.3135 10.1775 12.3135C11.0299 12.3562 11.8824 12.143 12.6495 11.7594C13.3315 11.4185 14.056 11.248 14.8232 11.248C15.4199 11.2054 16.0166 11.3332 16.5707 11.5463L15.5052 22.2017ZM16.6559 10.907C16.0592 10.6939 15.4625 10.6087 14.8232 10.6087C13.9708 10.5661 13.1184 10.7792 12.3512 11.1628C11.6692 11.5037 10.9447 11.6742 10.1775 11.6742C9.58081 11.6742 8.9841 11.589 8.3874 11.3759L8.21691 9.71362H16.7412L16.6559 10.907ZM17.8067 9.03169H7.23662V7.71041H17.8067V9.03169Z"
          fill="#606A78"
        />
        <path
          d="M11.2013 15.9364C11.457 15.9364 11.7127 15.8511 11.9259 15.638C12.0963 15.4675 12.2242 15.2118 12.2242 14.9135V14.2741C12.2242 14.0184 12.139 13.7627 11.9259 13.5496C11.7554 13.3791 11.4996 13.2512 11.2013 13.2512H10.562C10.3062 13.2512 10.0505 13.3365 9.83739 13.5496C9.66691 13.7201 9.53906 13.9758 9.53906 14.2741V14.9135C9.53906 15.1692 9.62428 15.4249 9.83739 15.638C10.0079 15.8085 10.2636 15.9364 10.562 15.9364H11.2013ZM10.221 14.9561V14.3168C10.221 14.2315 10.2636 14.1463 10.3062 14.061C10.3915 13.9758 10.4767 13.9758 10.562 13.9758H11.2013C11.2865 13.9758 11.3718 14.0184 11.457 14.061C11.5423 14.1463 11.5422 14.2315 11.5422 14.3168V14.9561C11.5422 15.0413 11.4996 15.1266 11.457 15.2118C11.3718 15.2971 11.2865 15.2971 11.2013 15.2971H10.562C10.4767 15.2971 10.3915 15.2544 10.3062 15.2118C10.221 15.1266 10.221 15.0413 10.221 14.9561Z"
          fill="#606A78"
        />
        <path
          d="M13.5029 15.9363C13.2472 15.9363 12.9914 16.0215 12.7783 16.2346C12.6078 16.4051 12.48 16.6608 12.48 16.9592V17.5985C12.48 17.8542 12.5652 18.11 12.7783 18.3231C12.9488 18.4936 13.2046 18.6214 13.5029 18.6214H14.1422C14.398 18.6214 14.6537 18.5362 14.8668 18.3231C15.0373 18.1526 15.1651 17.8968 15.1651 17.5985V16.9592C15.1651 16.7034 15.0799 16.4477 14.8668 16.2346C14.6963 16.0641 14.4406 15.9363 14.1422 15.9363H13.5029ZM14.4832 16.9592V17.5985C14.4832 17.6837 14.4406 17.769 14.3979 17.8542C14.3127 17.8969 14.2275 17.9395 14.1422 17.9395H13.5029C13.4177 17.9395 13.3324 17.8969 13.2472 17.8542C13.1619 17.769 13.1619 17.6837 13.1619 17.5985V16.9592C13.1619 16.8739 13.2045 16.7887 13.2472 16.7035C13.3324 16.6608 13.4177 16.6182 13.5029 16.6182H14.1422C14.2275 16.6182 14.3127 16.6608 14.3979 16.7035C14.4832 16.7461 14.5258 16.8313 14.4832 16.9592Z"
          fill="#606A78"
        />
        <path
          d="M11.8839 18.9199H11.2446C10.9889 18.9199 10.7331 19.0051 10.52 19.2183C10.3495 19.3887 10.2217 19.6445 10.2217 19.9428V20.5821C10.2217 20.8379 10.3069 21.0936 10.52 21.3067C10.6905 21.4772 10.9463 21.6051 11.2446 21.6051H11.8839C12.1397 21.6051 12.3954 21.5198 12.6085 21.3067C12.779 21.1362 12.9068 20.8805 12.9068 20.5821V19.9428C12.9068 19.6871 12.8216 19.4314 12.6085 19.2183C12.3954 19.0051 12.1397 18.9199 11.8839 18.9199ZM12.1823 20.5821C12.1823 20.6674 12.1397 20.7526 12.097 20.8379C12.0118 20.9231 11.9265 20.9231 11.8413 20.9231H11.202C11.1167 20.9231 11.0315 20.8805 10.9462 20.8379C10.861 20.7526 10.861 20.6674 10.861 20.5821V19.9428C10.861 19.8576 10.9036 19.7723 10.9462 19.6871C11.0315 19.6019 11.1167 19.6019 11.202 19.6019H11.8413C11.9265 19.6019 12.0118 19.6445 12.097 19.6871C12.1823 19.7723 12.1823 19.8576 12.1823 19.9428V20.5821Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
