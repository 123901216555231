import React from "react";
import { Container as MUIContainer, useMediaQuery, useTheme } from "@mui/material";
import { CustomTheme } from "../../helpers/Theme";
import { makeStyles } from "@mui/styles";
const getStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    alignItems: "center",
    flexDirection: "column",
    margin: "auto",
    [theme.breakpoints.only("xs")]: {
      width: "75%"
    },
    [theme.breakpoints.only("sm")]: {
      width: "89%"
    },
    [theme.breakpoints.only("md")]: {
      width: "80%"
    },
    [theme.breakpoints.only("lg")]: {
      width: "77%"
    },
    [theme.breakpoints.only("xl")]: {
      width: "85vw"
    }
  }
}));
type ContainerProps = {
  children?: React.ReactNode | undefined;
  disabled?: boolean;
  offsetHeader?: boolean;
};

export const Container = ({ children, disabled, offsetHeader = true }: ContainerProps) => {
  const styles = getStyles();
  const { breakpoints } = useTheme<CustomTheme>();
  const isMobile = useMediaQuery(breakpoints.only("xs"));
  const top = isMobile ? "62px" : "100px";
  const paddingTop = offsetHeader ? top : undefined;
  const marginTop = offsetHeader ? undefined : 0;
  return (
    <MUIContainer
      sx={{ paddingTop, marginTop }}
      className={disabled ? undefined : styles.container}>
      {children}
    </MUIContainer>
  );
};
