import { Box, BoxProps, Typography } from "@mui/material";
import { Icon } from "components/icon";
import { TooltipHotel, TooltipHotelTemplate } from "components/TooltipHotel";
import { ISimpleHotel } from "types/Hotel";

type HotelProps = {
  data: ISimpleHotel;
} & BoxProps;

export const Hotel = ({ data, ...boxProps }: HotelProps) => {
  return (
    <Box display="flex" pl={5} pb={5} flexDirection="row" alignItems="center" {...boxProps}>
      <TooltipHotel
        backgroundColor="white"
        elementAnchor={
          <Box display="flex" alignItems="center" gap={2} sx={{ cursor: "pointer" }}>
            <Icon name="Hotel" size={24} />
            <Typography variant="body1">{data.name}</Typography>
            <Icon name="Info" size={15} />
          </Box>
        }
        placement="top"
        arrow>
        <TooltipHotelTemplate data={data} />
      </TooltipHotel>
    </Box>
  );
};
