import {
  Grid,
  Typography,
  TextField,
  Button,
  Link,
  Box,
  useTheme,
  useMediaQuery
} from "@mui/material";
import { Title } from "../../components/Title";
import { Container } from "../../components/Container";
import { CustomTheme } from "../../helpers/Theme";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { INewContact } from "types/Client";
import { useState } from "react";
import { SaveContact } from "services/Contact";
import { useTitleChange } from "hooks/useTitleChange";
import { sharedData } from "helpers/Consts";
import { ModalSuccess } from "components/ModalSuccess";

const initialData: INewContact = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  description: ""
};

export const Contact = () => {
  useTitleChange("Contacto");
  const { palette, breakpoints } = useTheme<CustomTheme>();
  const isXS = useMediaQuery(breakpoints.only("xs"));
  const [data, setData] = useState<INewContact>(initialData);
  const enableSubmit =
    data.firstName && data.lastName && data.email && data.phone && data.description;
  const [showSuccess, setShowSuccess] = useState(false);
  const closeSuccess = () => {
    setShowSuccess(false);
  };

  const PutClient = async () => {
    const val = { ...data };
    val.phone = `+${val.phone}`;
    try {
      await SaveContact(val);
      setData(initialData);
      setShowSuccess(true);
    } catch (e) {
      console.error(e, "Contact->GetData");
    }
  };

  return (
    <Container disabled={isXS}>
      <ModalSuccess
        description="Mensaje enviado, en breve nos contactaremos contigo."
        open={showSuccess}
        onClose={closeSuccess}
      />
      <Title>Contáctenos</Title>
      <Box sx={{ height: "auto", textAlign: "center" }}>
        <Typography>
          Siempre un oído y una mano atenta a tus consultas sobre seguridad de tu información,
          destinos, paquetes, tours, ofertas, reservas, confirmaciones, novedades, cancelaciones,
          recibos y cotizaciones en inglés o en español.
        </Typography>
        <Typography sx={{ pt: "35px" }}>
          Establece contacto con nosotros en
          <Link aria-label="email" px={1} href={`mailto:${sharedData.email}`}>
            {sharedData.email}
          </Link>
          o en nuestras RRSS, y seguro responderemos a tus premuras en 24 horas como plazo máximo al
          ser recibido tu mail.
        </Typography>
      </Box>
      <Grid container spacing={3} mt={25}>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            required
            id="nombres"
            name="nombres"
            label="Nombres"
            value={data.firstName}
            onChange={({ target }) => setData(d => ({ ...d, firstName: target.value }))}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            id="apellidos"
            name="apellidos"
            label="Apellidos*"
            value={data.lastName}
            onChange={({ target }) => setData(d => ({ ...d, lastName: target.value }))}
            fullWidth
          />
        </Grid>
        <Grid mt={3} item xs={12} sm={6}>
          <TextField
            variant="standard"
            required
            id="correo"
            type="email"
            name="correo"
            label="Correo Electrónico"
            value={data.email}
            onChange={({ target }) => setData(d => ({ ...d, email: target.value }))}
            fullWidth
          />
        </Grid>
        <Grid mt={3} item xs={12} sm={6}>
          <PhoneInput
            specialLabel="Telefono Celular*"
            country={"pe"}
            onChange={phone => setData(d => ({ ...d, phone }))}
            value={data.phone}
            inputProps={{
              id: "telefono",
              name: "telefono"
            }}
            containerStyle={{
              color: "rgba(0, 0, 0, 0.6)"
            }}
            inputStyle={{
              color: "rgba(0, 0, 0, 0.6)",
              margin: 0,
              paddingTop: 18,
              paddingBottom: 11,
              border: "none",
              borderBottom: "1px solid rgba(0, 0, 0, 0.4)",
              borderRadius: 0,
              width: "100%"
            }}
            buttonStyle={{
              paddingTop: 18,
              paddingBottom: 10,
              border: "none",
              borderRadius: 0
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} mt={8}>
          <TextField
            sx={{ background: palette.neutral["200"] }}
            fullWidth
            label="¿Cómo podemos ayudarlo?"
            value={data.description}
            onChange={({ target }) => setData(d => ({ ...d, description: target.value }))}
            id="fullWidth"
          />
        </Grid>
        <Grid
          sx={{
            mt: 10,
            pl: 1
          }}>
          <Typography sx={{ ml: 3 }}>
            Para obtener más información sobre cómo utilizamos sus datos, consulte nuestras
            <Link aria-label="politicas-de-privacidad" href="/politicas-de-privacidad">
              Políticas de Privacidad
            </Link>
          </Typography>
        </Grid>
        <Grid
          sx={{
            width: "100%",
            mt: 20,
            mb: 30,
            ml: 3
          }}>
          <Button
            color="secondary"
            variant="outlined"
            disabled={!enableSubmit}
            onClick={PutClient}
            sx={{
              borderColor: palette.neutral["900"],
              borderRadius: 0,
              width: 160,
              color: palette.neutral["900"]
            }}>
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};
