import { Card, Box, Typography, useTheme, Button } from "@mui/material";
import { TourInfoItem } from "components/TourInfoItem";
import { TitleComing } from "../TitleComing";
import { LabelQuote } from "../LabelQuote";
import { Reactions } from "../Reactions";
import { Subtitle } from "../Subtitle/Subtitle";
import { TourPlaceList } from "../TourPlaceList";
import { CustomTheme } from "helpers/Theme";
import { ImagesTourList } from "../ImagesTourList";
import { AddCircle, Location } from "icons";
import { IQuote } from "types/Quote";
import { LabelInfo } from "../LabelInfo";
import { Hotel } from "../Hotel";
import { useSection } from "./hooks/useSection";
import { useSectionDescription } from "./hooks/useSectionDescription";

export const SecondSection = ({
  quote,
  index,
  showDetails
}: {
  quote: IQuote;
  index: number;
  showDetails(): void;
}) => {
  const { palette } = useTheme<CustomTheme>();
  const tour = quote.tours[index];
  const description = useSectionDescription(quote, index);
  const { date, showDate, title, outletAnotherDay } = useSection(quote, index);
  const hotelIndex = index > 0 && outletAnotherDay ? index - 1 : index;
  const hotel = quote.hotels[hotelIndex];
  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        width: { lg: "77%", md: "96.8%", sm: "89%", xs: "92%" }
      }}>
      <Box flex={1} overflow="hidden">
        <Box p={5}>
          <TitleComing>{title}</TitleComing>
          <Subtitle
            icon="Culture"
            label={tour.types?.map((t: { name: string }) => t.name).join(", ")}
          />
          <Box display="flex" alignItems="center">
            <Box p={1}>
              <Location sx={{ fontSize: 42 }} />
            </Box>
            <Typography variant="h2" color="secondary">
              {tour.description.generalTitle}
            </Typography>
          </Box>
          {showDate && (
            <Box mt={4}>
              <LabelQuote icon="Calendar" label={date.format("DD MMM [,] YYYY")} />
            </Box>
          )}
          <TourPlaceList places={tour.places} />
          <Box width="100%" display="flex" mt={5} flexDirection="column" gap={3}>
            <Typography variant="body1" whiteSpace="pre-line">
              {description}
            </Typography>
          </Box>
        </Box>
        {hotel && <Hotel data={hotel} />}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap={1}
          sx={{ marginBottom: { xs: 2.5, md: 5 } }}>
          <Button onClick={showDetails} endIcon={<AddCircle />}>
            <Typography variant="body2" sx={{ textTransform: "none" }}>
              Leer Más
            </Typography>
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          gap={5}
          style={{ paddingBottom: 20 }}>
          <LabelInfo label="Incluye" data={tour.includes} />
          <LabelInfo label="No incluye" data={tour.noIncludes} />
          <LabelInfo label="Restricciones" data={tour.restrictions} />
        </Box>
        <Reactions mb={5} />
      </Box>
      <Box
        flex={1}
        sx={{
          borderTop: { xs: `solid 2px ${palette.neutral["300"]}`, md: "none" },
          borderLeft: { md: `solid 2px ${palette.neutral["300"]}` }
        }}>
        <Box display="flex" justifyContent="space-evenly" mt={4} flexWrap="wrap">
          <TourInfoItem
            icon="Power"
            title={tour.difficulty?.name as string}
            description={tour.difficulty?.description as string}
          />
          <TourInfoItem
            icon="People"
            title={tour.modality?.name as string}
            description={tour.modality?.description as string}
          />
          <TourInfoItem
            icon="Clock"
            title={(tour.durationHours ?? 1).toString() + " horas"}
            description="Tiempo de duración aproximada del Tour."
          />
        </Box>
        <ImagesTourList images={tour.photos} />
      </Box>
    </Card>
  );
};
