import { SvgIcon, SvgIconProps } from "@mui/material";

export const Youtube = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M10.9993 18.1784C10.9976 18.1784 10.9958 18.1784 10.994 18.1784C10.3945 18.1743 5.09512 18.126 3.59369 17.7199C2.54438 17.4379 1.71607 16.6105 1.43324 15.561C1.04038 14.0861 1.06539 11.2465 1.06811 11.0197C1.06554 10.794 1.04023 7.93093 1.43203 6.4439C1.43248 6.44254 1.43279 6.44102 1.43324 6.43966C1.71288 5.40218 2.55984 4.54915 3.59111 4.26633C3.59369 4.26557 3.59642 4.26496 3.59899 4.26421C5.08345 3.87407 10.3933 3.82633 10.994 3.82224H11.0047C11.6057 3.82633 16.9192 3.87468 18.4069 4.28133C19.4534 4.56264 20.2811 5.38899 20.5647 6.43708C20.9723 7.92517 20.9345 10.7945 20.9305 11.0367C20.9333 11.2753 20.9571 14.0894 20.5665 15.5718C20.5662 15.5733 20.5658 15.5746 20.5655 15.576C20.2825 16.6255 19.4543 17.4529 18.4038 17.7352C18.4025 17.7357 18.4009 17.736 18.3996 17.7365C16.9153 18.1264 11.6053 18.1742 11.0047 18.1784C11.0029 18.1784 11.0011 18.1784 10.9993 18.1784ZM2.93225 6.84192C2.58728 8.15449 2.61986 10.9811 2.62017 11.0096V11.03C2.60986 11.8139 2.64609 14.0841 2.9324 15.1593C3.07123 15.6742 3.47971 16.0821 3.99777 16.2214C5.10557 16.521 9.42177 16.6153 10.9993 16.6264C12.5809 16.6153 16.9035 16.5236 18.0031 16.2359C18.5195 16.0962 18.9266 15.6897 19.0663 15.1742C19.3529 14.0832 19.3889 11.8241 19.3784 11.0452C19.3784 11.037 19.3784 11.0288 19.3786 11.0206C19.3928 10.2273 19.3648 7.93063 19.0677 6.84677C19.0674 6.84571 19.0671 6.84465 19.0669 6.84359C18.9275 6.32644 18.5189 5.91857 18.0008 5.77928C16.9038 5.47932 12.5806 5.38535 10.9993 5.37429C9.41874 5.38535 5.10042 5.4769 3.99746 5.76427C3.48926 5.90523 3.07154 6.32765 2.93225 6.84192ZM19.8161 15.3741H19.8166H19.8161ZM9.02117 14.1431V7.85742L14.4534 11.0003L9.02117 14.1431Z"
        fill={props.htmlColor || "white"}
      />
    </SvgIcon>
  );
};
