import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";
export const Heart = (props: CustomSvgProps) => {
  const SvgSize = props.size || 25;
  return (
    <SvgIcon
      {...props}
      width="21"
      height="21"
      viewBox="0 0 21 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        fill={props.htmlColor || "#606A78"}
        fillRule="evenodd"
        d="M10.524 2.887c.62-.951 1.362-1.74 2.36-2.257 3.502-1.812 7.51.695 7.452 4.635-.037 2.459-.846 4.567-2.669 6.237-1.697 1.554-3.427 3.072-5.14 4.609-.678.61-1.35 1.229-2.045 1.86-2.525-2.3-5.098-4.544-7.551-6.91C.957 9.156.363 6.694.807 4.044 1.129 2.122 2.353.82 4.234.271 6.276-.326 8.066.214 9.555 1.723c.342.346.626.749.969 1.164"
        clipRule="evenodd"></path>
    </SvgIcon>
  );
};
