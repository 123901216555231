import { Menu } from "@mui/material";
import { DestinationItem } from "components/DestinationItem";
import { useEffect, useState } from "react";
import { GetAllDestinations } from "services/Destination";
import { IDestination } from "types/Destination";

type DestinationMenuProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  onClose: () => void;
  mouseOver: () => void;
  isTransparent: boolean;
};

export const DestinationMenu = ({
  anchorEl,
  onClose,
  open,
  isTransparent,
  mouseOver
}: DestinationMenuProps) => {
  const [destinations, setDestinations] = useState<IDestination[]>([]);

  useEffect(() => {
    const GetData = async () => {
      try {
        const data = await GetAllDestinations();
        setDestinations(data);
      } catch (e) {
        console.error(e, "Destination->GetData");
      }
    };
    GetData();
  }, []);
  return (
    <Menu
      PaperProps={{
        onMouseLeave: onClose,
        onMouseOver: mouseOver,
        sx: {
          backgroundColor: isTransparent ? "#79797980" : "common.white",
          pointerEvents: "auto"
        }
      }}
      sx={{ pointerEvents: "none" }}
      anchorEl={anchorEl}
      open={destinations.length > 0 && open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      MenuListProps={{
        "aria-labelledby": "basic-button",
        sx: { paddingInline: 2.5 }
      }}>
      {destinations.map(d => (
        <DestinationItem
          isTransparent={isTransparent}
          key={d.id}
          label={d.name}
          routes={`/destinos/${d.id}/paquetes`}
          onClose={onClose}
        />
      ))}
    </Menu>
  );
};
