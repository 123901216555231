import {
  IncludeArrivalOptions,
  IncludeDescription,
  IncludeOutletOptions,
  OutletDescription
} from "helpers/Consts";
import { IQuote } from "types/Quote";

export const useSectionDescription = (quote: IQuote, index: number) => {
  const tour = quote.tours[index];
  const isLastTour = quote.tours.length - 1 === index;
  const isFirstTour = index === 0;
  const arrivalSameDay =
    quote.includedIn.included &&
    quote.includedIn.typeIncluded.id === IncludeArrivalOptions.SameDay &&
    isFirstTour;
  const outletSameDay =
    quote.includedOut.included &&
    quote.includedOut.typeIncluded.id === IncludeOutletOptions.SameDay &&
    isLastTour;
  const prefix = arrivalSameDay ? `${IncludeDescription}\n` : "";
  const suffix = outletSameDay ? `\n${OutletDescription}` : "";
  return prefix + tour.description?.generalDescription + suffix;
};
