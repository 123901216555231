export const Sms = () => {
  return (
    <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.5 27.625C6.5 16.25 13 11.375 22.75 11.375H55.25C65 11.375 71.5 16.25 71.5 27.625V50.375C71.5 61.75 65 66.625 55.25 66.625H22.75"
        stroke="#EB5027"
        strokeWidth="4.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.25 29.25L45.0775 37.375C41.73 40.04 36.2375 40.04 32.89 37.375L22.75 29.25"
        stroke="#EB5027"
        strokeWidth="4.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 53.625H26"
        stroke="#EB5027"
        strokeWidth="4.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 40.625H16.25"
        stroke="#EB5027"
        strokeWidth="4.875"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
