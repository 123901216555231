import { SvgIcon, SvgIconProps } from "@mui/material";
export const ArrowCircleRight = (props: SvgIconProps) => {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || "24"}
      height={props.height || "25"}
      viewBox="0 0 35 35"
      fill="none">
      <path
        d="M17.4998 32.0833C25.554 32.0833 32.0832 25.5541 32.0832 17.5C32.0832 9.44581 25.554 2.91663 17.4998 2.91663C9.44568 2.91663 2.9165 9.44581 2.9165 17.5C2.9165 25.5541 9.44568 32.0833 17.4998 32.0833Z"
        stroke={props.htmlColor || "#606A78"}
        fill="none"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6626 22.6479L20.7959 17.5L15.6626 12.3521"
        stroke={props.htmlColor || "#606A78"}
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
