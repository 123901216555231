import { Typography, Box } from "@mui/material";
import { Title } from "../../components/Title";
import { Container } from "../../components/Container";
import { TourCard } from "../../components/TourCard";
import Ofert from "../../assets/Ofert.png";
import { useEffect, useState } from "react";
import { GetPackagesByDestination } from "services/Destination";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootDispatch, RootState } from "state/store";
import { PackageActions } from "state/actions/PackageActions";
import { ParamsType } from "types/Common";
import { BreadCrumbs } from "components/BreadCrumbs";
import { LoaderIndicator } from "components/LoaderIndicator";
import { useTitleChange } from "hooks/useTitleChange";

export const Offer = () => {
  useTitleChange("Paquetes");
  const { packages } = useSelector((state: RootState) => state.PackageReducer);
  const { id } = useParams<ParamsType>();
  const getInverted = (index: number) => !((index + 1) % 2);

  const dispatch = useDispatch<RootDispatch>();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const GetData = async (idDestination: string) => {
      try {
        const data = await GetPackagesByDestination(idDestination);
        dispatch(PackageActions.SetPackages(data));
      } catch (e) {
        console.error(e, "Packages->GetData");
      }
      setLoading(false);
    };
    if (id) GetData(id);
  }, [id]);
  return (
    <Box>
      <LoaderIndicator isLoading={loading} />
      <Container>
        <BreadCrumbs
          items={[
            {
              label: "Destinos",
              to: "/destinos"
            },
            {
              label: "Cusco",
              to: ""
            }
          ]}
        />
        <Title mt={10}>Ofertas</Title>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            sx={{ maxWidth: 922, lineHeight: "39px", textAlign: { xs: "justify", md: "center" } }}>
            Nuestras ofertas están diseñadas para que no te pierdas ni uno solo de los rincones que
            has venido a conocer a Perú. Te vas a enamorar de nuestros paisajes, la gastronomía y la
            magia que cada destino te ofrece. <b>Inka Planet Adventure</b> es tu elección si quieres
            diseñar un tour a tu manera y vivir la experiencia más inolvidable.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 10, mt: 30, mb: 30 }}>
          {packages.map((p, index) => (
            <TourCard
              id={p.id}
              key={p.id}
              title={p.name}
              inverted={getInverted(index)}
              description={p.description.generalDescription}
              image={p.salePhoto || Ofert}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};
