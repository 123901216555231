/* eslint-disable react/jsx-key */
import { Box, Button, Typography } from "@mui/material";

import { CardPost } from "components/CardPost";
import { AddCircle } from "icons";
import { useEffect, useState } from "react";
import { ListPosts } from "services/Contentful";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { LoaderIndicator } from "components/LoaderIndicator";
import { Item } from "types/Posts";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");
dayjs.extend(localizedFormat);
export const NewPostContent = () => {
  const [posts, setPosts] = useState<Item[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const GetData = async () => {
      try {
        const data = await ListPosts();
        setPosts(data.data.pageBlogPostCollection.items);
      } catch (e) {
        console.error(e, "ListPosts->GetData");
      }
      setLoading(false);
    };
    GetData();
  }, []);

  return (
    <>
      <LoaderIndicator isLoading={loading} />
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
        mb={20}
        mt={10}>
        <Box
          mb={30}
          marginX={{ xs: 5, sm: 0, lg: 5 }}
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          gap={5}
          maxWidth={1440}>
          {posts.map(posts => (
            <CardPost
              slug={posts.slug}
              id={posts.sys.id}
              key={posts.sys.id}
              author={posts.author.name}
              image={posts.featuredImage.url}
              avatar={posts.author.avatar.url}
              date={dayjs(posts.publishedDate).format("DD/MM/YYYY")}
              title={posts.title}
            />
          ))}
        </Box>
        <Button endIcon={<AddCircle />}>
          <Typography variant="body2" sx={{ textTransform: "none" }}>
            Leer Más
          </Typography>
        </Button>
      </Box>
    </>
  );
};
