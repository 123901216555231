/* eslint-disable indent */
import { Box, Button, useMediaQuery } from "@mui/material";
import { Footer } from "../../components/Footer";
import { makeStyles } from "@mui/styles";
import { CustomTheme } from "../../helpers/Theme";
import { useTitleChange } from "hooks/useTitleChange";
import { useState } from "react";
import { Play } from "icons";
import { ModalSuccess } from "components/ModalSuccess";
import { homeImagesQuery, videoInkaUrl } from "helpers/Consts";

const getStyles = makeStyles<CustomTheme>((theme: CustomTheme) => ({
  background: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    objectPosition: "top",
    objectFit: "cover",
    zIndex: -1,
    transition: "ease"
  },
  live: {
    [theme.breakpoints.up("md")]: {
      width: "70%"
    },
    [theme.breakpoints.down("md")]: {
      width: "60%"
    }
  },
  iframe: {
    width: "100%",
    height: "100%"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.down("sm")]: {
      width: "95%"
    }
  },
  playButton: {
    backgroundColor: "transparent",
    color: "white",
    margin: "auto",
    borderColor: "white",
    marginTop: 30,
    fontSize: 13,
    "&:hover": {
      backgroundColor: "#dbdbdb40",
      borderColor: "#dbdbdb"
    }
  }
}));

export const Home = () => {
  const styles = getStyles();
  const mobileQuery = "(max-width: 575px)";
  const tableQuery = "(min-width: 575px) and (max-width: 875px)";
  useTitleChange("Inicio");
  const isMobile = useMediaQuery(mobileQuery);
  const isStretch = useMediaQuery("(max-width: 750px)");
  const isTable = useMediaQuery(tableQuery);
  const [showSuccess, setShowSuccess] = useState(false);

  const backgroundImageUrl = isTable
    ? homeImagesQuery.table
    : isMobile
    ? homeImagesQuery.mobile
    : homeImagesQuery.desktop;

  const showModal = () => {
    setShowSuccess(!showSuccess);
  };

  return (
    <Box>
      <ModalSuccess showVideo open={showSuccess} onClose={showModal}>
        <iframe
          className={styles.iframe}
          src={videoInkaUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          data-allow></iframe>
      </ModalSuccess>
      <img className={styles.background} src={backgroundImageUrl} alt="image-peru" />
      <Box className={styles.content}>
        {!isStretch && (
          <img
            onClick={showModal}
            className={styles.live}
            src={require("../../assets/live-it.webp")}
            alt="image-peru"
          />
        )}
        <Button
          startIcon={<Play />}
          variant="outlined"
          className={styles.playButton}
          onClick={showModal}>
          Ver video
        </Button>
      </Box>
      <Box position="fixed" bottom={-40} left={0} right={0}>
        <Footer hideScrollToTop />
      </Box>
    </Box>
  );
};
