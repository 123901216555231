import { Box, IconButton, Link, Typography, useTheme } from "@mui/material";
import { SocialMediaUrl } from "helpers/Consts";
import { CustomTheme } from "helpers/Theme";
import { Facebook, Instagram, TikTok, Youtube } from "icons";
export const SocialMedia = () => {
  const { palette } = useTheme<CustomTheme>();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h4" align="center" mb={5}>
        Síguenos a través de nuestras redes sociales
      </Typography>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        gap={5}
        textAlign="center"
        flexWrap="wrap">
        <Link
          width={{ lg: "auto", sm: "auto", xs: "40%" }}
          justifyContent="center"
          display="flex"
          alignItems="center"
          variant="body1"
          href={SocialMediaUrl.Facebook}
          target="_blank"
          color="secondary"
          underline="none">
          <IconButton aria-label="Facebook" size="small">
            <Facebook htmlColor={palette.primary.main} />
          </IconButton>
          Facebook
        </Link>
        <Link
          width={{ lg: "auto", sm: "auto", xs: "40%" }}
          justifyContent="center"
          display="flex"
          alignItems="center"
          variant="body1"
          href={SocialMediaUrl.Instagram}
          target="_blank"
          color="secondary"
          underline="none">
          <IconButton aria-label="Instagram" size="small">
            <Instagram htmlColor={palette.primary.main} />
          </IconButton>
          Instagram
        </Link>
        <Link
          width={{ lg: "auto", sm: "auto", xs: "40%" }}
          justifyContent="center"
          display="flex"
          alignItems="center"
          variant="body1"
          href={SocialMediaUrl.Youtube}
          target="_blank"
          color="secondary"
          underline="none">
          <IconButton aria-label="Youtube" size="small">
            <Youtube htmlColor={palette.primary.main} />
          </IconButton>
          Youtube
        </Link>
        <Link
          width={{ lg: "auto", sm: "auto", xs: "40%" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          variant="body1"
          href={SocialMediaUrl.Tiktok}
          target="_blank"
          color="secondary"
          underline="none">
          <IconButton aria-label="TikTok" size="small">
            <TikTok htmlColor={palette.primary.main} />
          </IconButton>
          Tiktok
        </Link>
      </Box>
    </Box>
  );
};
