import { Box, IconButton, Portal, useTheme } from "@mui/material";
import { Close, Menu } from "@mui/icons-material";
import logo from "assets/Logo.png";
import logoTransparent from "assets/LogoTransparent.png";
import { RefObject, useState } from "react";
import { MobileContent } from "./MobileContent";
import { CustomTheme } from "helpers/Theme";
import { Link as RouterLink } from "react-router-dom";

export const Mobile = ({
  isTransparent,
  portalRef
}: {
  isTransparent: boolean;
  portalRef: RefObject<HTMLDivElement>;
}) => {
  const { palette, transitions } = useTheme<CustomTheme>();
  const [showContent, setShowContent] = useState(false);
  const opacity = showContent ? "ff" : "80";
  const border = showContent ? 1 : undefined;
  const useTransparent = isTransparent && !showContent;
  const borderColor = useTransparent ? palette.neutral["100"] : palette.neutral["400"];
  const iconColor = useTransparent ? palette.neutral["100"] : palette.neutral["900"];
  const transition = transitions.create("background-color", {
    duration: transitions.duration.short,
    easing: transitions.easing.easeInOut
  });
  return (
    <>
      <Box
        height={62}
        flex={1}
        sx={{ transition }}
        bgcolor={useTransparent ? `#797979${opacity}` : "common.white"}
        borderBottom={border}
        borderColor={borderColor}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        px={4}>
        {!showContent ? (
          <IconButton
            onClick={() => setShowContent(s => !s)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}>
            <Menu htmlColor={iconColor} />
          </IconButton>
        ) : (
          <Box width={44} />
        )}
        <RouterLink to="/" aria-label="logo" onClick={() => setShowContent(false)}>
          <img
            src={useTransparent ? logoTransparent : logo}
            alt="logo-mobile"
            width={78.39}
            height={35.58}
          />
        </RouterLink>
        {showContent ? (
          <IconButton
            onClick={() => setShowContent(s => !s)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="close menu"
            sx={{ mr: 2 }}>
            <Close htmlColor={iconColor} />
          </IconButton>
        ) : (
          <Box width={44} />
        )}
      </Box>
      {showContent && (
        <Portal container={portalRef.current}>
          <MobileContent setShowContent={setShowContent} isTransparent={useTransparent} />
        </Portal>
      )}
    </>
  );
};
