import { Helmet } from "react-helmet";
type HelmetSeoProps = {
  title?: string;
  description?: string;
  keyword?: string[];
};
export const HelmetSEO = ({ title, description, keyword }: HelmetSeoProps) => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword?.join(", ")} />
    </Helmet>
  );
};
