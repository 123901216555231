import { SvgIcon, SvgIconProps } from "@mui/material";

export const BottomUp = ({ size, ...props }: SvgIconProps & { size?: number }) => {
  return (
    <SvgIcon
      width="16"
      height="22"
      viewBox="0 0 16 22"
      style={{ fontSize: size ?? 24 }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M8.70711 0.821394C8.31658 0.43087 7.68342 0.43087 7.29289 0.821394L0.928933 7.18536C0.538408 7.57588 0.538408 8.20905 0.928933 8.59957C1.31946 8.99009 1.95262 8.99009 2.34315 8.59957L8 2.94271L13.6569 8.59957C14.0474 8.99009 14.6805 8.99009 15.0711 8.59957C15.4616 8.20905 15.4616 7.57588 15.0711 7.18536L8.70711 0.821394ZM9 21.4717L9 1.5285L7 1.5285L7 21.4717L9 21.4717Z"
        fill="#EB5027"
      />
    </SvgIcon>
  );
};
