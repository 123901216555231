import { Box, BoxProps, Typography, CardMedia, CardContent, Card, SxProps } from "@mui/material";
import { CustomTheme } from "../../helpers/Theme";
import defaultImg from "assets/imgItineraryItem.png";

type ItineraryItemProps = {
  boxProps?: BoxProps;
  number: string;
  title: string;
  subtitle: string;
  description: string;
  img?: string;
};

export const ItineraryItem = ({
  boxProps,
  number,
  title,
  subtitle,
  description,
  img
}: ItineraryItemProps) => {
  const cardProps: SxProps<CustomTheme> = {
    minWidth: 252,
    overflow: "hidden",
    width: { sm: 311, md: 354 },
    minHeight: 394,
    height: { xs: 490, md: 518 },
    borderRadius: 5
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        gap: 5,
        py: 2,
        marginBottom: 30,
        userSelect: "none"
      }}
      {...boxProps}>
      <Card elevation={2} sx={cardProps}>
        <CardContent>
          <Typography
            sx={{
              fontFamily: "Zilla Slab",
              fontSize: "100px",
              fontStyle: "normal",
              fontWeight: "300",
              lineHeight: "120px",
              letterSpacing: "0.08em",
              textAlign: "center",
              color: "secondary.main"
            }}>
            {number}
          </Typography>
          <Typography variant="h4" mt={2.5} textAlign="center">
            {title}
          </Typography>
          <Box px={5} mt={5}>
            <Typography variant="h3">{subtitle}</Typography>
            <Typography
              my={3}
              variant="body1"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="break-spaces"
              maxHeight={265}>
              {description}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      <Card sx={cardProps}>
        <CardMedia
          sx={{ width: "100%", height: "100%", objectFit: "cover", pointerEvents: "none" }}
          component="img"
          alt="defaultImg"
          image={img ?? defaultImg}
        />
      </Card>
    </Box>
  );
};
