import { Box, Card, CardContent, Typography, CardMedia, Link, SxProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { CustomTheme } from "../../helpers/Theme";
const getStyles = (inverted: boolean) => {
  const container: SxProps<CustomTheme> = {
    display: "flex",
    height: "auto",
    marginTop: "6rem",
    alignSelf: {
      xs: "start",
      sm: inverted ? "end" : "start",
      md: "start"
    },
    flexDirection: {
      xs: "column-reverse",
      md: inverted ? "row-reverse" : "row"
    },
    width: {
      xs: "100%",
      sm: "68%",
      md: "100%"
    }
  };
  const content: SxProps<CustomTheme> = {
    width: {
      xs: "100%",
      md: "50%",
      lg: "65vw",
      xl: "70vw"
    },
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
    justifyContent: "space-between"
  };
  const identifier: SxProps<CustomTheme> = {
    fontFamily: "Montserrat",
    color: "#0000000F",
    fontWeight: "600",
    lineHeight: "85px",
    fontSize: "70px"
  };
  const image: SxProps<CustomTheme> = {
    borderRadius: 5,
    width: {
      xs: "100%",
      md: "50%",
      lg: "35vw",
      xl: "30vw"
    },
    aspectRatio: "4 / 3"
  };
  return { container, content, identifier, image };
};
type DestinationCardProps = {
  inverted?: boolean;
  image: string;
  title: string;
  description: string;
  identifier: string;
  id: string;
};

export const DestinationCard = ({
  inverted,
  image,
  title,
  description,
  identifier,
  id
}: DestinationCardProps) => {
  const styles = getStyles(!!inverted);
  return (
    <Box flex={1} display="flex" flexDirection="column">
      <Card elevation={0} sx={styles.container}>
        <CardContent sx={styles.content}>
          <Box mx={10}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pb={5}
              flexDirection={inverted ? "row-reverse" : "row"}>
              <Typography sx={styles.identifier}>{identifier}</Typography>
              <Typography variant="subtitle2">{title}</Typography>
            </Box>
            <Typography
              textAlign={inverted ? "left" : "right"}
              variant="body2"
              whiteSpace="pre-line"
              color="text.secondary"
              overflow="hidden"
              maxHeight={390}>
              {description}
            </Typography>
            <Box display="flex" mt={5} justifyContent={inverted ? "start" : "end"}>
              <Link
                aria-label="destinos-paquetes"
                to={`/destinos/${id}/paquetes`}
                component={RouterLink}
                underline="hover"
                sx={{ textUnderlineOffset: 10 }}>
                Ver Paquetes
              </Link>
            </Box>
          </Box>
        </CardContent>
        <CardMedia component="img" image={image} sx={styles.image} />
      </Card>
    </Box>
  );
};
