import { Box } from "@mui/material";
import { ContentTextAbout } from "./components/ContentTextAbout";
import { OneCard } from "./components/OneCard";
import { TwoCard } from "./components/TwoCard";
import { Title } from "../../components/Title";
import { Container } from "../../components/Container";
import { ContentTitle } from "../../components/ContentTitle";
import { useTitleChange } from "hooks/useTitleChange";
import { AboutImages } from "helpers/Consts";

export const About = () => {
  useTitleChange("Nosotros");
  return (
    <>
      <ContentTitle title="Nosotros" img={AboutImages.Bg} />
      <Container offsetHeader={false}>
        <Title>Nuestra Historia</Title>
        <ContentTextAbout />
        <Box
          sx={{
            display: "flex",
            mt: 30,
            mb: 40,
            gap: 20,
            flexWrap: "wrap",
            justifyContent: "center",
            maxWidth: {
              xl: "85vw"
            }
          }}>
          <OneCard />
          <TwoCard />
        </Box>
      </Container>
    </>
  );
};
