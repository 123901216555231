import { ArrowForward } from "@mui/icons-material";
import { MenuItem, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";
import { useState } from "react";
import { CustomTheme } from "../../helpers/Theme";

type DestinationItemProps = {
  onClose: () => void;
  label: string;
  routes: string;
  isTransparent: boolean;
};

export const DestinationItem = ({
  onClose,
  label,
  routes,
  isTransparent
}: DestinationItemProps) => {
  const [isHover, setHover] = useState(false);
  const defaultTextColor = isTransparent ? "common.white" : "common.black";
  const defaultIconColor = isTransparent ? "#fff" : "#000";

  const { palette } = useTheme<CustomTheme>();

  return (
    <MenuItem
      component={RouterLink}
      to={routes}
      aria-label="DestinationItem"
      sx={{
        borderRadius: 1,
        color: isHover ? "common.black" : defaultTextColor,
        "&:hover": {
          backgroundColor: isTransparent ? "common.white" : palette.neutral[200]
        }
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClose}>
      {isHover ? (
        <ArrowForward
          sx={{ mr: 2.5 }}
          htmlColor={isHover ? "#000" : defaultIconColor}
          fontSize="small"
        />
      ) : (
        <Box width={30} />
      )}
      {label}
    </MenuItem>
  );
};
