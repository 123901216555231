import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";

export const ArrowFillRight = (props: CustomSvgProps) => {
  const SvgSize = props.size || 14;
  return (
    <SvgIcon
      width={props.width || "13"}
      height={props.height || "16"}
      fill="none"
      viewBox="0 0 13 16"
      {...props}
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        fill={props.htmlColor || "#606A78"}
        d="M13.015 7.424L6.487.128C6.36.128 6.103 0 5.847 0H1.495a.704.704 0 00-.64.384C.727.64.727.896.983 1.152l6.144 6.784L.983 14.72c-.128.256-.256.512-.128.768a.704.704 0 00.64.384h4.352c.256 0 .384-.128.512-.256l6.656-7.168c.128-.256.128-.64 0-1.024z"></path>
    </SvgIcon>
  );
};
