import { Box, Typography, Link } from "@mui/material";
import { ContentTitle } from "components/ContentTitle";
import { Container } from "components/Container";
import { Title } from "components/Title";
import Details from "assets/Details.png";
import { ItineraryCarousel } from "./components/ItineraryCarousel";
import { OtherTours } from "./components/OtherTours";
import { useParams } from "react-router-dom";
import { ParamsType } from "types/Common";
import { RootDispatch, RootState } from "state/store";
import { useDispatch, useSelector } from "react-redux";
import { BreadCrumbs } from "components/BreadCrumbs";
import { useState, useEffect } from "react";
import { LoaderIndicator } from "components/LoaderIndicator";
import { GetPackage } from "services/Package";
import { PackageActions } from "state/actions/PackageActions";
import { useTitleChange } from "hooks/useTitleChange";
import { AutoScroll } from "./components/AutoScroll";
import { sharedData } from "helpers/Consts";
export const Package = () => {
  useTitleChange("Detalle de Paquete");
  const dispatch = useDispatch<RootDispatch>();
  const { id } = useParams<ParamsType>();
  const pack = useSelector((state: RootState) => state.PackageReducer.pack);
  const [loading, setLoading] = useState(true);
  const pageDestination = `/destinos/${pack?.destination.id}/paquetes`;
  const message = encodeURIComponent(`Hola IPA, estoy interesado en el paquete *${pack?.name}*`);
  const whatsappURL = `https://wa.me/${sharedData.tel.replace(" ", "")}?text=${message}`;
  useEffect(() => {
    const GetData = async (idPack: string) => {
      try {
        const data = await GetPackage(idPack);
        dispatch(PackageActions.SetPackageSelected(data));
      } catch (e) {
        console.error(e, "Packages->GetData");
      }
      setLoading(false);
    };
    let timer: NodeJS.Timer;
    if (id && (!pack || pack?.id !== id)) {
      GetData(id);
    } else {
      timer = setTimeout(() => setLoading(false), 1500);
    }
    return () => timer && clearTimeout(timer);
  }, [pack]);
  return (
    <Box>
      <LoaderIndicator isLoading={loading} />
      <ContentTitle title={pack?.name ?? ""} img={pack?.principalPhoto || Details} />
      {!loading && <AutoScroll time={2000} />}
      <BreadCrumbs
        items={[
          {
            label: "Destinos",
            to: "/destinos"
          },
          {
            label: pack?.destination.name,
            to: pageDestination
          },
          {
            label: pack?.name,
            to: ""
          }
        ]}
        pl={{ lg: 41, md: 4, sm: 10, xs: 4 }}
      />
      <Container offsetHeader={false}>
        <Title mt={10}>{pack?.name}</Title>

        <Box sx={{ height: "auto", textAlign: "center" }}>
          <Typography sx={{ whiteSpace: "pre-wrap" }}>
            {pack?.description.specificDescription}
          </Typography>
          <Typography mt={15}>
            <Link
              href={whatsappURL}
              target="_blank"
              aria-label="package"
              underline="none"
              sx={{ "&:hover": { cursor: "pointer" } }}>
              Cotizar en Línea
            </Link>
          </Typography>
        </Box>
      </Container>
      {pack && <ItineraryCarousel />}
      <OtherTours />
    </Box>
  );
};
