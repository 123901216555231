import { PublicApi } from "helpers/API";
import { IPackage } from "types/package";

export const GetPackage = (id: string) => {
  return new Promise<IPackage>(async (res, rej) => {
    try {
      const response = await PublicApi.get<IPackage[]>(`package-by-id?id=${id}`);
      res(response.data?.[0]);
    } catch (e) {
      rej(e);
    }
  });
};
