import { Typography } from "@mui/material";
export const ContentTextAbout = () => {
  return (
    <Typography
      maxWidth={1200}
      variant="body1"
      lineHeight="2.5rem"
      textAlign="center"
      p={{ xs: 0, sm: 6.5, md: 11.5 }}>
      Inka Planet Adventure (IPA) se origina gracias a los continuos años de experiencia de
      Marianela Mendoza., fundadora de Inka Planet Adventure, quien con más de 20 años de
      experiencia dentro del sector turístico fue que pudo descubrir e identificar los muchos
      aspectos y necesidades para la mejora en la experiencia del turista. Tuvo la suerte de
      trabajar para numerosas y variadas empresas de renombre dentro de la ciudad del Cusco. Y
      gracias a su pasión inagotable pudo inculcar a sus hijos la misma vocación. El turismo y la
      profesionalidad corren en la sangre de la familia, llevándolos a tomar el reto de construir
      una verdadera experiencia personalizada e individual. Y los años que llevan dentro de la
      Ciudad del Cusco son prueba del gran afán que tienen por llevar a cabo esta tarea. Contamos
      con una gran trayectoria y experiencia dentro del rubro turístico. Y nuestra pasión por los
      diferentes destinos de nuestro país es lo que de verdad nos ha incentivado. Y no fue hasta
      hace poco que decidimos emprender y llevar nuestra experiencia en una gestión ágil,
      personalizada y a tu medida. Nos llena de placer poder enseñar nuestra rica cultura viva,
      paisajes únicos y distinguida gastronomía. Como empresa familiar, buscamos exponer la gran
      cultura que caracteriza nuestro país. Todo en un proceso lleno de magia donde podrá conocer y
      practicar las muchas tradiciones antiguas que nos dejaron nuestros antepasados. Siempre con el
      objetivo de transmitir los misterios y aventuras, a través de experiencias memorables.
    </Typography>
  );
};
