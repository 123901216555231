import { SvgIcon, SvgIconProps } from "@mui/material";
type Props = SvgIconProps & { active?: boolean };
export const Dislike = ({ active, ...props }: Props) => {
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15.61 5.50002V15.66C15.61 16.06 15.49 16.45 15.27 16.78L12.54 20.84C12.11 21.49 11.04 21.95 10.13 21.61C9.15002 21.28 8.50002 20.18 8.71002 19.2L9.23002 15.93C9.27002 15.63 9.19002 15.36 9.02002 15.15C8.85002 14.96 8.60002 14.84 8.33002 14.84H4.22002C3.43002 14.84 2.75002 14.52 2.35002 13.96C1.97002 13.42 1.90002 12.72 2.15002 12.01L4.61002 4.52002C4.92002 3.28002 6.27002 2.27002 7.61002 2.27002H11.51C12.18 2.27002 13.12 2.50002 13.55 2.93002L14.83 3.92002C15.32 4.30002 15.61 4.88002 15.61 5.50002Z"
        fill={active ? "#2864F8" : "#606A78"}
      />
      <path
        d="M18.7904 17.61H19.8204C21.3704 17.61 22.0004 17.01 22.0004 15.53V5.48002C22.0004 4.00002 21.3704 3.40002 19.8204 3.40002H18.7904C17.2404 3.40002 16.6104 4.00002 16.6104 5.48002V15.54C16.6104 17.01 17.2404 17.61 18.7904 17.61Z"
        fill={active ? "#2864F8" : "#606A78"}
      />
    </SvgIcon>
  );
};
