import { EPackagesType } from "state/actions/PackageActions";
import { IPackage } from "types/package";

type State = {
  packages: IPackage[];
  pack?: IPackage;
};
const initialState: State = {
  packages: [],
  pack: undefined
};

type ActionType = {
  type: EPackagesType;
  payload?: unknown;
};
export interface IAction<T> {
  type: T;
  payload: T extends EPackagesType.SetPackages
    ? IPackage[]
    : T extends EPackagesType.SetPackageSelected
    ? IPackage
    : undefined;
}

export const PackageReducer = (state = initialState, action: ActionType) => {
  switch (action.type) {
    case EPackagesType.SetPackages: {
      const val = action as IAction<typeof action.type>;
      const packages = val.payload;
      return { ...state, packages };
    }
    case EPackagesType.SetPackageSelected: {
      const val = action as IAction<typeof action.type>;
      const pack = val.payload;
      return { ...state, pack };
    }
    default:
      return state;
  }
};
