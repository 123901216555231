import { Box, Typography } from "@mui/material";
import { IconName } from "types/Common";
import { Icon } from "components/icon";

type LabelModalProps = {
  icon?: IconName;
  label: string;
};

export const Subtitle = ({ icon, label }: LabelModalProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      sx={{ paddingBlock: { xs: 5 }, marginLeft: { xs: 5, sm: 12 } }}>
      {icon && <Icon name={icon} size={20} />}
      <Typography color="secondary" fontWeight={500} fontSize={18}>
        {label}
      </Typography>
    </Box>
  );
};
