import { useState } from "react";
import { IQuote } from "types/Quote";
import { SecondSection } from "../SecondSection";
import { TourDescriptionModal } from "../TourDescriptionModal";

export const TourSection = ({ quote, index }: { quote: IQuote; index: number }) => {
  const [showDetails, setShowDetails] = useState(false);
  return (
    <>
      <SecondSection quote={quote} index={index} showDetails={() => setShowDetails(true)} />
      <TourDescriptionModal
        quote={quote}
        index={index}
        onClose={() => setShowDetails(false)}
        open={showDetails}
      />
    </>
  );
};
