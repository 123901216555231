import { Tooltip, tooltipClasses, TooltipProps, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TooltipCustomType } from "./types";

const TooltipComponent = styled(
  ({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />,
  { shouldForwardProp: prop => prop !== "tooltipColor" }
)<{ tooltipColor: string }>(({ theme, tooltipColor }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: tooltipColor
  },
  [`& .${tooltipClasses.tooltip}`]: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    borderRadius: 20,
    backgroundColor: tooltipColor,
    color: "rgba(0, 0, 0, 0.87)",
    minWidth: 246,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "1px 1px 12px -2px rgba(0,0,0,0.45)"
  }
}));

export const TooltipCustom = ({
  placement,
  children,
  elementAnchor,
  backgroundColor,
  ...props
}: TooltipCustomType) => {
  const theme = useTheme();
  return (
    <TooltipComponent
      {...props}
      tooltipColor={backgroundColor ?? theme.palette.common.white}
      title={children}
      placement={placement}>
      {elementAnchor ?? <p>Element Anchor</p>}
    </TooltipComponent>
  );
};
