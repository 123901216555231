import { SvgIcon, SvgIconProps } from "@mui/material";

export const Facebook = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fill={props.htmlColor || "white"}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.73885 19.6415H11.8298V11.8386H14.9113L15.4205 8.74659H11.8298V6.60213C11.8298 5.75557 12.4793 5.10599 13.3259 5.10599H15.3706V2.01398H13.3259C10.7863 2.01398 8.73885 4.07117 8.73885 6.61081V8.74659H5.74548V11.8386H8.73885V19.6415Z"
      />
    </SvgIcon>
  );
};
