import { SvgIcon, SvgIconProps } from "@mui/material";
export const Person = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0041 13.0457C13.4359 12.9912 14.8218 13.2316 16.1201 13.8379C18.4991 14.9489 20.2153 16.6914 21.1088 19.1834C21.4463 20.1252 21.559 21.1053 21.5318 22.1022C21.5292 22.204 21.3452 22.3892 21.2422 22.3918C21.123 22.3944 21.0038 22.3931 20.8833 22.3925C20.7226 22.3918 20.5496 22.224 20.5451 22.0653C20.512 20.968 20.2704 19.9186 19.813 18.9197C18.9364 17.0088 17.502 15.6659 15.5888 14.8128C14.4615 14.3101 13.2785 14.0335 12.0436 14.0141C11.239 14.0011 10.4486 14.1385 9.6737 14.3581C8.22372 14.7681 6.93121 15.4677 5.85642 16.521C4.7965 17.5587 4.0935 18.8161 3.71254 20.249C3.55381 20.8462 3.47481 21.4558 3.45472 22.0731C3.45084 22.2163 3.27393 22.3912 3.13339 22.3925C3.0407 22.3931 2.94809 22.3847 2.85737 22.3944C2.73495 22.408 2.64938 22.3614 2.5665 22.2739C2.4939 22.1988 2.46669 22.1262 2.46408 22.0239C2.43363 20.6305 2.72388 19.3103 3.3595 18.0672C4.00153 16.8086 4.92605 15.7819 6.04038 14.9255C7.51431 13.7938 9.19427 13.183 11.0478 13.0489C11.3646 13.0256 11.6847 13.0457 12.0041 13.0457Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0023 3.52604C9.72114 3.52604 7.865 5.37443 7.865 7.64517C7.865 9.91662 9.72114 11.765 12.0023 11.765C14.2834 11.765 16.1396 9.91662 16.1396 7.64517C16.1396 5.37443 14.2834 3.52604 12.0023 3.52604ZM12.0023 12.9629C9.0603 12.9629 6.66709 10.5774 6.66709 7.64517C6.66709 4.71359 9.0603 2.32812 12.0023 2.32812C14.9443 2.32812 17.3375 4.71359 17.3375 7.64517C17.3375 10.5774 14.9443 12.9629 12.0023 12.9629Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
