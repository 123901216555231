import { SvgIcon, SvgIconProps } from "@mui/material";
export const Calendar = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9783 20.6359C14.1348 20.6359 16.2905 20.6424 18.447 20.633C19.6272 20.6287 20.2603 20.0412 20.2668 18.9C20.2841 16.0924 20.2784 13.2847 20.2697 10.477C20.2668 9.68497 19.9084 9.35254 19.117 9.35254C14.3697 9.35109 9.62302 9.35109 4.87639 9.35254C4.08718 9.35254 3.73015 9.68643 3.72733 10.4807C3.7186 13.2883 3.72221 16.096 3.72653 18.9036C3.72733 19.3943 3.85084 19.8467 4.22745 20.2001C4.61552 20.5644 5.09393 20.6359 5.59616 20.6359H11.9783ZM21.7743 6.7559V19.5302C21.5619 20.323 21.2366 21.063 20.5212 21.5183C20.1064 21.7814 19.6207 21.9346 19.1676 22.137H4.82936C4.03592 21.9252 3.29655 21.5993 2.84125 20.8838C2.57748 20.469 2.42503 19.9833 2.22266 19.5302V6.7559C2.24797 6.70677 2.28476 6.65979 2.29782 6.60776C2.74297 4.82055 4.39364 3.81238 6.24368 4.22867C6.24368 4.58062 6.23645 4.94123 6.24518 5.30113C6.25454 5.66176 6.25313 6.026 6.30297 6.38227C6.42295 7.22856 7.14273 7.8667 7.99766 7.89993C8.37347 7.9144 8.75 7.90571 9.12581 7.90066C9.61719 7.89488 10.0154 7.67663 10.3522 7.33551C10.7555 6.92719 10.8733 6.41625 10.8711 5.86412C10.8696 5.30693 10.8711 4.74972 10.8711 4.1882H13.5197C13.5197 4.83211 13.5111 5.45146 13.5212 6.0708C13.5378 7.10354 14.301 7.87394 15.3287 7.90428C15.6033 7.91295 15.8786 7.9086 16.1533 7.90644C17.3666 7.89632 18.1132 7.14473 18.1226 5.91831C18.1276 5.34451 18.1233 4.77069 18.1233 4.18313C19.1156 4.0986 19.9727 4.29155 20.7134 4.9174C21.2916 5.40448 21.5669 6.05851 21.7743 6.7559Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.25942 2.58813C9.76272 2.82579 9.95439 3.22017 9.93126 3.7742C9.9016 4.49582 9.92835 5.22031 9.92261 5.94337C9.91758 6.6368 9.57831 6.97052 8.8783 6.99146C7.6915 7.02686 7.09117 7.01313 7.18519 5.36911C7.21556 4.85048 7.21556 4.32607 7.18308 3.80744C7.14688 3.23752 7.34508 2.82941 7.86789 2.58813H9.25942Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.4729 2.58814C16.9937 2.79844 17.2054 3.1742 17.1801 3.74221C17.1461 4.49304 17.175 5.24603 17.1699 5.99832C17.1663 6.60172 16.8536 6.93775 16.2577 6.99051C16.2433 6.99196 16.2288 6.99124 16.2143 6.99196C14.7207 7.08734 14.4686 6.85247 14.4686 5.36166C14.4686 4.84063 14.5025 4.31743 14.4599 3.80075C14.4137 3.24503 14.6102 2.85552 15.0833 2.58814L16.4729 2.58814Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0404 10.8955C17.3004 10.8955 17.5613 10.8861 17.8213 10.8977C18.3428 10.9208 18.6846 11.2514 18.7033 11.7767C18.7221 12.2831 18.72 12.7909 18.7049 13.2974C18.6882 13.8588 18.3299 14.2132 17.7707 14.2277C17.2795 14.2408 16.7868 14.2415 16.2956 14.2277C15.7386 14.2118 15.3839 13.8537 15.368 13.2901C15.3543 12.8127 15.3579 12.3345 15.3673 11.857C15.3796 11.2312 15.7141 10.9078 16.3462 10.8948C16.5773 10.8904 16.8092 10.8941 17.0404 10.8941V10.8955Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.90445 10.895C7.15046 10.895 7.39709 10.8878 7.64302 10.8972C8.19138 10.9174 8.53999 11.2502 8.55668 11.7911C8.57258 12.2965 8.57258 12.8033 8.55668 13.3088C8.53999 13.838 8.17619 14.2041 7.64734 14.2207C7.14102 14.2366 6.63389 14.2359 6.12757 14.2207C5.58795 14.2055 5.23995 13.859 5.22043 13.3138C5.20312 12.8373 5.20816 12.36 5.21761 11.8827C5.22988 11.2178 5.546 10.9066 6.20933 10.895C6.44007 10.8914 6.67301 10.8943 6.90445 10.895Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.85885 19.2839C6.61272 19.2839 6.36597 19.2882 6.11992 19.2824C5.58649 19.2701 5.24344 18.9616 5.2203 18.4312C5.19715 17.8971 5.19715 17.3602 5.21959 16.8262C5.24203 16.3059 5.59665 15.9807 6.12425 15.9699C6.63082 15.959 7.13819 15.9583 7.64477 15.9706C8.18183 15.9829 8.53786 16.3341 8.55526 16.8739C8.57116 17.3653 8.56684 17.8574 8.55667 18.3488C8.54439 18.9348 8.18907 19.2752 7.59777 19.2839C7.35093 19.2868 7.1049 19.2839 6.85885 19.2839Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0233 19.2847C16.7778 19.2847 16.5315 19.289 16.2859 19.284C15.7588 19.2724 15.3984 18.9558 15.3753 18.4405C15.3515 17.9057 15.3522 17.3694 15.3746 16.8345C15.3962 16.3178 15.7537 15.9831 16.2737 15.9709C16.7792 15.9586 17.2855 15.96 17.791 15.9701C18.3145 15.981 18.6778 16.3077 18.7023 16.8222C18.7276 17.3564 18.7255 17.8934 18.7031 18.4275C18.6814 18.9471 18.324 19.2702 17.804 19.284C17.5439 19.2905 17.2833 19.2847 17.0233 19.2847Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9467 19.2833C11.6725 19.2833 11.3976 19.2935 11.1241 19.2812C10.6731 19.2609 10.3275 18.9369 10.3102 18.4849C10.2892 17.9215 10.2907 17.3566 10.3095 16.7932C10.3246 16.3296 10.6789 15.9861 11.1516 15.9709C11.6855 15.9535 12.2202 15.9535 12.7534 15.9702C13.2686 15.9853 13.602 16.3412 13.6099 16.8634C13.6186 17.3696 13.6179 17.8759 13.6106 18.3822C13.602 18.9427 13.2795 19.2689 12.726 19.2833C12.4662 19.2906 12.2064 19.2848 11.9467 19.2848V19.2833Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6179 12.5783C13.6179 12.8238 13.623 13.07 13.6172 13.3155C13.6049 13.8289 13.2697 14.2007 12.7621 14.2209C12.2271 14.2426 11.6898 14.2426 11.1548 14.2202C10.6625 14.1999 10.3157 13.8404 10.3019 13.3538C10.2874 12.8195 10.2881 12.2845 10.3019 11.7502C10.3142 11.268 10.6349 10.9264 11.1157 10.907C11.6797 10.8846 12.2459 10.8846 12.8099 10.9077C13.2914 10.9272 13.5999 11.2694 13.6165 11.7553C13.6252 12.0296 13.6179 12.304 13.6179 12.5783Z"
          stroke="#606A78"
          strokeWidth="0.5625"
        />
      </svg>
    </SvgIcon>
  );
};
