import Axios, { AxiosError } from "axios";
import { Navigate } from "navigation/RootNavigator";

export const PublicApi = Axios.create({
  baseURL: process.env.REACT_APP_URL_API
});
PublicApi.interceptors.response.use(
  r => r,
  (e: AxiosError) => {
    const code = e.response?.status ?? 200;
    if (code >= 500) Navigate("/500");
    else if (code >= 400) Navigate("/400");
    else if (code === 204) Navigate("/204");
    return Promise.reject(e);
  }
);
const space = process.env.REACT_APP_CONTENTFUL_SPACE;
const environment = process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;
const contentfulKey = process.env.REACT_APP_CONTENTFUL_KEY;

export const PrivateApi = Axios.create({
  baseURL: `https://graphql.contentful.com/content/v1/spaces/${space}/environments/${environment}`,
  headers: {
    Authorization: `Bearer ${contentfulKey}`
  }
});
PrivateApi.interceptors.response.use(
  r => r,
  (e: AxiosError) => {
    const code = e.response?.status ?? 200;
    if (code >= 500) Navigate("/500");
    else if (code >= 400) Navigate("/400");
    return Promise.reject(e);
  }
);
