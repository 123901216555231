/* eslint-disable max-lines */
import { createTheme } from "@mui/material/styles";
import type { PaletteColorOptions } from "@mui/material/styles";
import type { ColorPartial, SimplePaletteColorOptions } from "@mui/material/styles/createPalette";
declare module "@mui/material/styles" {
  interface Theme {
    palette: Palette;
    opacity?: {
      light: {
        "05": string;
        "10": string;
        "20": string;
        "40": string;
        "80": string;
      };
      dark: {
        "05": string;
        "10": string;
        "20": string;
        "40": string;
        "80": string;
      };
    };
    spacer?: Record<SpacerKeys, string>;
    weights?: Record<FontWeightsKeys, FontWeights>;
  }
  type SpacerKeys =
    | "xxxxx-large"
    | "xxxx-large"
    | "xxx-large"
    | "xx-large"
    | "x-large"
    | "large"
    | "medium"
    | "small";
  type FontWeights = "500" | "400" | "300";
  type FontWeightsKeys = "Medium" | "Regular" | "Small";
  // allow configuration using `createTheme`
  interface ThemeOptions {
    palette?: PaletteOptions;
    opacity?: {
      light: {
        "05": string;
        "10": string;
        "20": string;
        "40": string;
        "80": string;
      };
      dark: {
        "05": string;
        "10": string;
        "20": string;
        "40": string;
        "80": string;
      };
    };
    spacer?: Record<SpacerKeys, string>;
    weights?: Record<FontWeightsKeys, FontWeights>;
  }
  interface Palette {
    neutral: ColorPartial & SimplePaletteColorOptions;
  }
  interface PaletteOptions {
    neutral?: PaletteColorOptions;
  }
}
export const Theme = createTheme({
  spacing: (v: number) => 4 * v,
  spacer: {
    "xxxxx-large": "3.8rem",
    "xxxx-large": "3rem",
    "xxx-large": "2.5rem",
    "xx-large": "2rem",
    "x-large": "1.5rem",
    large: "1.25rem",
    medium: "1rem",
    small: "0.875rem"
  },
  weights: {
    Medium: "500",
    Regular: "400",
    Small: "300"
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          //? Montserrat/Desktop/Heading 1
          fontSize: "3.125rem",
          lineHeight: "3.75rem",
          fontWeight: "500",
          fontFamily: ["Zilla Slab", "sans-serif"].join(","),
          color: "common.white"
        },
        h2: {
          //? /* Montserrat/Desktop/38 px medium */
          fontSize: "2.375rem",
          lineHeight: "2.875rem",
          fontWeight: "500",
          fontFamily: ["Zilla Slab", "sans-serif"].join(",")
        },
        h3: {
          //? Montserrat/Desktop/Subtitle 3
          fontSize: "1.25rem",
          lineHeight: "1.8rem",
          fontWeight: "500",
          fontFamily: ["Zilla Slab", "sans-serif"].join(","),
          color: "#292929"
        },
        h4: {
          //? Montserrat / Desktop / Subtitle 18px
          fontSize: "1.125rem",
          lineHeight: "2.25rem",
          fontWeight: "500",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          color: "common.secondary"
        },
        subtitle1: {
          //? Montserrat/Desktop/Subtitle 1
          fontSize: "1.625rem",
          lineHeight: "1.938rem",
          fontWeight: "500",
          fontFamily: ["Zilla Slab", "sans-serif"].join(","),
          color: "#292929"
        },
        subtitle2: {
          //? Montserrat/Desktop/Subtitle 2
          fontSize: "1.375rem",
          lineHeight: "1.676rem",
          fontWeight: "400",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          color: "#292929"
        },
        body1: {
          //? Montserrat/Desktop/Body 1 Regular
          //? Montserrat / Desktop / 15 px Regular
          fontSize: "0.938rem",
          lineHeight: "1.875rem",
          fontWeight: "400",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          color: "#292929"
        },
        body2: {
          //? Montserrat/Desktop/Body 1 compressed
          fontSize: "0.938rem",
          lineHeight: "1.875rem",
          fontWeight: "300",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          color: "secondary"
        },
        paragraph: {
          //? Montserrat/Desktop/14 px light
          fontSize: "0.875rem",
          lineHeight: "1.067rem",
          fontWeight: "300",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          color: "secondary"
        },
        caption: {
          //? Montserrat/Desktop/13 px Regular
          fontSize: "0.813rem",
          lineHeight: "1.625rem",
          fontWeight: "300",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          color: "#292929"
        },
        button: {
          fontSize: "1rem",
          lineHeight: "2.665rem",
          fontWeight: "300",
          fontFamily: ["Montserrat", "sans-serif"].join(","),
          color: "primary",
          textTransform: "none"
        }
      }
    }
  },
  opacity: {
    light: {
      "05": "#0000001A",
      "10": "#FFFFFF1A",
      "20": "#FFFFFF33",
      "40": "#FFFFFF66",
      "80": "#FFFFFFF2"
    },
    dark: {
      "05": "#0000001A",
      "10": "#0000001A",
      "20": "#00000033",
      "40": "#00000066",
      "80": "#000000CC"
    }
  },
  palette: {
    primary: {
      "100": "#FFE8E6",
      "200": "#FFB8AC",
      "300": "#FF8F73",
      "400": "#FF6C45",
      "500": "#EB5027",
      "600": "#CC350C",
      "700": "#991F00",
      "800": "#660F00",
      "900": "#330500",
      main: "#EB5027"
    },
    secondary: {
      "100": "#EEF0F3",
      "200": "#C8CED6",
      "300": "#A4ADB8",
      "400": "#828B99",
      "500": "#606A78",
      "600": "#4A5462",
      "700": "#363F4C",
      "800": "#232A34",
      "900": "#11151A",
      main: "#606A78"
    },
    neutral: {
      "100": "#FFFFFF",
      "200": "#F2F2F2",
      "300": "#E6E6E6",
      "400": "#D9D9D9",
      "500": "#CDCDCD",
      "600": "#A4A4A4",
      "700": "#7B7B7B",
      "800": "#525252",
      "900": "#292929",
      main: "#CDCDCD"
    },
    info: {
      "100": "#E6EBFF",
      "200": "#ACC2FF",
      "300": "#739EFF",
      "400": "#447EFF",
      "500": "#2864F8",
      "600": "#2153CD",
      "700": "#1A41A1",
      "800": "#133076",
      "900": "#0C1E4A",
      main: "#2864F8"
    },
    success: {
      "100": "#CCF2DC",
      "200": "#99E4B9",
      "300": "#66D795",
      "400": "#33C972",
      "500": "#00BC4F",
      "600": "#00A93C",
      "700": "#008D2B",
      "800": "#00661B",
      "900": "#00330D",
      main: "#00BC4F"
    },
    warning: {
      "100": "#FFF7CE",
      "200": "#FFEF9E",
      "300": "#FFE86D",
      "400": "#FFE03D",
      "500": "#FFD80C",
      "600": "#CCAD0A",
      "700": "#998207",
      "800": "#665605",
      "900": "#332B02",
      main: "#FFD80C"
    },
    error: {
      "100": "#F7CCD4",
      "200": "#EF99A9",
      "300": "#E8667D",
      "400": "#E03352",
      "500": "#D80027",
      "600": "#AD001F",
      "700": "#820017",
      "800": "#560010",
      "900": "#2B0008",
      main: "#D80027"
    },
    background: {
      default: "#E5E5E5",
      paper: "#FFFFFF"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 750,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  }
});
export type CustomTheme = typeof Theme;
