import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "../types/Common";

export const Info = (props: CustomSvgProps) => {
  const SvgSize = props.size || 24;
  return (
    <SvgIcon
      {...props}
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        d="M7.5 14.7153C3.87563 14.7153 0.9375 11.7772 0.9375 8.15283C0.9375 4.52846 3.87563 1.59033 7.5 1.59033C11.1244 1.59033 14.0625 4.52846 14.0625 8.15283C14.0625 11.7772 11.1244 14.7153 7.5 14.7153ZM7.5 15.6528C11.6421 15.6528 15 12.295 15 8.15283C15 4.0107 11.6421 0.652832 7.5 0.652832C3.35786 0.652832 0 4.0107 0 8.15283C0 12.295 3.35786 15.6528 7.5 15.6528Z"
        fill={props.htmlColor || "#7B7B7B"}
      />
      <path
        d="M8.37253 6.82898L6.22471 7.09814L6.1478 7.4552L6.57078 7.5321C6.84544 7.59802 6.90037 7.6969 6.83994 7.97156L6.1478 11.2235C5.96653 12.064 6.24668 12.4595 6.90586 12.4595C7.41672 12.4595 8.00999 12.2233 8.27915 11.8992L8.36155 11.5092C8.17478 11.674 7.90012 11.7399 7.71885 11.7399C7.46067 11.7399 7.36729 11.5586 7.4332 11.24L8.37253 6.82898Z"
        fill={props.htmlColor || "#7B7B7B"}
      />
      <path
        d="M8.4375 4.87158C8.4375 5.38935 8.01777 5.80908 7.5 5.80908C6.98223 5.80908 6.5625 5.38935 6.5625 4.87158C6.5625 4.35382 6.98223 3.93408 7.5 3.93408C8.01777 3.93408 8.4375 4.35382 8.4375 4.87158Z"
        fill={props.htmlColor || "#7B7B7B"}
      />
    </SvgIcon>
  );
};
