import { SvgIcon, SvgIconProps } from "@mui/material";
export const Feeding = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.3979 16.2409C15.3979 16.2409 15.3979 16.2781 15.3979 16.2409L15.3608 16.2781C14.4327 17.6888 12.8364 18.5798 11.1658 18.5798H7.89883C5.11451 18.5798 2.88706 16.3152 2.88706 13.568V13.4938H16.2147C16.2147 14.4961 15.9177 15.4242 15.3979 16.2409ZM2.99843 11.489H16.1775V12.677H2.92418L2.99843 11.489ZM4.96602 9.85558C5.07739 9.85558 5.15164 9.85558 5.26301 9.89271C5.44863 9.92983 5.59713 9.85558 5.7085 9.70709C6.00549 9.2616 6.56236 8.9646 7.0821 9.00173C7.26772 9.00173 7.41622 9.03885 7.56471 9.07598C7.75033 9.15022 7.97308 9.03885 8.04733 8.85323C8.45569 7.92512 9.42092 7.36826 10.4975 7.36826C11.8711 7.36826 12.9848 8.33349 13.1333 9.59571C13.1705 9.81846 13.3561 10.0041 13.616 9.96696C13.6531 9.96696 13.6531 9.96696 13.6902 9.96696C13.8016 9.92983 13.9501 9.92983 14.0615 9.92983C14.6183 9.92983 15.0638 10.264 15.2494 10.7094H3.81517C3.96366 10.1897 4.44628 9.85558 4.96602 9.85558ZM17.0314 13.0854L16.9943 11.0436C16.9943 10.8208 16.8086 10.6352 16.5859 10.6352H16.1404C15.9548 9.74421 15.1009 9.03885 14.0986 9.03885C14.0615 9.03885 13.9872 9.03885 13.9501 9.03885C13.5788 7.55388 12.1681 6.5144 10.5718 6.5144C9.30955 6.5144 8.1587 7.18264 7.52759 8.18499C7.41622 8.14787 7.26772 8.14787 7.15635 8.14787C6.41386 8.14787 5.7085 8.44486 5.22589 9.03885C5.11451 9.03885 5.04027 9.03885 4.96602 9.03885C3.96366 9.03885 3.10981 9.74421 2.92418 10.6723H2.55294C2.3302 10.6723 2.14458 10.8579 2.14458 11.0807L2.0332 13.0854V13.2339C2.0332 13.3453 2.0332 13.4566 2.0332 13.568C2.0332 16.7978 4.66902 19.4336 7.89883 19.4336H11.1658C13.1705 19.4336 14.9896 18.4313 16.0662 16.7607V16.7236C16.6973 15.7583 17.0314 14.6075 16.9943 13.4566L17.0314 13.0854Z"
          fill="#606A78"
        />
        <path
          d="M20.0021 11.7489C19.7793 11.8232 19.5194 11.8232 19.2967 11.7489C18.6285 11.4891 18.1459 10.561 18.1459 9.5215C18.1459 8.2964 18.8512 7.25692 19.6308 7.25692C20.4476 7.25692 21.1158 8.2964 21.1158 9.5215C21.1158 10.5981 20.6332 11.5262 20.0021 11.7489ZM19.8907 18.5427C19.8164 18.6169 19.7051 18.6541 19.6308 18.6541C19.4081 18.6541 19.2596 18.5056 19.2596 18.2828V18.2457L19.5194 12.6771C19.5566 12.6771 19.5937 12.6771 19.6308 12.6771C19.6679 12.6771 19.7051 12.6771 19.7422 12.6771L20.0021 18.2828C20.0021 18.3571 19.9649 18.4684 19.8907 18.5427ZM19.6308 6.44019C18.9626 6.44019 18.3686 6.7743 17.9231 7.40542C17.5147 7.9994 17.292 8.74189 17.292 9.55862C17.292 10.8208 17.8489 11.9346 18.7027 12.4172L18.4057 18.2086C18.4057 18.2086 18.4057 18.2086 18.4057 18.2457V18.2828C18.4057 18.951 18.9626 19.5079 19.6308 19.5079C19.9649 19.5079 20.2619 19.3594 20.4847 19.1367C20.7074 18.9139 20.8188 18.5798 20.8188 18.2457L20.5589 12.4543C21.4128 11.9717 21.9696 10.858 21.9696 9.59574C21.9696 8.77901 21.7469 8.03653 21.3385 7.44254C20.893 6.7743 20.2991 6.44019 19.6308 6.44019Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
