import { makeStyles } from "@mui/styles";
import { Facebook, Instagram, Youtube, Call, Mail, BottomUp, TikTok } from "../../icons";
import { CustomTheme } from "../../helpers/Theme";
import { Box, Grid, IconButton, Link, Typography, Container } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SocialMediaUrl, sharedData } from "helpers/Consts";
const getStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    margin: "auto",
    position: "relative",
    backgroundColor: "#79797980",
    [theme.breakpoints.only("xs")]: {
      width: "75%",
      padding: 20,
      height: 495
    },
    [theme.breakpoints.only("sm")]: {
      width: "89%",
      padding: 20,
      height: 241
    },
    [theme.breakpoints.only("md")]: {
      width: "80%",
      padding: 40,
      height: 220
    },
    [theme.breakpoints.only("lg")]: {
      width: "77%",
      padding: 40,
      height: 220
    },
    [theme.breakpoints.up("xl")]: {
      width: "85vw",
      maxWidth: "85vw",
      padding: 40,
      height: 220
    }
  },
  title: {
    color: theme.palette.common.white,
    fontSize: "16px",
    marginBottom: "14px",
    lineHeight: "20px",
    fontWeight: theme.weights?.Medium,
    fontFamily: "Montserrat"
  },
  link: {
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    color: theme.palette.common.white,
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "300",
    marginBottom: "12px",
    fontFamily: ["Montserrat", "sans-serif"].join(",")
  },
  linkLast: {
    [theme.breakpoints.only("sm")]: {
      marginBottom: "0px"
    }
  },
  item: {
    color: theme.palette.common.white
  },
  section: {
    [theme.breakpoints.only("xs")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    }
  },
  bottomUp: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 5,
    padding: 10,
    position: "absolute",
    right: 28,
    top: -22,
    boxShadow: "0px 10px 15px rgba(235, 80, 39, 0.25)",
    "&:hover": {
      background: theme.palette.common.white,
      border: "1px solid rgb(238, 97, 6)",
      margin: -1
    }
  }
}));
export const Footer = ({ hideScrollToTop }: { hideScrollToTop?: boolean }) => {
  const styles = getStyles();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  return (
    <Container component="footer" className={styles.container}>
      {hideScrollToTop ? null : (
        <IconButton
          aria-label="Scroll to Top"
          onClick={scrollToTop}
          size="small"
          className={styles.bottomUp}
          disableRipple>
          <BottomUp size={20} />
        </IconButton>
      )}
      <Grid container component="footer">
        <Grid className={styles.section} item xs={12} sm={4} md={3}>
          <Typography className={styles.title}>Oficina Principal</Typography>
          <Typography paragraph color="common.white">
            {sharedData.address}
          </Typography>
          <Typography paragraph color="common.white">
            {sharedData.generalAddress}
          </Typography>
        </Grid>
        <Grid className={styles.section} item xs={12} sm={4} md={3}>
          <Typography className={styles.title}>Contáctenos</Typography>
          <Link href={`tel:${sharedData.tel}`} paragraph className={styles.link}>
            <Call height={15} sx={{ mr: 3 }} />
            {sharedData.tel}
          </Link>
          <Link href={`mailto:${sharedData.email}`} paragraph className={styles.link}>
            <Mail sx={{ mr: 3 }} />
            {sharedData.email}
          </Link>
        </Grid>
        <Grid className={styles.section} item xs={12} sm={4} md={3}>
          <Typography className={styles.title}>Enlaces de Interés</Typography>
          <Link component={RouterLink} to="/politicas-de-privacidad" className={styles.link}>
            Políticas de Privacidad
          </Link>
          <Link component={RouterLink} to="/consejos-recomendaciones" className={styles.link}>
            Consejos y Recomendaciones
          </Link>
          <Link component={RouterLink} to="/preguntas-frecuentes" className={styles.link}>
            Preguntas Frecuentes
          </Link>
          <Link
            component={RouterLink}
            to="/terminos-condiciones"
            className={`${styles.link} ${styles.linkLast}`}>
            Términos y Condiciones
          </Link>
        </Grid>
        <Grid className={styles.section} item xs={12} sm={4} md={3}>
          <Typography className={styles.title}>Síguenos</Typography>
          <Box display="flex" flexDirection="row" marginTop={-2}>
            <Link href={SocialMediaUrl.Facebook} target="_blank" aria-label="facebook">
              <IconButton aria-label="facebook button" size="small" sx={{ mr: 5 }}>
                <Facebook />
              </IconButton>
            </Link>
            <Link href={SocialMediaUrl.Instagram} target="_blank" aria-label="instagram">
              <IconButton aria-label="instagram button" size="small" sx={{ mr: 5 }}>
                <Instagram />
              </IconButton>
            </Link>
            <Link href={SocialMediaUrl.Tiktok} target="_blank" aria-label="tiktok">
              <IconButton aria-label="tiktok button" size="small" sx={{ mr: 5 }}>
                <TikTok />
              </IconButton>
            </Link>
            <Link href={SocialMediaUrl.Youtube} target="_blank" aria-label="youtube">
              <IconButton aria-label="youtube button" size="small">
                <Youtube />
              </IconButton>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};
