import { SvgIcon, SvgIconProps } from "@mui/material";

export const Call = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M3.80034 2.44H2.43966C2.52213 3.53953 2.75578 4.65281 3.14061 5.77984L4.17143 4.70779C3.95152 3.9656 3.82783 3.20967 3.80034 2.44ZM12.4592 12.9956L11.3871 14.0264C12.5417 14.4387 13.6549 14.6586 14.727 14.6861V13.4079C13.8474 13.2979 13.0914 13.1605 12.4592 12.9956ZM4.62499 0.666992C4.87239 0.666992 5.07855 0.763202 5.24348 0.955621C5.40841 1.12055 5.49088 1.31297 5.49088 1.53288C5.49088 2.54995 5.65581 3.59451 5.98567 4.66656C6.12311 4.99642 6.05439 5.29879 5.7795 5.57368L3.84157 7.51161C5.16101 10.068 7.09895 12.006 9.65537 13.3254L11.5933 11.3875C11.7582 11.2225 11.9644 11.1401 12.2118 11.1401C12.3217 11.1401 12.418 11.1538 12.5004 11.1813C13.4625 11.5112 14.5071 11.6761 15.6341 11.6761C15.854 11.6761 16.0464 11.7586 16.2114 11.9235C16.4038 12.0884 16.5 12.2946 16.5 12.542V15.6344C16.5 15.8543 16.4038 16.0605 16.2114 16.2529C16.0464 16.4179 15.854 16.5003 15.6341 16.5003C11.4834 16.5003 7.95109 15.0434 5.03732 12.1297C2.12354 9.21589 0.666656 5.68363 0.666656 1.53288C0.666656 1.31297 0.749122 1.12055 0.914052 0.955621C1.10647 0.763202 1.31263 0.666992 1.53254 0.666992H4.62499Z"
        fill="white"
      />
    </SvgIcon>
  );
};
