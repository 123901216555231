import { Box } from "@mui/material";
import { Photos } from "types/Tour";
import { ImagesItems } from "../ImagesItems/ImagesItems";

export const ImagesTourList = ({ images }: { images?: Photos[] }) => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center" mt={4}>
      {images?.map((m, i) => (
        <ImagesItems description={m.name} key={i} img={m.url} />
      ))}
    </Box>
  );
};
