import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";

export const ArrowFillLeft = (props: CustomSvgProps) => {
  const SvgSize = props.size || 14;
  return (
    <SvgIcon
      width={props.width || "13"}
      height={props.height || "16"}
      fill="none"
      viewBox="0 0 13 16"
      {...props}
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        fill={props.htmlColor || "#fff"}
        d="M.485 7.424L7.013.128c.128 0 .384-.128.64-.128h4.352c.256 0 .512.128.64.384s.128.512-.128.768L6.373 7.936l6.144 6.784c.128.256.256.512.128.768a.704.704 0 01-.64.384H7.653c-.256 0-.384-.128-.512-.256L.485 8.448c-.128-.256-.128-.64 0-1.024z"></path>
    </SvgIcon>
  );
};
