import React, { useEffect, useRef, useState } from "react";
import { Box, Portal, useTheme } from "@mui/material";
import { CustomTheme } from "helpers/Theme";
import Loader from "assets/loader.gif";
export const LoaderIndicator = ({ isLoading }: { isLoading: boolean }) => {
  const { palette } = useTheme<CustomTheme>();
  const [show, setShow] = useState(isLoading);
  const waitMinTime = useRef(true);
  const timer = useRef<NodeJS.Timeout>();
  useEffect(() => {
    return () => timer.current && clearTimeout(timer.current);
  }, []);
  useEffect(() => {
    if (isLoading) {
      timer.current && clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        waitMinTime.current = false;
      }, 1000);
    } else {
      if (waitMinTime.current === false) setShow(false);
      else {
        timer.current && clearTimeout(timer.current);
        setTimeout(() => {
          setShow(false);
        }, 500);
      }
    }
  }, [isLoading]);
  if (!show) return null;
  return (
    <Portal>
      <Box
        position="fixed"
        bgcolor={palette.background.default}
        width="100%"
        zIndex={2000}
        top={0}
        height="100%"
        display="flex"
        justifyContent="center"
        alignItems="center">
        <img src={Loader} alt="Loader" aria-label="Loader" width="200px" />
      </Box>
    </Portal>
  );
};
