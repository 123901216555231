import { SvgIcon, SvgIconProps } from "@mui/material";
export const ArrivalDate = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2339 8.3358C20.2339 7.59653 20.2552 6.87918 20.2273 6.16318C20.2033 5.56293 19.7082 5.11978 19.106 5.11575C18.2602 5.10978 17.4138 5.11441 16.568 5.11441C16.5394 5.11511 16.5101 5.12107 16.4622 5.12708V5.33268C16.4622 5.78586 16.4648 6.2397 16.4609 6.69353C16.4582 6.92577 16.2985 7.08546 16.0862 7.08481C15.874 7.08412 15.7162 6.92378 15.7122 6.69154C15.7076 6.40939 15.7103 6.12788 15.7103 5.84572V5.12639H8.29192V6.51319C8.29192 6.90911 8.17284 7.0881 7.91128 7.08481C7.65508 7.08149 7.53998 6.90712 7.53998 6.5225V5.12241C7.46346 5.11909 7.4096 5.11441 7.35566 5.11441C6.55246 5.11376 5.74925 5.11242 4.94677 5.11441C4.25071 5.11644 3.76828 5.60086 3.76698 6.29693C3.76495 6.91046 3.7656 7.52334 3.76625 8.13619C3.76698 8.20141 3.77226 8.2666 3.77559 8.33581L20.2339 8.3358ZM3.76626 9.10908V9.33665C3.76626 13.0152 3.76626 16.6937 3.76699 20.3716C3.76699 21.1056 4.24008 21.5774 4.97537 21.5781C9.66009 21.5787 14.3434 21.5787 19.0268 21.5781C19.7634 21.5774 20.2359 21.1049 20.2359 20.3716V9.10908L3.76626 9.10908ZM7.53998 4.36047V3.69905C7.54063 3.38628 7.536 3.07354 7.5433 2.76145C7.54794 2.5405 7.70698 2.38945 7.91664 2.38945C8.12556 2.39011 8.28591 2.5405 8.2886 2.76275C8.29526 3.2219 8.29128 3.68174 8.29192 4.14155V4.34982H15.7103V4.14687C15.7103 3.70571 15.7089 3.26385 15.7116 2.82265C15.7122 2.55516 15.854 2.39278 16.0809 2.38945C16.3158 2.38613 16.4602 2.54984 16.4615 2.82932C16.4642 3.26451 16.4622 3.70035 16.4622 4.13558V4.36046H16.6937C17.479 4.36046 18.2629 4.35981 19.0474 4.36046C20.174 4.3618 20.9865 5.16765 20.9865 6.29424C20.9885 10.9969 20.9885 15.6989 20.9872 20.4009C20.9865 21.5255 20.1727 22.3353 19.0488 22.3353C14.3514 22.336 9.65545 22.336 4.9601 22.3353C3.82482 22.3353 3.01568 21.5262 3.01568 20.3876C3.01365 15.7042 3.01365 11.0202 3.01568 6.33619C3.01633 5.14901 3.81288 4.36046 5.00534 4.36046L7.53998 4.36047Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.96094 12.5394C5.96094 12.8089 5.95761 13.0784 5.96159 13.3479C5.96557 13.5861 6.10065 13.7352 6.33289 13.7379C6.88387 13.7445 7.43487 13.7445 7.98585 13.7385C8.22141 13.7359 8.36178 13.5895 8.36381 13.3539C8.36779 12.8156 8.36779 12.2765 8.36381 11.7375C8.36178 11.4807 8.21743 11.3369 7.96456 11.3349C7.43218 11.3316 6.89915 11.3323 6.36684 11.3349C6.10333 11.3363 5.96426 11.4807 5.96158 11.7495C5.9589 12.013 5.96094 12.2759 5.96094 12.5394ZM7.16534 14.4985C6.88323 14.4978 6.60168 14.5018 6.31957 14.4971C5.71001 14.4878 5.21825 14.0167 5.20363 13.4052C5.18965 12.8296 5.19096 12.2533 5.20298 11.6777C5.21427 11.0941 5.65876 10.615 6.24175 10.5897C6.85261 10.5637 7.46744 10.5624 8.07829 10.589C8.67785 10.615 9.11845 11.1067 9.12372 11.7089C9.12909 12.2606 9.12973 12.8116 9.12372 13.3632C9.11706 14.0107 8.6253 14.4925 7.97382 14.4978C7.70438 14.5005 7.43486 14.4985 7.16534 14.4985Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8582 11.3343C16.5946 11.3343 16.3318 11.333 16.0683 11.3343C15.7788 11.3363 15.6397 11.4727 15.6384 11.7622C15.6351 12.2825 15.6357 12.8029 15.6377 13.324C15.639 13.5948 15.7748 13.7379 16.041 13.7399C16.5734 13.7445 17.1063 13.7445 17.6387 13.7399C17.9049 13.7372 18.04 13.5941 18.0406 13.3233C18.0433 12.7969 18.0433 12.2699 18.0406 11.7435C18.0393 11.4793 17.8949 11.3376 17.6294 11.3349C17.3725 11.3323 17.115 11.3343 16.8582 11.3343ZM16.8269 14.4985C16.5513 14.4985 16.2759 14.5018 15.9997 14.4978C15.3815 14.4885 14.8904 14.0093 14.8798 13.3898C14.8698 12.8202 14.8698 12.2499 14.8798 11.681C14.8898 11.0994 15.333 10.6156 15.9159 10.5903C16.5334 10.5637 17.1536 10.5637 17.7711 10.591C18.3434 10.6163 18.7859 11.0934 18.7992 11.669C18.8126 12.2446 18.8126 12.8215 18.7992 13.3971C18.7853 14.018 18.2935 14.4898 17.6727 14.4978C17.3912 14.5011 17.109 14.4985 16.8269 14.4985Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.36577 17.5748C8.36577 17.3179 8.3678 17.0604 8.36512 16.8035C8.36244 16.5134 8.22411 16.3743 7.93663 16.373C7.4222 16.371 6.90785 16.371 6.39415 16.373C6.10261 16.3743 5.9629 16.5107 5.9616 16.7982C5.95957 17.3186 5.95957 17.8389 5.9616 18.3593C5.9629 18.6308 6.09604 18.7759 6.3615 18.7792C6.89388 18.7859 7.42691 18.7852 7.95921 18.7806C8.22476 18.7779 8.36114 18.6322 8.36447 18.3647C8.3678 18.1011 8.36513 17.8383 8.36577 17.5748ZM7.16332 19.5365C6.88786 19.5365 6.61241 19.5385 6.33623 19.5365C5.714 19.5305 5.2189 19.0627 5.20363 18.4418C5.18966 17.8662 5.19031 17.29 5.20363 16.7143C5.21695 16.1341 5.64617 15.6596 6.2251 15.631C6.85455 15.5991 7.48807 15.5997 8.11696 15.633C8.68923 15.6636 9.11641 16.1547 9.12373 16.7303C9.13039 17.2946 9.13168 17.8582 9.12373 18.4219C9.11438 19.0487 8.61937 19.5298 7.99113 19.5365C7.71502 19.5391 7.4395 19.5365 7.16332 19.5365Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.6378 17.5768C15.6378 17.8403 15.6351 18.1038 15.6384 18.3667C15.6417 18.6335 15.7795 18.7786 16.0456 18.7812C16.572 18.7859 17.0984 18.7852 17.6254 18.7806C17.9062 18.7772 18.04 18.6382 18.0413 18.358C18.044 17.8376 18.0433 17.3172 18.0413 16.7969C18.04 16.5174 17.9009 16.375 17.626 16.3737C17.1057 16.371 16.5853 16.371 16.0649 16.3737C15.7781 16.3743 15.6404 16.5147 15.6378 16.8055C15.6351 17.0624 15.637 17.3199 15.6378 17.5768ZM16.8402 19.5371C16.5647 19.5371 16.2892 19.5391 16.013 19.5365C15.3849 19.5305 14.8891 19.05 14.8798 18.4232C14.8711 17.8596 14.8725 17.296 14.8798 16.7317C14.8864 16.1561 15.313 15.6643 15.8853 15.6337C16.5141 15.5997 17.1476 15.5997 17.7765 15.631C18.356 15.6603 18.7859 16.1341 18.7992 16.7137C18.8126 17.2893 18.8132 17.8656 18.7992 18.4412C18.7839 19.062 18.2895 19.5305 17.6673 19.5365C17.3918 19.5391 17.1163 19.5371 16.8402 19.5371Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0024 11.3343C11.7389 11.3343 11.4754 11.3323 11.2126 11.3349C10.9497 11.3376 10.798 11.4807 10.7953 11.7422C10.7906 12.2745 10.7906 12.8076 10.7953 13.3406C10.798 13.5914 10.9477 13.7372 11.2013 13.7392C11.7336 13.7438 12.2666 13.7438 12.7996 13.7392C13.0618 13.7372 13.2056 13.5901 13.2075 13.3246C13.2116 12.7976 13.2122 12.2712 13.2075 11.7442C13.2049 11.4827 13.0532 11.3376 12.7923 11.3349C12.5295 11.3323 12.266 11.3343 12.0024 11.3343ZM13.9582 12.5354C13.9582 12.8176 13.9602 13.0997 13.9575 13.3812C13.9515 13.9914 13.4764 14.4845 12.8675 14.4951C12.2919 14.5058 11.7156 14.5051 11.14 14.4951C10.5298 14.4851 10.05 13.9941 10.0454 13.3859C10.0414 12.8229 10.0414 12.2592 10.046 11.6956C10.05 11.1027 10.4852 10.6163 11.0802 10.5903C11.6917 10.5631 12.3058 10.5624 12.9174 10.5897C13.5216 10.6156 13.9521 11.1027 13.9575 11.7089C13.9595 11.9844 13.9575 12.2599 13.9582 12.5354Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9831 18.7812C12.2526 18.7812 12.5222 18.7859 12.7916 18.7799C13.0678 18.7732 13.2062 18.6308 13.2075 18.3527C13.2108 17.8316 13.2116 17.3113 13.2075 16.7909C13.2049 16.5181 13.0558 16.3743 12.7817 16.373C12.2613 16.371 11.7402 16.371 11.2199 16.3737C10.9464 16.3743 10.7973 16.5187 10.7946 16.7916C10.7907 17.3119 10.7913 17.833 10.7946 18.3533C10.7967 18.6308 10.9351 18.7732 11.2119 18.7799C11.4688 18.7859 11.7263 18.7812 11.9831 18.7812ZM13.9575 17.5868C13.9575 17.8682 13.9608 18.1504 13.9568 18.4318C13.9482 19.0388 13.463 19.5278 12.8555 19.5352C12.2852 19.5418 11.7156 19.5418 11.146 19.5352C10.5384 19.5278 10.0514 19.0374 10.046 18.4312C10.0407 17.8609 10.0414 17.2913 10.046 16.7217C10.05 16.1567 10.4739 15.663 11.0375 15.6343C11.679 15.6017 12.3245 15.6011 12.9653 15.6343C13.5303 15.6643 13.9502 16.1574 13.9568 16.723C13.9602 17.0105 13.9575 17.2986 13.9575 17.5868Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
