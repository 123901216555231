import { SvgIcon, SvgIconProps } from "@mui/material";
export const Hand = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8025 20.3901C9.84252 20.3107 8.40698 19.4724 7.41462 17.8402C6.14326 15.7498 5.07806 13.5501 3.93596 11.3913C3.75214 11.0441 3.81785 10.6956 4.08729 10.4749C4.32974 10.2764 4.68561 10.2598 4.93352 10.4567C5.03305 10.5358 5.11488 10.6473 5.18002 10.7586C5.61638 11.5042 6.04642 12.2533 6.47812 13.0014C6.6434 13.288 6.85309 13.3856 7.07563 13.2779C7.30906 13.165 7.37617 12.9155 7.24391 12.6179C6.60602 11.1821 5.96542 9.74732 5.32756 8.31147C5.11321 7.82876 5.24414 7.40605 5.66545 7.19715C6.07997 6.99179 6.50102 7.15679 6.75082 7.61877C7.49861 9.0009 8.24745 10.3819 8.99603 11.7632C9.15476 12.0559 9.35629 12.1579 9.58672 12.0649C9.82917 11.9672 9.89788 11.7712 9.79315 11.4414C9.62108 10.9009 9.44411 10.362 9.26738 9.82287C8.83215 8.49473 8.39499 7.16715 7.96057 5.83847C7.81113 5.38194 7.99822 4.96441 8.4168 4.80815C8.83215 4.65297 9.25293 4.84414 9.43672 5.29304C10.1441 7.02097 10.8447 8.75136 11.548 10.4809C11.5696 10.5344 11.5903 10.5881 11.6132 10.641C11.7152 10.8753 11.9252 11.025 12.0962 10.9866C12.3051 10.9394 12.4308 10.7234 12.397 10.4343C12.3504 10.0342 12.2912 9.63524 12.2363 9.23598C12.0574 7.93212 11.8758 6.6288 11.6999 5.32467C11.6334 4.83214 11.9487 4.43016 12.4537 4.34616C12.8791 4.27553 13.2484 4.55124 13.3504 5.03422C13.7243 6.80388 14.1014 8.57328 14.4586 10.3465C14.6013 11.0553 14.6964 11.7736 14.8113 12.4876C14.8372 12.6471 14.8628 12.8026 15.0611 12.8367C15.2582 12.8705 15.3368 12.739 15.4126 12.5928C15.9165 11.6217 16.4213 10.6508 16.9308 9.68268C16.9989 9.55369 17.0802 9.42333 17.1833 9.32215C17.4628 9.04807 17.9136 8.99407 18.2356 9.17652C18.5373 9.34697 18.7295 9.84959 18.624 10.2442C18.3715 11.1894 18.099 12.1292 17.8533 13.0761C17.5743 14.1498 17.2989 15.2251 17.0521 16.3064C16.5479 18.5157 14.9896 19.966 12.748 20.2777C12.4142 20.3241 12.079 20.3574 11.8025 20.3901Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0081 9.81516C21.9841 10.4213 21.8466 10.9942 21.6203 11.5474C21.5235 11.7831 21.3705 11.8635 21.1831 11.7885C20.998 11.714 20.9478 11.5528 21.0348 11.3114C21.7089 9.44281 21.133 7.75506 19.4485 6.67428C19.2985 6.57798 19.1815 6.47542 19.2292 6.27901C19.2808 6.06596 19.503 5.98385 19.7261 6.10934C20.818 6.72229 21.5663 7.61185 21.8663 8.84212C21.9437 9.1591 21.9626 9.49054 22.0081 9.81516Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.69941 13.4724C2.69448 13.9625 2.63173 14.4602 2.69611 14.941C2.8822 16.3359 3.67758 17.2923 4.95261 17.8614C5.28822 18.0114 5.38947 18.1429 5.30404 18.3474C5.22137 18.5451 5.03528 18.5754 4.70597 18.4456C2.66774 17.6416 1.60254 15.5734 2.12669 13.4397C2.17469 13.2442 2.26585 13.0969 2.49394 13.1253C2.69039 13.1498 2.77335 13.2791 2.76243 13.4719C2.74141 13.4722 2.72039 13.4724 2.69941 13.4724Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.31725 14.2601C4.24716 15.0975 4.68279 15.8778 5.43701 16.2665C5.47327 16.2853 5.51173 16.3001 5.54829 16.3189C5.75287 16.4247 5.82406 16.5854 5.74388 16.758C5.6623 16.9329 5.49645 16.9874 5.28234 16.8903C4.41301 16.4959 3.86501 15.8364 3.71117 14.8888C3.66315 14.5945 3.69699 14.2852 3.70924 13.9832C3.71743 13.7789 3.82601 13.6365 4.04558 13.6344C4.23654 13.6327 4.34918 13.801 4.335 14.0389C4.3309 14.1128 4.32328 14.1864 4.31725 14.2601Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3085 10.0103C20.2703 10.1879 20.2174 10.4944 20.1358 10.7937C20.0785 11.0048 19.8913 11.103 19.7216 11.0331C19.5312 10.9543 19.4758 10.8043 19.5339 10.6098C19.8389 9.58835 19.5538 8.76845 18.6905 8.14684C18.5279 8.02983 18.4654 7.88554 18.5762 7.70252C18.6795 7.5315 18.8471 7.50014 19.0457 7.6297C19.8559 8.15802 20.2717 8.90919 20.3085 10.0103Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
