export const DestinationImage =
  "https://inka-adventure-planet-front.s3.amazonaws.com/destino/destino.jpg";
export const Arrival = {
  description:
    "Serás recibido por nuestro equipo y llevado hasta tu hotel. Donde podrás descansar luego de tu viaje.",
  image: "https://inka-adventure-planet-front.s3.amazonaws.com/llegada/llegada.jpg"
};
export const Outlet = {
  description:
    "Serás despedido por nuestro equipo y acompañado hasta el Aeropuerto. Donde abordará el vuelo viajar hacia su próximo destino.",
  image: "https://inka-adventure-planet-front.s3.amazonaws.com/salida/salida.jpg"
};
export const AboutImages = {
  PersonOne: "https://inka-adventure-planet-front.s3.amazonaws.com/nosotros/persona1.png",
  PersonTwo: "https://inka-adventure-planet-front.s3.amazonaws.com/nosotros/persona2.png",
  Bg: "https://inka-adventure-planet-front.s3.amazonaws.com/nosotros/nosotros.jpg"
};

export const OutletDescription =
  "Una movilidad lo llevará del hotel seleccionado al aeropuerto, donde abordará el vuelo hacia su próximo destino.";
export const IncludeDescription =
  "Una movilidad y un representante lo recibirán en el Aeropuerto y luego será trasladado a su hotel seleccionado. A su llegada al hotel, será asistido en el check-in y acomodo.";
export const IncludeArrivalOptions = {
  SameDay: 1,
  NextDay: 2
};
export const IncludeOutletOptions = {
  SameDay: 1,
  NextDay: 2
};
import NotFoundImage from "assets/NotFound.png";
import ServerErrorImage from "assets/ServerError.png";
import ClientErrorImage from "assets/ClientError.png";

export const NotFoundError = {
  code: 404,
  title: "PÁGINA NO ENCONTRADA",
  image: NotFoundImage,
  description: "LO SENTIMOS, NO PODEMOS UBICAR LA PÁGINA QUE ESTÁ BUSCANDO."
};
export const sharedData = {
  address: "San Marcos A-3",
  generalAddress: "San Blas - Cusco, Perú",
  tel: "+51 992005739",
  email: "info@ipa.com.pe"
};
export const ServerError = {
  code: 500,
  title: "HUY! ALGO SALIÓ MAL",
  image: ServerErrorImage,
  description: "DISCULPE LAS MOLESTIAS, ESTAMOS TRABAJANDO EN ELLO."
};
export const ClientError = {
  code: 400,
  title: "SOLICITUD INCORRECTA",
  image: ClientErrorImage,
  description: "VAYA, NO SE PUDO ENCONTRAR LA PÁGINA."
};
export const ContentError = {
  code: 204,
  title: "SIN CONTENIDO",
  image: ServerErrorImage,
  description: "VAYA, NO SE PUDO ENCONTRAR LA PÁGINA."
};
export const SocialMediaUrl = {
  Facebook: "https://www.facebook.com/InkaPlanetAdventure",
  Instagram: "https://www.instagram.com/inkaplanetadventure/",
  Youtube: "https://www.youtube.com/@inkaplanetadventure1775",
  Tiktok: "https://www.tiktok.com/@inkaplanetadventure/"
};
export const homeImagesQuery = {
  table: "https://ipa-videos.s3.amazonaws.com/backgrouds/bg-sm.webp",
  mobile: "https://ipa-videos.s3.amazonaws.com/backgrouds/bg-sm.webp",
  desktop: "https://ipa-videos.s3.amazonaws.com/backgrouds/lg.webp"
};
export const videoInkaUrl = process.env.REACT_APP_URL_VIDEO_INKA;
