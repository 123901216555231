import { SvgIcon, SvgIconProps } from "@mui/material";
export const Mail = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M17.3338 2.65366C17.3338 1.69288 16.5904 0.857422 15.6906 0.857422H2.30971C1.40982 0.857422 0.666443 1.69288 0.666443 2.65366V13.3475C0.666443 14.3083 1.40982 15.1438 2.30971 15.1438H15.6906C16.5904 15.1438 17.3338 14.3083 17.3338 13.3475V2.65366ZM15.6906 2.65366L9.00014 7.12336L2.30971 2.65366H15.6906ZM15.6906 13.3475H2.30971V4.44989L9.00014 8.87782L15.6906 4.44989V13.3475Z"
        fill="white"
      />
    </SvgIcon>
  );
};
