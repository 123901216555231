import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { CustomTheme } from "helpers/Theme";
const Line = styled("div", {
  skipSx: true,
  skipVariantsResolver: true
})(({ theme }: { theme?: CustomTheme }) => ({
  backgroundColor: theme?.palette.primary.main,
  height: 1,
  marginTop: 2
}));
export const TitleComing: React.FC = ({ children }) => {
  return (
    <Box ml={{ sm: 12, xs: 5 }} mb={2} width="max-content">
      <Typography variant="h4" color="secondary">
        {children}
      </Typography>
      <Line />
    </Box>
  );
};
