import { ArrowForward } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { Box, MenuItem, useTheme } from "@mui/material";
import { CustomTheme } from "helpers/Theme";
import { useState } from "react";

type DestinationMenuItemMobileProps = {
  label: string;
  routes: string;
  onClose: () => void;
  isTransparent: boolean;
};

export const DestinationMenuItemMobile = ({
  label,
  onClose,
  routes,
  isTransparent
}: DestinationMenuItemMobileProps) => {
  const [isHover, setHover] = useState(false);
  const defaultTextColor = isTransparent ? "common.white" : "common.black";
  const defaultIconColor = isTransparent ? "#fff" : "#000";

  const { palette } = useTheme<CustomTheme>();
  return (
    <MenuItem
      component={RouterLink}
      aria-label="destination-menu-mobile"
      to={routes}
      onClick={onClose}
      sx={{
        borderRadius: 1,
        color: isHover ? "common.black" : defaultTextColor,
        "&:hover": {
          backgroundColor: isTransparent ? "common.white" : palette.neutral[200]
        }
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      {isHover ? (
        <ArrowForward
          sx={{ mr: 2.5 }}
          htmlColor={isHover ? "#000" : defaultIconColor}
          fontSize="small"
        />
      ) : (
        <Box width={34} />
      )}
      {label}
    </MenuItem>
  );
};
