import React from "react";
import { Table, TableBody, TableCell, TableRow, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { GetTotal } from "helpers/Utils";
import { LabelQuote } from "pages/Quote/components/LabelQuote";
import { IQuote } from "types/Quote";

export const QuoteTableInfo = ({ quote }: { quote: IQuote }) => {
  const { palette } = useTheme();
  const showDate = !!quote.dateIn;
  const dateIn = dayjs.utc(quote.dateIn);
  return (
    <Table
      sx={{
        width: { lg: "100%" },
        margin: { xs: "auto" },
        borderRadius: 3,
        bgcolor: palette.neutral[200]
      }}>
      <TableBody
        sx={{
          "& tr > td": {
            borderBottom: `0.5px solid ${palette.secondary.main}`
          },
          "& tr > th": {
            borderRight: `0.5px solid ${palette.secondary.main}`,
            borderBottom: `0.5px solid ${palette.secondary.main}`
          },
          "& tr:last-child td, tr:last-child th": { borderBottom: "none" }
        }}>
        <TableRow>
          <TableCell component="th">
            <LabelQuote icon="Quote" label="Cotización" />
          </TableCell>
          <TableCell align="left">
            <LabelQuote label={quote.code} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">
            <LabelQuote icon="Person" label="Estimado(a)." />
          </TableCell>
          <TableCell align="left">
            <LabelQuote label={quote.client.name} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">
            <LabelQuote icon="Calendar" label="Fecha llegada" />
          </TableCell>
          <TableCell align="left">
            <LabelQuote label={showDate ? dateIn.format("DD/MM/YYYY HH:mm") : "Por definir"} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell component="th">
            <LabelQuote icon="MoneyPack" label="Monto Total (USD)" />
          </TableCell>
          <TableCell align="left">
            <LabelQuote label={`$ ${GetTotal(quote.resume).toFixed(2)} Dólares Americanos`} />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
