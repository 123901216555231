import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";
export const Power = (props: CustomSvgProps) => {
  const SvgSize = props.size || 24;
  return (
    <SvgIcon
      {...props}
      width="34"
      height="18"
      viewBox="0 0 34 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.76549 9.80193C6.60108 8.83894 5.49933 7.92716 4.33047 6.96017C6.6147 4.61699 9.00256 2.56407 12.4069 2.02111C12.7568 3.35632 13.1017 4.6722 13.475 6.09621C11.1849 6.70918 9.3128 7.97439 7.76549 9.80193Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.8665 9.66795C28.0308 8.70437 29.1325 7.79316 30.3013 6.82618C28.0172 4.483 25.6293 2.43007 22.2252 1.88711C21.8752 3.22233 21.5304 4.53821 21.1571 5.96222C23.447 6.57462 25.3192 7.8404 26.8665 9.66795"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0054 1.62206C20.6554 2.88946 20.3001 4.1745 19.9153 5.56654C18.1353 4.82328 16.3855 4.85402 14.5668 5.48744C14.2275 4.0345 13.9053 2.65442 13.5752 1.23905C15.9257 0.757353 18.3286 0.40957 21.0054 1.62206Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.468437 17.2038C0.465516 16.431 0.519736 15.4323 0.733822 14.289C0.837947 13.7313 1.03104 12.7269 1.52929 11.5068C1.86516 10.6822 2.43069 9.53897 3.38506 8.32685C4.53405 9.12185 5.68319 9.91684 6.83233 10.7118C6.20827 11.7424 5.49357 13.2152 5.10862 15.084C4.95042 15.8522 4.87285 16.5659 4.84338 17.2038H0.601056"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.5264 17.2038C33.5293 16.431 33.4752 15.4323 33.2614 14.289C33.1572 13.7313 32.9644 12.7269 32.4666 11.5068C32.1309 10.6822 31.566 9.53897 30.6125 8.32685C29.4645 9.12185 28.3164 9.91684 27.1684 10.7118C27.7919 11.7424 28.5058 13.2152 28.8904 15.084C29.0486 15.8522 29.126 16.5659 29.1556 17.2038H33.3939"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89393 17.2036C8.6411 13.0031 11.216 9.61987 14.6507 8.59094C17.3179 7.7925 19.7347 8.64443 19.9505 8.72354C22.4713 9.65002 23.7471 11.5684 24.3224 12.4335C25.5897 14.3388 25.9334 16.2037 26.045 17.2036H7.89393Z"
        fill="#EEF0F3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.882 7.5818L17.0034 5.5L17.9704 7.5818H15.882Z"
        fill="#606A78"
      />
      <path
        d="M17.0218 14.0366C15.8836 14.0366 15.3145 13.5076 15.3145 12.4496V12.2581H16.423V12.4223C16.4338 12.705 16.4796 12.9125 16.5606 13.0447C16.6469 13.177 16.8033 13.2431 17.0299 13.2431C17.2618 13.2431 17.4183 13.1747 17.4992 13.0379C17.5801 12.8965 17.6206 12.6754 17.6206 12.3744C17.6206 12.0734 17.5639 11.8454 17.4506 11.6904C17.3373 11.5353 17.1378 11.451 16.8519 11.4373H16.6657V10.678H16.8357C17.127 10.6734 17.3293 10.6073 17.4425 10.4796C17.5612 10.352 17.6206 10.1422 17.6206 9.85034C17.6206 9.59953 17.5747 9.41257 17.483 9.28944C17.3967 9.16632 17.2402 9.10476 17.0137 9.10476C16.7925 9.10476 16.6415 9.17088 16.5606 9.30312C16.4796 9.43537 16.4338 9.63145 16.423 9.89138V10.0897H15.3145V9.82982C15.3145 9.35556 15.4601 8.99075 15.7514 8.73538C16.0481 8.47545 16.4689 8.34549 17.0137 8.34549C17.5693 8.34549 17.9928 8.47317 18.2841 8.72854C18.5807 8.97935 18.7291 9.3396 18.7291 9.8093C18.7291 10.1331 18.6482 10.4021 18.4863 10.6164C18.3299 10.8262 18.1222 10.963 17.8633 11.0269C18.133 11.1044 18.3434 11.2526 18.4944 11.4715C18.6509 11.6858 18.7291 11.9799 18.7291 12.3539C18.7291 12.8828 18.5915 13.2955 18.3164 13.5919C18.0467 13.8884 17.6152 14.0366 17.0218 14.0366Z"
        fill="#606A78"
      />
    </SvgIcon>
  );
};
