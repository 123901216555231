import { SvgIcon, SvgIconProps } from "@mui/material";
export const Measurer = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.4591 12.4811C5.76045 11.9033 5.0994 11.3563 4.39809 10.7761C5.76862 9.37018 7.20134 8.13842 9.24393 7.81265C9.45391 8.61378 9.66081 9.4033 9.8848 10.2577C8.51077 10.6255 7.38748 11.3846 6.4591 12.4811Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.9196 12.4008C18.6182 11.8226 19.2792 11.2759 19.9805 10.6957C18.61 9.28981 17.1773 8.05806 15.1348 7.73228C14.9249 8.53341 14.718 9.32294 14.494 10.1773C15.8679 10.5448 16.9912 11.3043 17.9196 12.4008"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4025 7.57328C14.1924 8.33373 13.9793 9.10475 13.7484 9.93997C12.6804 9.49402 11.6305 9.51246 10.5393 9.89251C10.3357 9.02075 10.1424 8.1927 9.94434 7.34348C11.3547 7.05446 12.7964 6.84579 14.4025 7.57328Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.08067 16.9223C2.07892 16.4586 2.11145 15.8593 2.2399 15.1734C2.30238 14.8388 2.41824 14.2361 2.71718 13.5041C2.9187 13.0093 3.25803 12.3234 3.83064 11.5961C4.52004 12.0731 5.20952 12.5501 5.89901 13.0271C5.52457 13.6454 5.09575 14.5291 4.86478 15.6504C4.76986 16.1113 4.72332 16.5395 4.70563 16.9223H2.16024"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.915 16.9223C21.9168 16.4586 21.8844 15.8593 21.756 15.1734C21.6935 14.8388 21.5779 14.2361 21.2792 13.5041C21.0778 13.0093 20.7388 12.3234 20.1667 11.5961C19.4779 12.0731 18.789 12.5501 18.1003 13.0271C18.4744 13.6454 18.9027 14.5291 19.1335 15.6504C19.2284 16.1113 19.2748 16.5395 19.2926 16.9223H21.8355"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.53558 16.9222C6.98388 14.4019 8.52882 12.3719 10.5897 11.7546C12.19 11.2755 13.64 11.7867 13.7695 11.8341C15.282 12.39 16.0475 13.5411 16.3927 14.0601C17.1531 15.2033 17.3593 16.3223 17.4262 16.9222H6.53558Z"
          fill="#EEF0F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3284 11.149L12.0013 9.89994L12.5814 11.149H11.3284Z"
          fill="#606A78"
        />
        <path
          d="M12.0127 15.0221C11.3297 15.0221 10.9883 14.7047 10.9883 14.0699V13.955H11.6534V14.0535C11.6599 14.2231 11.6874 14.3476 11.7359 14.427C11.7877 14.5063 11.8816 14.546 12.0175 14.546C12.1567 14.546 12.2506 14.5049 12.2991 14.4229C12.3477 14.338 12.3719 14.2053 12.3719 14.0248C12.3719 13.8442 12.338 13.7074 12.27 13.6143C12.202 13.5213 12.0823 13.4707 11.9107 13.4625H11.7991V13.0069H11.901C12.0758 13.0042 12.1972 12.9645 12.2651 12.8879C12.3363 12.8113 12.3719 12.6854 12.3719 12.5103C12.3719 12.3598 12.3444 12.2477 12.2894 12.1738C12.2376 12.0999 12.1438 12.063 12.0078 12.063C11.8751 12.063 11.7845 12.1027 11.7359 12.182C11.6874 12.2613 11.6599 12.379 11.6534 12.535V12.654H10.9883V12.498C10.9883 12.2135 11.0757 11.9946 11.2504 11.8414C11.4285 11.6854 11.6809 11.6074 12.0078 11.6074C12.3412 11.6074 12.5953 11.684 12.77 11.8373C12.9481 11.9877 13.0371 12.2039 13.0371 12.4857C13.0371 12.68 12.9885 12.8414 12.8914 12.97C12.7976 13.0959 12.6729 13.1779 12.5176 13.2162C12.6794 13.2628 12.8056 13.3517 12.8963 13.483C12.9901 13.6116 13.0371 13.7881 13.0371 14.0124C13.0371 14.3298 12.9545 14.5775 12.7895 14.7553C12.6276 14.9331 12.3687 15.0221 12.0127 15.0221Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
