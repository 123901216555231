import { Box, Typography } from "@mui/material";
import { Icon } from "components/icon";
import { IconName } from "types/Common";

type LabelItemsProps = {
  icon?: IconName;
  description: string;
};

export const LabelItems = ({ icon, description }: LabelItemsProps) => {
  return (
    <Box display="flex" alignItems="flex-start" gap={3} m={5}>
      {icon && <Icon name={icon} size={20} sx={{ paddingTop: 1 }} />}
      <Typography variant="body1">{description}</Typography>
    </Box>
  );
};
