import { SvgIcon, SvgIconProps } from "@mui/material";
export const PeopleGroup = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0749 4.14305C16.9351 4.32725 16.8029 4.50195 16.6523 4.70134C16.4087 4.03671 16.1733 3.39614 15.9297 2.73215C16.6308 2.77709 17.3115 2.8214 18.0227 2.86761C17.8784 3.06256 17.7495 3.23727 17.6172 3.41576C20.3295 5.14885 21.9789 7.58074 22.3953 10.788C22.6857 13.0319 22.2327 15.1473 21.078 17.095C18.8364 20.8745 14.4101 22.7367 10.1654 21.7113C5.86489 20.6719 2.90642 17.0152 2.55717 12.8914C2.17132 8.33714 4.78054 4.88743 7.46685 3.39804C7.46947 3.42906 7.47581 3.45501 7.4738 3.48159C7.45928 3.75947 7.44028 4.03798 7.43086 4.31649C7.42762 4.4127 7.3941 4.47093 7.31378 4.52853C5.20687 6.02362 3.90426 8.04344 3.5164 10.5962C3.06784 13.5459 3.89855 16.136 5.9446 18.3071C7.38777 19.8389 9.18157 20.7295 11.265 21.0163C15.8577 21.6499 20.1846 18.7217 21.319 14.2225C22.2927 10.3627 20.6281 6.29833 17.228 4.2323C17.1826 4.20445 17.1356 4.1785 17.0749 4.14305"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.91408 15.5453C8.91408 15.2875 8.9184 15.0291 8.91346 14.7713C8.90388 14.3007 9.09429 13.9238 9.4422 13.6204C9.87614 13.2429 10.3973 13.0383 10.9406 12.8825C10.9735 12.873 11.0374 12.9015 11.0589 12.9319C11.6889 13.8434 13.3176 13.8339 13.935 12.9294C13.9552 12.9002 14.0134 12.8704 14.0425 12.8793C14.6376 13.0535 15.2101 13.2739 15.6605 13.7255C15.9413 14.0074 16.0824 14.3475 16.0812 14.7472C16.0798 15.2426 16.0691 15.7385 16.0843 16.2332C16.1008 16.7836 15.8793 17.2283 15.5137 17.6115C15.0748 18.072 14.5156 18.3146 13.9033 18.4242C12.8824 18.6066 11.8597 18.6154 10.847 18.3678C10.2265 18.2158 9.68388 17.9187 9.2935 17.393C9.04441 17.0579 8.89123 16.688 8.91207 16.2573C8.9235 16.0204 8.91408 15.7828 8.91408 15.5453Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2323 17.1197C16.4031 16.8088 16.4203 16.4878 16.4145 16.1598C16.4056 15.6766 16.4006 15.1935 16.4152 14.711C16.4309 14.1626 16.2153 13.7244 15.7914 13.4021C15.5358 13.2077 15.2397 13.0659 14.9614 12.9025C14.9228 12.8791 14.8805 12.8639 14.8186 12.8354C14.9007 12.779 14.9602 12.7246 15.0299 12.6935C15.3525 12.5517 15.6776 12.4143 16.0047 12.2832C16.0376 12.2699 16.1115 12.2959 16.1336 12.3263C16.6814 13.107 18.0851 13.1134 18.6349 12.3237C18.6545 12.2952 18.7134 12.268 18.743 12.2769C19.2099 12.4111 19.6578 12.5859 20.0341 12.9057C20.3453 13.1703 20.518 13.5003 20.5137 13.9182C20.5086 14.4007 20.5231 14.8845 20.5092 15.3664C20.4965 15.8071 20.2732 16.1579 19.9671 16.4555C19.554 16.857 19.0385 17.0514 18.4837 17.1394C17.7688 17.2534 17.0508 17.2464 16.3343 17.1476C16.3063 17.1438 16.2797 17.1337 16.2323 17.1197"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.72244 17.0885C8.43484 17.1132 8.19224 17.1454 7.9484 17.153C7.34042 17.1714 6.73306 17.1745 6.13776 17.0183C5.59751 16.8766 5.11617 16.6362 4.7678 16.1833C4.53865 15.8866 4.40367 15.5577 4.41635 15.17C4.43026 14.7335 4.42516 14.2964 4.41758 13.86C4.41124 13.4615 4.5722 13.1426 4.8629 12.8839C5.24157 12.5462 5.70204 12.3652 6.18152 12.2292C6.21445 12.2198 6.27646 12.2495 6.29996 12.2786C6.84393 12.9927 8.04658 13.068 8.67559 12.4241C8.69584 12.4032 8.71548 12.3817 8.73373 12.3589C8.86114 12.2065 8.86624 12.1926 9.0555 12.271C9.37031 12.4007 9.67677 12.5493 9.98525 12.6923C10.0251 12.7106 10.0581 12.7441 10.1133 12.7846C10.0568 12.8106 10.0182 12.8295 9.97891 12.8472C9.60395 13.0136 9.24939 13.2122 8.96118 13.5114C8.67173 13.8131 8.51587 14.1674 8.51911 14.5899C8.52298 15.0346 8.52035 15.4799 8.51974 15.9246C8.51974 16.3105 8.48293 16.7007 8.72245 17.0885"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9613 10.5827C14.0011 11.3634 13.72 12.0094 13.1325 12.5175C12.676 12.9123 12.3108 12.9116 11.855 12.5156C11.2597 11.9999 10.9799 11.3426 11.0325 10.5549C11.0509 10.2778 11.0761 9.99748 11.1388 9.72795C11.2673 9.17309 11.6282 8.85358 12.1981 8.78525C12.4848 8.75045 12.7717 8.75614 13.0521 8.83966C13.3877 8.93962 13.6251 9.15221 13.7662 9.47171C13.9226 9.82602 13.9461 10.2037 13.9613 10.5827"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4739 10.7473C14.47 10.6954 14.4682 10.6479 14.4624 10.6005C14.422 10.2582 14.4093 9.90964 14.3347 9.57497C14.1505 8.75315 13.6725 8.32865 12.8333 8.2464C12.5307 8.21667 12.2149 8.23122 11.9162 8.28436C11.2757 8.3976 10.8877 8.81009 10.7022 9.4225C10.5826 9.81601 10.5479 10.2215 10.5371 10.6296C10.5358 10.6631 10.5352 10.696 10.5326 10.7296C10.532 10.7359 10.5238 10.7422 10.5048 10.77C10.4029 10.6777 10.2952 10.5948 10.2054 10.4961C9.94785 10.2127 9.79456 9.88876 9.80986 9.49146C9.82315 9.14287 9.82176 8.79238 9.80986 8.44379C9.7958 8.0275 9.97304 7.71434 10.2946 7.47014C10.6004 7.23669 10.951 7.09624 11.3186 6.99628C11.3497 6.98806 11.4086 7.00957 11.4276 7.03614C11.8972 7.70106 13.1023 7.70485 13.5739 7.03234C13.5903 7.0083 13.6371 6.98236 13.6606 6.98932C14.1151 7.12155 14.553 7.2873 14.8922 7.63906C15.0923 7.8472 15.1904 8.09456 15.1892 8.38369C15.1859 8.80377 15.1898 9.22448 15.1878 9.64456C15.1859 10.0324 14.8118 10.6157 14.4739 10.7473"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.85119 10.1931C8.86 10.9366 8.59244 11.5395 8.00017 11.9862C7.6787 12.2286 7.41934 12.2292 7.09725 11.9894C6.57265 11.5977 6.28099 11.072 6.2708 10.4139C6.26709 10.1292 6.28918 9.83879 6.34294 9.55912C6.46127 8.94728 6.83588 8.64927 7.46352 8.63345C7.58756 8.63029 7.71347 8.63599 7.83627 8.65307C8.28364 8.71634 8.59105 8.95994 8.70938 9.39841C8.77966 9.6572 8.80562 9.928 8.85119 10.1931Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0891 10.2874C16.1133 10.0766 16.1252 9.86455 16.1627 9.65632C16.2774 9.02086 16.6554 8.70059 17.3007 8.68287C17.4213 8.67971 17.5429 8.68477 17.6621 8.70059C18.0438 8.75123 18.3608 8.92465 18.4888 9.29808C18.7444 10.0437 18.7882 10.7893 18.3475 11.495C18.1909 11.7456 17.9893 11.9532 17.7338 12.107C17.4948 12.2501 17.2583 12.2513 17.0262 12.1007C16.4942 11.7558 16.1938 11.2627 16.1119 10.6374C16.0967 10.5228 16.11 10.4051 16.11 10.2886L16.0891 10.2874Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6161 5.29183C13.6161 5.89278 13.3941 6.38365 12.9284 6.75687C12.6184 7.0061 12.3715 7.0042 12.0609 6.75307C11.5643 6.35076 11.3536 5.82066 11.4028 5.18746C11.4181 4.99389 11.4396 4.79716 11.4852 4.60866C11.5839 4.20065 11.8547 3.96596 12.2716 3.91915C12.431 3.90081 12.5969 3.90587 12.7563 3.92548C13.1663 3.97672 13.4187 4.22089 13.5131 4.61625C13.5656 4.83702 13.5832 5.06664 13.6161 5.29183Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.842 7.08887C9.85033 7.52909 9.68722 7.8933 9.36038 8.18214C9.08986 8.42156 8.86996 8.42156 8.59743 8.18277C8.22522 7.8572 8.08418 7.43851 8.12599 6.95206C8.13417 6.85324 8.14436 6.75317 8.16133 6.65562C8.25362 6.13876 8.56657 5.96901 9.02798 5.99308C9.52226 6.01905 9.74849 6.24644 9.81176 6.74113C9.82626 6.85641 9.83197 6.97296 9.842 7.08887Z"
          fill="#606A78"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8873 7.07993C16.9133 7.54303 16.7373 7.92211 16.3785 8.2121C16.1366 8.40732 15.942 8.40796 15.7019 8.21463C15.3534 7.93537 15.1721 7.56957 15.1772 7.12099C15.1791 6.9681 15.1924 6.81394 15.2171 6.66357C15.2939 6.19605 15.6049 5.95723 16.0802 5.99072C16.6086 6.02799 16.838 6.26997 16.8867 6.84364C16.893 6.92198 16.8873 7.00095 16.8873 7.07993"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
