import { Box } from "@mui/system";
import { TooltipCustom } from "components/TooltipCustom";
import { useHideAfterTimeOut } from "hooks/useHideAfterTimeOut";
import { useState } from "react";
import { TooltipCustomType } from "../TooltipCustom/types";

export const TooltipHotel: React.FC<TooltipCustomType> = ({
  placement,
  children,
  elementAnchor,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const { leaveAnchor, overDialog } = useHideAfterTimeOut(setOpen);
  return (
    <TooltipCustom
      {...props}
      open={open}
      onMouseOver={() => {
        setOpen(true);
      }}
      onMouseLeave={leaveAnchor}
      elementAnchor={elementAnchor}
      placement={placement}>
      <Box onMouseLeave={() => setOpen(false)} onMouseOver={overDialog}>
        {children}
      </Box>
    </TooltipCustom>
  );
};
