import { ContentTitle } from "../../components/ContentTitle";
import { DestinationCard } from "../../components/DestinationCard";
import { Title } from "../../components/Title";
import { Typography, Box, Container } from "@mui/material";
import { GetAllDestinations } from "services/Destination";
import { useEffect, useState } from "react";
import { IDestination } from "types/Destination";
import { LoaderIndicator } from "components/LoaderIndicator";
import { DestinationImage } from "helpers/Consts";
import { useTitleChange } from "hooks/useTitleChange";
import { CustomTheme } from "helpers/Theme";
import { makeStyles } from "@mui/styles";
const getStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    mx: "5vw",
    [theme.breakpoints.up("xl")]: {
      mx: "auto",
      maxWidth: "85vw"
    }
  }
}));
const getIdentifier = (index: number) =>
  index < 10 ? String(index + 1).padStart(String(index).length + 1, "0") : String(index);
const getInverted = (index: number) => !((index + 1) % 2);
export const Destination = () => {
  const styles = getStyles();
  useTitleChange("Destinos");
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const GetData = async () => {
      try {
        const data = await GetAllDestinations();
        setDestinations(data);
      } catch (e) {
        console.error(e, "Destination->GetData");
      }
      setLoading(false);
    };
    GetData();
  }, []);

  return (
    <>
      <LoaderIndicator isLoading={loading} />
      <ContentTitle title="Destinos" img={DestinationImage} />
      <Container sx={{ mt: 10, mb: 20 }} className={styles.container}>
        <Title>Emprenda la aventura definitiva</Title>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography sx={{ maxWidth: 1174, textAlign: "center", lineHeight: "39px" }}>
            Los destinos más deslumbrantes de Perú podrá conocer con
            <b> Inka Planet Adventure</b>. ¿Quieres conocer
            <b> Cusco, Puno, Arequipa, Lima o Ica</b>? Lo hacemos posible, no hay límite a la hora
            de soñar con todo lo que vas a descubrir cuando vengas a nuestro país.
          </Typography>
        </Box>
        {destinations.map((d, index) => (
          <DestinationCard
            id={d.id}
            key={d.id}
            inverted={getInverted(index)}
            image={d.image}
            title={d.name}
            description={d.description}
            identifier={getIdentifier(index)}
          />
        ))}
      </Container>
    </>
  );
};
