import "./App.css";
import { ThemeProvider } from "@mui/material";
import { Theme } from "./helpers/Theme";
import { RootNavigator } from "./navigation/RootNavigator";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { BrowserRouter as Router } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/es";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);
dayjs.locale("es");
export const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <Provider store={store}>
        <Router>
          <RootNavigator />
        </Router>
      </Provider>
    </ThemeProvider>
  );
};
