import { Box, Typography, useTheme } from "@mui/material";
import { Icon } from "components/icon";
import { TooltipCustom } from "components/TooltipCustom";
import { CustomTheme } from "helpers/Theme";
import { useState } from "react";
import { IEnumDataIcon } from "types/Common";

type ModalButtonProps = {
  label: string;
  data: IEnumDataIcon[];
};

export const LabelInfo = ({ label, data }: ModalButtonProps) => {
  const { palette } = useTheme<CustomTheme>();
  const [open, setOpen] = useState(false);
  return (
    <TooltipCustom
      open={open}
      placement="top"
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      elementAnchor={
        <Box display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
          <Typography
            variant="caption"
            sx={{
              textTransform: "capitalize",
              fontWeight: 500,
              color: palette.neutral["900"],
              mr: 1
            }}>
            {label}
          </Typography>
          <Icon name="Info" size={15} />
        </Box>
      }
      arrow>
      <Typography pt={4} textAlign="center" color="common.secondary" variant="h4">
        {label}
      </Typography>
      <Box pb={data.length ? 1 : 4} display="flex" flexDirection="column">
        {data.map(item => (
          <Box p={1} pb={4} key={item.id} display="flex" alignItems="center">
            {!!item.image && <img src={item.image} style={{ maxWidth: 24, maxHeight: 24 }} />}
            <Typography pl={2} color="common.secondary" variant="body1" lineHeight="1rem">
              {item.name}
            </Typography>
          </Box>
        ))}
      </Box>
    </TooltipCustom>
  );
};
