import { SvgIcon, SvgIconProps } from "@mui/material";
export const ArrowCircleLeft = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width={props.width || "24"}
      height={props.height || "25"}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 22.1528C17.5228 22.1528 22 17.6757 22 12.1528C22 6.62998 17.5228 2.15283 12 2.15283C6.47715 2.15283 2 6.62998 2 12.1528C2 17.6757 6.47715 22.1528 12 22.1528Z"
        stroke={props.htmlColor || "#606A78"}
        fill="none"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2602 15.6828L9.74023 12.1528L13.2602 8.6228"
        stroke={props.htmlColor || "#606A78"}
        fill="none"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
