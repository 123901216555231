import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";
export const Hotel = (props: CustomSvgProps) => {
  const SvgSize = props.size || 24;
  return (
    <SvgIcon
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.623 21.8236C13.623 21.1388 13.6248 20.4757 13.6207 19.8125C13.6207 19.7321 13.5973 19.6499 13.5752 19.5712C13.3514 18.7677 12.6189 18.277 11.7868 18.3708C11.0118 18.4582 10.3905 19.1429 10.3713 19.9541C10.3573 20.5427 10.3678 21.1313 10.369 21.7198C10.369 21.7548 10.3823 21.7898 10.3887 21.8236H13.623ZM8.09566 5.16395H15.8841V2.90932H8.09566V5.16395ZM19.1818 5.61385H4.82354V21.8189H9.93716C9.93716 21.7362 9.93652 21.6627 9.93716 21.5887C9.94122 21.0066 9.9272 20.4238 9.95402 19.8428C10.0053 18.7502 10.9482 17.9058 12.0612 17.932C13.1586 17.9576 14.0537 18.8684 14.0566 19.9634C14.0577 20.5141 14.0566 21.0648 14.0566 21.6155V21.816H19.1818L19.1818 5.61385ZM4.37652 5.17911H7.67781V4.95826C7.67781 4.26828 7.67724 3.5789 7.67781 2.88891C7.67781 2.59287 7.7944 2.46469 8.08343 2.46469C10.6965 2.46294 13.3095 2.46294 15.9226 2.46469C16.1976 2.46469 16.3275 2.59753 16.3281 2.87493C16.3305 3.56431 16.3293 4.25429 16.3293 4.94368V5.17213H19.6177V21.8306C19.8456 21.8306 20.0583 21.83 20.271 21.8311C20.4692 21.8323 20.5671 21.904 20.5606 22.0491C20.553 22.2292 20.4237 22.2554 20.2815 22.26C20.2058 22.2624 20.13 22.2612 20.0537 22.2612C14.6568 22.2612 9.25995 22.2612 3.86313 22.2606C3.78737 22.2606 3.71161 22.2566 3.63585 22.2513C3.50709 22.2425 3.43311 22.1633 3.43717 22.0421C3.44066 21.9145 3.51876 21.8323 3.65748 21.8317C3.88995 21.8311 4.12306 21.8317 4.37652 21.8317L4.37652 5.17911Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3409 19.4634H17.7454V18.0689H16.3409V19.4634ZM18.1766 18.7694C18.1766 19.0351 18.1801 19.3009 18.176 19.566C18.1725 19.7845 18.0688 19.9017 17.8532 19.904C17.3095 19.911 16.7657 19.9104 16.222 19.9034C16.0204 19.9011 15.9045 19.7845 15.9021 19.5782C15.8952 19.0345 15.8952 18.4908 15.9039 17.9471C15.9074 17.7338 16.0263 17.6313 16.2472 17.6295C16.778 17.626 17.3095 17.626 17.8403 17.6307C18.0729 17.6325 18.1725 17.7385 18.176 17.9728C18.1795 18.2379 18.1766 18.5037 18.1766 18.7694Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3403 12.1668H17.7454V10.7671H16.3403V12.1668ZM18.1766 11.4705C18.1766 11.7298 18.1789 11.9891 18.176 12.2484C18.1737 12.4914 18.0699 12.601 17.8328 12.6022C17.3013 12.6051 16.7704 12.6051 16.239 12.6022C16.0239 12.601 15.9051 12.4938 15.9021 12.2776C15.894 11.7397 15.894 11.2024 15.9016 10.6645C15.9045 10.4332 16.0274 10.3265 16.2669 10.3254C16.7856 10.3225 17.3042 10.3225 17.8229 10.3248C18.0606 10.3259 18.1714 10.4355 18.176 10.6738C18.1807 10.939 18.1772 11.2047 18.1766 11.4705Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3421 15.8166H17.7401V14.4128H16.3421V15.8166ZM17.0478 16.2484C16.7821 16.2484 16.5163 16.2496 16.2506 16.2484C16.028 16.2467 15.9068 16.1523 15.9033 15.939C15.8945 15.3889 15.8952 14.8382 15.9039 14.2881C15.9074 14.0882 16.0274 13.9757 16.2349 13.9728C16.7722 13.9658 17.3101 13.9658 17.8479 13.9739C18.0624 13.9769 18.1737 14.0969 18.1755 14.3108C18.1801 14.8481 18.1795 15.3859 18.1755 15.9238C18.1737 16.1435 18.0659 16.2449 17.8445 16.2478C17.5787 16.2502 17.3136 16.2484 17.0478 16.2484Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0211 10.7677H9.62303V12.1727H11.0211V10.7677ZM10.3095 12.6027C10.0444 12.6027 9.7786 12.6057 9.51291 12.6022C9.30541 12.5987 9.18597 12.4984 9.18248 12.2922C9.17373 11.742 9.17487 11.1913 9.18362 10.6412C9.1871 10.4372 9.30896 10.3283 9.51347 10.3265C10.0513 10.3219 10.5886 10.3219 11.1265 10.3265C11.3398 10.3289 11.4523 10.4443 11.454 10.661C11.4587 11.1989 11.4593 11.7362 11.4546 12.2741C11.4529 12.4938 11.3433 12.5987 11.1259 12.6022C10.8538 12.6057 10.5817 12.6027 10.3095 12.6027Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.62189 15.8172H11.0216V14.4133H9.62189V15.8172ZM10.306 16.249C10.0467 16.249 9.78735 16.2507 9.52806 16.2484C9.29729 16.2467 9.18305 16.1476 9.18013 15.9186C9.17373 15.3807 9.17373 14.8434 9.18191 14.3061C9.18482 14.0917 9.30191 13.9757 9.51639 13.9728C10.0537 13.9658 10.591 13.9664 11.1288 13.9734C11.3375 13.9763 11.4529 14.1021 11.454 14.3131C11.4581 14.8446 11.4587 15.3755 11.4552 15.9069C11.454 16.1488 11.3486 16.2467 11.1027 16.2484C10.8369 16.2502 10.5711 16.249 10.306 16.249Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.345 8.52468H17.7407V7.10451H16.345V8.52468ZM17.0251 8.95708C16.7657 8.95708 16.5065 8.96057 16.2472 8.95591C16.0199 8.95182 15.9039 8.84519 15.9009 8.61908C15.8945 8.08122 15.8945 7.54393 15.9016 7.00603C15.9045 6.78985 16.0228 6.68204 16.2384 6.6803C16.7757 6.67678 17.3136 6.67621 17.8509 6.6803C18.0559 6.68204 18.172 6.79216 18.1737 6.99788C18.1801 7.54798 18.1795 8.09811 18.1742 8.64821C18.1725 8.84518 18.0525 8.95182 17.841 8.95591C17.5694 8.96117 17.2973 8.95708 17.0251 8.95708Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66266 18.0736H6.25473V19.4681H7.66266V18.0736ZM8.09566 18.7676C8.09566 19.0334 8.09737 19.2991 8.09445 19.5648C8.09217 19.7816 7.98838 19.9011 7.77341 19.9034C7.22908 19.911 6.68538 19.9116 6.14169 19.904C5.93248 19.9017 5.82058 19.7764 5.81887 19.5607C5.81475 19.0293 5.81475 18.4984 5.81887 17.9669C5.82001 17.7461 5.93945 17.6313 6.16445 17.6295C6.68951 17.626 7.21392 17.6254 7.73898 17.6289C7.99308 17.6307 8.09331 17.7373 8.09508 17.9897C8.09679 18.249 8.09566 18.5083 8.09566 18.7676Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0246 7.11152H9.62189V8.52642H11.0246V7.11152ZM11.4569 7.8312C11.4564 8.08413 11.4587 8.33702 11.4564 8.58994C11.4534 8.8417 11.3479 8.95533 11.1027 8.95707C10.5776 8.95999 10.0526 8.95999 9.52806 8.9559C9.30078 8.95416 9.18305 8.84579 9.18013 8.62025C9.17373 8.08295 9.17373 7.54506 9.18134 7.00776C9.18483 6.78344 9.30483 6.68029 9.53503 6.67912C10.0601 6.67738 10.5851 6.67738 11.1102 6.67969C11.3357 6.68086 11.4505 6.78985 11.4552 7.01535C11.4616 7.28691 11.4569 7.55907 11.4569 7.8312Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3893 10.7671H12.9767V12.1739H14.3893V10.7671ZM14.8165 11.4658C14.8165 11.7321 14.8182 11.9979 14.8159 12.2636C14.8135 12.4903 14.7058 12.601 14.4779 12.6022C13.9465 12.6051 13.4149 12.6051 12.8829 12.6022C12.6574 12.601 12.5415 12.4897 12.5397 12.2647C12.535 11.7327 12.535 11.2012 12.5397 10.6697C12.5415 10.4489 12.662 10.3277 12.8823 10.3259C13.4138 10.3219 13.9453 10.3219 14.4767 10.3265C14.7011 10.3283 14.8135 10.4472 14.8159 10.6686C14.8182 10.9343 14.8165 11.2001 14.8165 11.4658Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66266 10.7735H6.2553V12.1721H7.66266V10.7735ZM8.09566 11.4734C8.09566 11.7391 8.09801 12.0048 8.09445 12.2706C8.09217 12.4868 7.98319 12.6004 7.76815 12.6016C7.23085 12.6051 6.69299 12.6051 6.15506 12.6016C5.94008 12.6004 5.82179 12.4891 5.81944 12.2752C5.81418 11.7374 5.81418 11.1995 5.81944 10.6616C5.82115 10.4431 5.94528 10.3271 6.16964 10.3254C6.6947 10.3225 7.21975 10.3225 7.74481 10.3259C7.98262 10.3277 8.0933 10.4419 8.09508 10.6762C8.09679 10.9419 8.09566 11.2076 8.09566 11.4734Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9727 15.8172H14.3841V14.418H12.9727V15.8172ZM13.6644 16.2484C13.3987 16.249 13.1329 16.2513 12.8672 16.2478C12.6644 16.2455 12.5426 16.1371 12.5403 15.9349C12.5344 15.3906 12.535 14.8463 12.5403 14.302C12.542 14.1045 12.6591 13.9786 12.8608 13.9751C13.4051 13.9658 13.9493 13.9658 14.4931 13.9728C14.7011 13.9757 14.8148 14.1068 14.8159 14.3148C14.8177 14.8463 14.8177 15.3778 14.8159 15.9093C14.8148 16.14 14.7087 16.2461 14.4809 16.2478C14.2087 16.2496 13.9365 16.2484 13.6644 16.2484Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66736 14.4133H6.25473V15.8224H7.66736V14.4133ZM8.09566 15.1272C8.09566 15.3801 8.09623 15.633 8.09509 15.886C8.09388 16.1424 7.99422 16.2473 7.74368 16.2478C7.21919 16.2496 6.69413 16.2496 6.16908 16.2484C5.93247 16.2473 5.82001 16.1383 5.81823 15.9005C5.81539 15.3754 5.81475 14.8504 5.81823 14.3253C5.82001 14.1021 5.93134 13.978 6.1545 13.974C6.69235 13.9658 7.23029 13.9658 7.76758 13.974C7.98668 13.9769 8.09331 14.1057 8.09509 14.33C8.0968 14.5957 8.09566 14.8615 8.09566 15.1272Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25651 8.52176H7.66735V7.10743H6.25651V8.52176ZM8.09566 7.82189C8.09566 8.08761 8.09737 8.35334 8.09445 8.61907C8.09274 8.83294 7.98376 8.95299 7.77049 8.95473C7.22616 8.95999 6.6819 8.95999 6.13763 8.95416C5.93596 8.95181 5.82115 8.83355 5.81944 8.629C5.81418 8.0847 5.81361 7.5404 5.82001 6.99614C5.82236 6.79568 5.94237 6.68261 6.14752 6.68086C6.69121 6.6762 7.23548 6.6762 7.77982 6.68086C7.98028 6.6826 8.09218 6.80325 8.09445 7.00546C8.09801 7.27759 8.09566 7.54975 8.09566 7.82189Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9773 8.52119H14.3876V7.10742H12.9773V8.52119ZM14.8165 7.8312C14.8165 8.09053 14.8182 8.34985 14.8159 8.60918C14.8136 8.83642 14.7075 8.95299 14.482 8.95533C13.9441 8.96056 13.4063 8.95999 12.8683 8.95472C12.6586 8.95298 12.5415 8.83237 12.5397 8.61967C12.535 8.08178 12.535 7.54391 12.5397 7.00602C12.542 6.79858 12.6667 6.68142 12.8783 6.68029C13.4156 6.67737 13.9534 6.67677 14.4913 6.68086C14.704 6.6826 14.813 6.79798 14.8153 7.01477C14.8188 7.2869 14.8165 7.55907 14.8165 7.8312Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4313 4.02993C11.4307 3.74204 11.3351 3.55442 11.1586 3.49264C10.905 3.40407 10.6527 3.52003 10.5723 3.76129C10.4587 4.10221 10.5753 4.49381 10.8142 4.57131C10.838 4.57889 10.8637 4.58298 10.8882 4.58646C11.2291 4.63249 11.4324 4.42389 11.4313 4.02993ZM11.8101 4.0113C11.8083 4.56317 11.4925 4.88485 10.9563 4.88133C10.4266 4.87784 10.1481 4.58764 10.1528 4.04278C10.1574 3.49207 10.4721 3.17972 11.0169 3.18494C11.5409 3.19021 11.8118 3.47226 11.8101 4.0113Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.50567 3.84057C9.50567 3.67249 9.51319 3.52126 9.50333 3.37065C9.49403 3.21886 9.58352 3.2107 9.69458 3.21187C9.79805 3.21244 9.87654 3.22117 9.87419 3.36192C9.86781 3.81033 9.86838 4.25874 9.87419 4.70715C9.87597 4.85315 9.78996 4.84964 9.68876 4.84964C9.58707 4.85025 9.49808 4.85138 9.5039 4.70659C9.51085 4.53034 9.50567 4.35413 9.50567 4.16219H8.8755C8.8755 4.32796 8.86904 4.49605 8.8772 4.66352C8.88359 4.79845 8.8435 4.85489 8.69702 4.85315C8.55921 4.85138 8.50804 4.81592 8.51152 4.67052C8.52081 4.27912 8.51442 3.88711 8.51442 3.49567C8.51442 3.18571 8.51442 3.18571 8.865 3.23395V3.84057L9.50567 3.84057Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4033 4.579V4.85051C14.1024 4.85051 13.8133 4.85867 13.5253 4.84292C13.4734 4.8406 13.3859 4.74192 13.3848 4.68585C13.3731 4.24848 13.3766 3.81053 13.3801 3.37318C13.3807 3.27039 13.4326 3.21259 13.5468 3.21433C13.825 3.21843 14.1031 3.21551 14.3974 3.21551V3.48121H13.7561V3.86776C13.864 3.86776 13.9649 3.85843 14.0628 3.87068C14.159 3.88295 14.3135 3.79709 14.3199 3.99507C14.3269 4.19886 14.1695 4.11535 14.0745 4.12587C13.9748 4.13695 13.8733 4.12819 13.759 4.12819V4.579H14.4033Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3472 3.49113C12.2083 3.49113 12.0987 3.50508 11.9954 3.48531C11.9429 3.4754 11.8759 3.41205 11.8624 3.36026C11.8373 3.26376 11.8933 3.21605 12.0018 3.21779C12.3308 3.22304 12.6593 3.21956 12.9882 3.22013C13.2046 3.2207 13.2123 3.23117 13.1626 3.47831C13.1364 3.48239 13.1067 3.48995 13.0763 3.49112C12.7088 3.50099 12.7088 3.50099 12.7083 3.8604C12.7083 4.12497 12.7018 4.39018 12.7112 4.65418C12.7158 4.79896 12.6744 4.85422 12.5204 4.85422C12.3705 4.85422 12.3419 4.79608 12.3442 4.66291C12.3518 4.28025 12.3472 3.89703 12.3472 3.49113Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0832 4.5653C15.2587 4.5653 15.4051 4.55361 15.548 4.57114C15.5982 4.57756 15.6652 4.64937 15.6787 4.70252C15.7026 4.79418 15.6559 4.85491 15.5433 4.85199C15.3287 4.84675 15.1141 4.84732 14.8994 4.85199C14.7793 4.85434 14.7227 4.80238 14.7227 4.68498C14.7227 4.23595 14.725 3.78693 14.7215 3.3379C14.7203 3.18197 14.8288 3.21291 14.918 3.20884C15.0154 3.20357 15.0872 3.21818 15.0854 3.34898C15.079 3.74661 15.0832 4.14368 15.0832 4.5653Z"
        fill="#606A78"
      />
    </SvgIcon>
  );
};
