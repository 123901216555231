import { SvgIcon } from "@mui/material";
import { CustomSvgProps } from "types/Common";
export const Clock = (props: CustomSvgProps) => {
  const SvgSize = props.size || 24;
  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      style={{ ...props.style, fontSize: SvgSize }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.2796 11.9978H6.91165C6.09249 12.9872 5.29273 13.9541 4.48364 14.9316C3.677 13.956 2.87783 12.9904 2.0625 12.0047H3.64832C3.65137 11.946 3.6558 11.9022 3.65641 11.8578C3.71895 7.96341 6.25755 4.52024 9.95698 3.31273C14.9629 1.67958 20.3194 4.61024 21.6447 9.70843C22.6056 13.4072 21.1505 17.3279 18.0039 19.5067C16.3726 20.6361 14.5599 21.2061 12.5799 21.1342C9.48334 21.0211 7.03794 19.6648 5.24588 17.136C4.97162 16.7498 5.06283 16.2529 5.43717 15.9941C5.81638 15.7316 6.30803 15.8248 6.58169 16.2223C7.25592 17.201 8.10314 17.9898 9.14225 18.5667C10.4944 19.3167 11.9432 19.6273 13.484 19.4854C16.7837 19.1823 19.4766 16.7729 20.1651 13.5141C20.8936 10.0672 19.0304 6.49214 15.7865 5.1015C14.6537 4.61586 13.474 4.41524 12.2469 4.50024C8.87545 4.73462 6.03193 7.26715 5.41459 10.5972C5.3371 11.0147 5.32276 11.4434 5.2802 11.8678C5.27592 11.9072 5.2796 11.9478 5.2796 11.9978Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0994 11.6975H13.2599C14.8288 11.6975 16.3984 11.6969 17.968 11.6982C18.3818 11.6982 18.7118 11.9586 18.7869 12.3377C18.885 12.8323 18.5363 13.2826 18.0305 13.3125C17.9855 13.3157 17.9399 13.3144 17.8943 13.3144C16.0596 13.3144 14.2257 13.315 12.3911 13.3144C11.8085 13.3144 11.481 12.989 11.481 12.4089C11.481 10.6671 11.4804 8.92532 11.4816 7.18355C11.4822 6.6296 11.9098 6.25052 12.4329 6.33546C12.7917 6.39415 13.0749 6.70704 13.0955 7.07051C13.0994 7.13234 13.0994 7.19416 13.0994 7.25599V11.6975Z"
        fill="#606A78"
      />
    </SvgIcon>
  );
};
