import { Box, useMediaQuery, useTheme, Typography } from "@mui/material";
import { ItineraryItem } from "components/ItineraryItem/ItineraryItem";
import bgCarrusel from "assets/BackgroundCarrusel.png";
import { makeStyles } from "@mui/styles";
import { CustomTheme } from "helpers/Theme";
import ArrowRight from "../../../assets/arrow-circle-right.png";
import ArrowLeft from "../../../assets/arrow-circle-left.png";
import { useState } from "react";
import Carousel from "react-simply-carousel";
import { useItineraryDetail } from "../hooks/useItineraryDetail";

type Event = React.MouseEvent<HTMLImageElement, MouseEvent>;
const MB = 100;
const getStyles = makeStyles<CustomTheme>(() => ({
  parallax: {
    position: "relative",
    marginTop: 100 + MB,
    backgroundImage: `url(${bgCarrusel})`,
    width: "100%",
    height: "500px",
    backgroundSize: "cover"
  },
  swiper: {
    position: "absolute",
    top: -50 - MB,
    left: 0,
    width: "100%"
  },
  swiperItem: {
    marginBottom: MB,
    paddingRight: 40,
    paddingLeft: 15
  },
  arrowContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    marginBottom: "30px"
  },
  leftArrow: {
    marginRight: 20
  },
  rightArrow: {
    marginLeft: 20
  }
}));
export const ItineraryCarousel = () => {
  const { breakpoints } = useTheme();
  const isDesktop = useMediaQuery(breakpoints.up("lg"));
  const isTable = useMediaQuery(breakpoints.up("sm"));
  const sliderView = isDesktop || isTable ? 2 : 1;
  const s = getStyles();
  const getIdentifier = (index: number) =>
    index < 10 ? String(index + 1).padStart(String(index).length + 1, "0") : String(index);
  const [activeSlide, setActiveSlide] = useState(0);
  const handleView = (e: Event) => {
    const dir = e.currentTarget.id;
    setActiveSlide(i => (dir === "l" ? (i > 0 ? i - 1 : i) : i < items.length ? i + 1 : i));
  };
  const items = useItineraryDetail();
  return (
    <Box mt={25}>
      <Typography
        fontFamily="Zilla Slab"
        fontSize="1.7rem"
        lineHeight="2rem"
        textAlign="center"
        fontWeight="400">
        Itinerario
      </Typography>
      <Box className={s.parallax}>
        <Carousel
          infinite={false}
          containerProps={{
            className: s.swiper
          }}
          activeSlideIndex={activeSlide}
          itemsListProps={{
            style: {
              paddingLeft: isDesktop || isTable ? "25%" : 0,
              paddingRight: isDesktop ? 180 : isTable ? "80%" : 0
            }
          }}
          itemsToShow={sliderView}
          speed={400}
          onRequestChange={setActiveSlide}
          forwardBtnProps={{
            style: {
              display: "none"
            }
          }}
          backwardBtnProps={{
            style: {
              display: "none"
            }
          }}>
          {items.map((data, i) => (
            <Box key={data.id} className={s.swiperItem}>
              <ItineraryItem
                number={getIdentifier(i)}
                title={data.title}
                subtitle={`Día ${i + 1}: ` + data.subtitle}
                description={data.description}
                img={data.img}
              />
            </Box>
          ))}
        </Carousel>
        <Box className={s.arrowContainer}>
          <img
            src={ArrowLeft}
            alt="leftArrow"
            className={s.leftArrow}
            id="l"
            onClick={handleView}
          />
          <img
            src={ArrowRight}
            alt="rightArrow"
            className={s.rightArrow}
            id="r"
            onClick={handleView}
          />
        </Box>
      </Box>
    </Box>
  );
};
