import { SvgIconProps } from "@mui/material";
export const ArrowView = (props: SvgIconProps) => {
  return (
    <svg
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M17.4201 1.45001L10.9001 7.97001C10.1301 8.74001 8.87008 8.74001 8.10008 7.97001L1.58008 1.45001"
        stroke="#EB5027"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
