import { Box, Link, Typography, TypographyVariant } from "@mui/material";
type BreakLineType = boolean | "after" | "min" | "afterMin";
type ContentType =
  | "text"
  | "prominent-text"
  | "subtitle"
  | "mail"
  | "tel"
  | "url"
  | "navigation"
  | "row";
export type Content = {
  type: ContentType;
  bold?: boolean;
  value: string;
  key?: string;
  children?: Array<Array<Content>>;
  breakLine: BreakLineType;
  external?: boolean;
  debug?: boolean;
};
type TextContentType = Exclude<ContentType, "row">;
const Variants: Record<TextContentType, TypographyVariant> = {
  subtitle: "h4",
  "prominent-text": "body1",
  navigation: "body2",
  text: "body2",
  mail: "body2",
  tel: "body2",
  url: "body2"
};
const getHRef = (v: string, t: ContentType) => {
  if (!v) return undefined;
  let value = v;
  if (v[v.length - 1] === ".") value = v.substring(0, v.length - 1);
  if (t === "mail") return `mailto:${value}`;
  else if (t === "tel") return `tel:${value}`;
  else if (t === "url") return value;
  else if (t === "navigation") return value.match(/(?:\[)(.*)(?=\])/)?.[1] ?? undefined;
  else return undefined;
};
const getValue = (v: string, t: ContentType) => {
  if (t === "navigation") return v.match(/(?:\()(.*)(?=\))/)?.[1] ?? v;
  else return v;
};
const LinkTypes: ContentType[] = ["url", "mail", "tel", "navigation"];
const afterLines = ["after", "afterMin"] as BreakLineType[];
const renderContent = (c: Content, i: number | string) => {
  const hRef = getHRef(c.value, c.type);
  const component =
    c.breakLine && !afterLines.includes(c.breakLine) ? undefined : hRef ? "a" : "span";
  const mt = c.type === "subtitle" ? 6 : undefined;
  const Render = LinkTypes.includes(c.type) ? Link : Typography;
  const value = getValue(c.value, c.type);
  if (c.type === "row")
    return (
      <Box flexDirection="row" display="flex" justifyContent="space-between" mb={10} mx={4}>
        {c.children?.map((child, j) => (
          <Box flex={1} key={`${c.key}_${j}`}>
            {child.map((v, h) => renderContent(v, `${c.key}_${j}_${h}`))}
          </Box>
        ))}
      </Box>
    );
  else
    return (
      <>
        {/* @ts-expect-error: as */}
        <Render
          component={component}
          key={String(i)}
          target={
            c.type === "navigation" || (c.type === "url" && c.external) ? "_blank" : undefined
          }
          color={c.type === "navigation" ? "primary" : undefined}
          underline={c.type === "navigation" ? "hover" : undefined}
          variant={Variants[c.type]}
          mt={mt}
          fontWeight={c.bold ? "500" : undefined}
          href={hRef}>
          {value}
        </Render>
        {afterLines.includes(c.breakLine) ? <br /> : null}
        {(!component && c.breakLine !== "min") || c.breakLine === "after" ? <br /> : null}
      </>
    );
};
export const Body = ({ content }: { content: Content[] }) => {
  return <Box mb={10}>{content.map(renderContent)}</Box>;
};
