import { AppBar, Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { useRef } from "react";
import { Desktop } from "./components/Desktop";
import { Mobile } from "./components/Mobile";
import { CustomTheme } from "helpers/Theme";

export const Header = ({ isTransparent }: { isTransparent: boolean }) => {
  const { breakpoints } = useTheme<CustomTheme>();
  const isMobile = useMediaQuery(breakpoints.only("xs"));
  const portalRef = useRef<HTMLDivElement>(null);
  return (
    <>
      <div ref={portalRef}></div>
      <Box bgcolor="common.white">
        <AppBar position="fixed" color="transparent" elevation={isTransparent ? 0 : undefined}>
          <Toolbar sx={{ padding: { xs: 0, sm: 0, md: 0, lg: 0 } }}>
            {isMobile ? (
              <Mobile portalRef={portalRef} isTransparent={isTransparent} />
            ) : (
              <Desktop isTransparent={isTransparent} />
            )}
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};
