import { PublicApi } from "helpers/API";
import { INewQuote, IQuote, ISimpleQuote } from "types/Quote";

export const SaveQuote = (val: INewQuote) => {
  return new Promise<ISimpleQuote>(async (res, rej) => {
    try {
      const response = await PublicApi.post<ISimpleQuote>("quote-save-web-site", val);
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
};
export const QuoteByCode = (code: string) => {
  return new Promise<IQuote>(async (res, rej) => {
    try {
      const response = await PublicApi.get<IQuote>(`quote-detail-by-code?code=${code}`);
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
};
