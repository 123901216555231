import { Box, Card, Typography, CardMedia } from "@mui/material";
import { Hand } from "icons";
import Quote from "assets/QuoteImg.jpg";
import { LabelQuote } from "../LabelQuote";
import { TitleComing } from "../TitleComing";
import { IQuote } from "types/Quote";
import dayjs from "dayjs";
import { IncludeArrivalOptions, IncludeDescription } from "helpers/Consts";
import { QuoteTableInfo } from "./components/QuoteTableInfo";
import { Hotel } from "../Hotel";

export const FirstSection = ({ quote }: { quote: IQuote }) => {
  const dateIn = dayjs.utc(quote.dateIn);
  const destination = quote.tours?.[0]?.destination?.name;
  const arrivalDay =
    quote.includedIn.included && quote.includedIn.typeIncluded.id === IncludeArrivalOptions.NextDay;
  const hotel = quote.includedHotel && arrivalDay ? quote.hotels[0] : undefined;
  const showDate = !!quote.dateIn;
  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: { md: "row", xs: "column" },
        width: { lg: "77%", md: "96%", sm: "89%", xs: "92%" }
      }}>
      <CardMedia
        component="img"
        alt="Quote"
        image={Quote}
        sx={{
          width: { xs: "100%", md: "50%" },
          height: { lg: 698, md: 775, sm: 593, xs: 666 }
        }}
      />

      <Box width={{ xs: "100%", md: "50%" }}>
        <Box sx={{ margin: { sm: 10, xs: 5 } }}>
          {arrivalDay && (
            <>
              <TitleComing>Día 1: {destination}</TitleComing>
              <Box display="flex" alignItems="center">
                <Box p={1}>
                  <Hand sx={{ fontSize: 42 }} />
                </Box>
                <Typography variant="h2" color="secondary">
                  ¡Bienvenida a {destination}!
                </Typography>
              </Box>
            </>
          )}
          <Box sx={{ width: { lg: "100%" } }} mt={3}>
            {arrivalDay && (
              <>
                {showDate && (
                  <LabelQuote icon="Calendar" label={dateIn.format("DD MMM [,] YYYY")} />
                )}
                <Typography variant="body1" color="secondary">
                  {IncludeDescription}
                </Typography>
                {hotel ? <Hotel data={hotel} pl={0} pb={14} /> : <Box pt={5} />}
              </>
            )}
            <QuoteTableInfo quote={quote} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};
