import { SvgIcon, SvgIconProps } from "@mui/material";
export const AddCircle = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5 22.6528C18 22.6528 22.5 18.1528 22.5 12.6528C22.5 7.15283 18 2.65283 12.5 2.65283C7 2.65283 2.5 7.15283 2.5 12.6528C2.5 18.1528 7 22.6528 12.5 22.6528Z"
          stroke="#EB5027"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 12.6528H16.5"
          stroke="#EB5027"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 16.6528V8.65283"
          stroke="#EB5027"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};
