import { SvgIcon, SvgIconProps } from "@mui/material";

export const Email = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M19.9999 2.58395C19.9999 1.43102 19.1078 0.428467 18.028 0.428467H1.97094C0.891081 0.428467 -0.000976562 1.43102 -0.000976562 2.58395V15.4166C-0.000976562 16.5695 0.891081 17.5721 1.97094 17.5721H18.028C19.1078 17.5721 19.9999 16.5695 19.9999 15.4166V2.58395ZM18.028 2.58395L9.99946 7.94759L1.97094 2.58395H18.028ZM18.028 15.4166H1.97094V4.73943L9.99946 10.0529L18.028 4.73943V15.4166Z"
        fill="#606A78"
      />
    </SvgIcon>
  );
};
