import { Box, Card, Typography } from "@mui/material";
import { LikeHand } from "icons/LikeHand";
import { LabelItemsList } from "./components/LabelItemsList";
export const FifthSection = () => {
  return (
    <Card
      elevation={4}
      sx={{
        display: "flex",
        flexDirection: { xs: "column" },
        paddingBottom: 10,
        width: { lg: "77%", md: "96%", sm: "89%", xs: "92%" }
      }}>
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        sx={{ marginTop: { xs: 10, md: 30 } }}>
        <Box display="flex" alignItems="center">
          <LikeHand sx={{ fontSize: 42, paddingInline: 1 }} />
          <Typography variant="h2" color="secondary">
            Recomendaciones
          </Typography>
        </Box>
      </Box>
      <LabelItemsList />
    </Card>
  );
};
