import React from "react";
import { ContentTitle } from "components/ContentTitle";
import HomeBackground from "assets/HomeBackground.png";
import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CustomTheme } from "helpers/Theme";
type ErrorParams = {
  code: number;
  title: string;
  description: string;
  image: string;
};
export const ErrorTemplate = ({ code, title, image, description }: ErrorParams) => {
  const navigate = useNavigate();
  const { breakpoints } = useTheme<CustomTheme>();
  const isMobile = useMediaQuery(breakpoints.only("xs"));
  return (
    <>
      <ContentTitle img={HomeBackground}>
        <Typography
          variant="h1"
          fontSize={isMobile ? undefined : "100px"}
          color="common.white"
          align="center">
          {"ERROR " + code}
        </Typography>
        <Typography variant={isMobile ? "h3" : "h1"} color="common.white" align="center">
          {title}
        </Typography>
      </ContentTitle>
      <Box px={5}>
        <Box
          flexDirection="row"
          flexWrap="wrap-reverse"
          display="flex"
          my={25}
          alignItems="center"
          justifyContent="space-evenly">
          <Box
            mr={isMobile ? 0 : 5}
            mt={isMobile ? 5 : 0}
            display="flex"
            flexDirection="column"
            alignItems={isMobile ? "center" : "start"}>
            <Typography
              maxWidth={430}
              color="secondary"
              lineHeight="1.5rem"
              textAlign={isMobile ? "center" : "start"}
              fontFamily="Zilla Slab"
              variant="h4"
              mb={7.5}>
              {description}
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate("/")}
              sx={{ textTransform: "none" }}>
              Volver al inicio
            </Button>
          </Box>
          <img
            src={image}
            alt="ClientError"
            style={{ width: "25vw", minWidth: 220, maxWidth: 360 }}
          />
        </Box>
      </Box>
    </>
  );
};
