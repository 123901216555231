import dayjs from "dayjs";
import { IncludeArrivalOptions, IncludeOutletOptions } from "helpers/Consts";
import { IQuote } from "types/Quote";

export const useSection = (quote: IQuote, index: number) => {
  const tour = quote.tours[index];
  const destination = tour.destination.name;
  const isFirstTour = index === 0;
  const isLastTour = quote.tours.length - 1 === index;
  const arrivalSameDay =
    quote.includedIn.included &&
    quote.includedIn.typeIncluded.id === IncludeArrivalOptions.SameDay &&
    isFirstTour;
  const outletSameDay =
    quote.includedOut.included &&
    quote.includedOut.typeIncluded.id === IncludeOutletOptions.SameDay &&
    isLastTour;
  const outletAnotherDay =
    quote.includedOut.included &&
    quote.includedOut.typeIncluded.id === IncludeOutletOptions.NextDay &&
    isLastTour;
  const arrivalDay =
    quote.includedIn.included && quote.includedIn.typeIncluded.id === IncludeArrivalOptions.NextDay;
  const num = arrivalDay ? index + 1 : index;
  const showDate = !!quote.dateIn;
  const date = dayjs.utc(quote.dateIn).add(num, "day");
  const prefix = arrivalSameDay ? "Llegada" : outletSameDay ? "Salida" : "";
  const title = `Día ${num + 1}: ${prefix} ${destination}`;
  return { date, showDate, title, outletAnotherDay };
};
