import { Box, Popover, Typography } from "@mui/material";
import { Icon } from "components/icon";
import { useState } from "react";
import { IconName } from "types/Common";

type TourInfoItemProps = {
  icon: IconName;
  description: string;
  title: string;
};

export const TourInfoItem = ({ icon, description, title }: TourInfoItemProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        mb={3}
        aria-owns={open ? "mouse-over-popover" : undefined}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        aria-haspopup="true">
        {icon && <Icon name={icon} size={18} />}
        <Typography variant="caption">{title}</Typography>
        <Icon name="Info" size={15} />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none"
        }}
        PaperProps={{
          sx: {
            width: 246,
            height: "auto",
            bgcolor: "#606A78",
            clipPath: "polygon(0% 0%, 100% 0%, 100% 75%, 58% 76%, 48% 93%, 36% 75%, 0% 75%)"
          }
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <Typography padding={5} marginBottom={8} color="#fff" variant="body1">
          {description}
        </Typography>
      </Popover>
    </>
  );
};
