import { Rating, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import { ISimpleHotel } from "types/Hotel";
import { TooltipCarrousel } from "./TooltipCarrousel";

type TemplateProps = {
  data: ISimpleHotel;
};

const urlImages = [{ url: "https://via.placeholder.com/300x180/c6c6c6/" }];

export const TooltipHotelTemplate = ({ data }: TemplateProps) => {
  const { palette } = useTheme();
  return (
    <Box>
      <Box height={180}>
        <TooltipCarrousel urlImages={data?.photos ?? urlImages} />
      </Box>
      <Box p={4}>
        <Box display="flex" alignItems="center" gap={2} pb={2}>
          <Typography fontWeight="700" borderBottom={`solid 1px ${palette.primary.main}`}>
            {data.name}
          </Typography>
          <Rating
            name="read-only"
            style={{ color: palette.primary.main }}
            size="small"
            value={data.category}
            readOnly
          />
        </Box>
        <Typography fontSize={14} lineHeight={1.3}>
          {data.description}
        </Typography>
      </Box>
    </Box>
  );
};
