import { Box, Typography, useTheme } from "@mui/material";
import { CustomTheme } from "helpers/Theme";
type SiteProps = {
  description: string;
};

export const Place = ({ description }: SiteProps) => {
  const { palette } = useTheme<CustomTheme>();
  const bgRadius = palette.secondary.main;
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        px: 2
      }}>
      <Box width={9} height={9} borderRadius={100} bgcolor={bgRadius} mr={2} />
      <Typography noWrap variant="body1">
        {description}
      </Typography>
    </Box>
  );
};
