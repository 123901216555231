import { Box, Typography, CardMedia } from "@mui/material";

type ImagesItemsPros = {
  img: string;
  description: string;
};

export const ImagesItems = ({ img, description }: ImagesItemsPros) => {
  return (
    <Box m={5} sx={{ width: { xs: "100%", sm: "40%" } }} display="flex" flexDirection="column">
      <CardMedia
        component="img"
        src={img}
        sx={{ width: "100%", height: { sx: 242, sm: 215 }, borderRadius: 2 }}
      />
      <Typography variant="caption" sx={{ textAlign: { xs: "center", sm: "left" } }}>
        {description}
      </Typography>
    </Box>
  );
};
