/* eslint-disable no-useless-escape */
import { PrivateApi } from "helpers/API";
import { PostListResponse, PostResponse } from "types/Posts";

const query =
  "query pageBlogPostCollectionQuery {\npageBlogPostCollection{\nitems {\nsys {\nid\n }\nauthor {\navatar {\nurl\n}\n sys{\n id\n}\n name\n }\nfeaturedImage {\nurl\n}\n title\npublishedDate\nslug\n#add the fields you want to query\n}\n}\n}";

export const ListPosts = () => {
  return new Promise<PostListResponse>(async (res, rej) => {
    try {
      const response = await PrivateApi.post<PostListResponse>("", {
        query: query
      });
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
};

export const GetPost = (id: string) => {
  return new Promise<PostResponse>(async (res, rej) => {
    try {
      const response = await PrivateApi.post<PostResponse>("", {
        query: `query pageBlogPostEntryQuery {\n  pageBlogPost(id:\"${id}\") {\n sys {\n id\n}\ntitle\ncontent {\njson\nlinks {\nentries {\nblock {\nsys {\n  id\n            }\n            ... on ComponentRichImage {\n              image {\n                width\n                height\n                title\n                description\n                url\n              }\n              fullWidth\n              caption\n            }\n          }\n        }\n      }\n    }\n    shortDescription\n    featuredImage {\n      title\n      description\n      url\n      width\n      height\n    }\n    seoFields {\n      pageTitle\n      keyword\n      pageDescription\n      canonicalUrl\n    },\n    slug\n    author {\n      name\n      avatar {\n        title\n        description\n        width\n        height\n        url\n      }\n    }\n    publishedDate\n    relatedBlogPostsCollection(limit: 5) {\n    \titems {\n        sys {\n          id\n        }\n        title\n        featuredImage {\n          url\n        }\n        shortDescription\n        author {\n          sys {\n            id\n          }\n          name\n          avatar {\n            url\n          }\n        }\n        publishedDate\n        slug\n      }\n    }  \n}\n}`
      });
      res(response.data);
    } catch (e) {
      rej(e);
    }
  });
};
