import { Box } from "@mui/material";
import { FirstSection } from "./components/FirstSection";
import { FourthSection } from "./components/FourthSection";
import { FifthSection } from "./components/FifthSection";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { QuoteByCode } from "services/Quote";
import { IQuote } from "../../types/Quote";
import { TourSection } from "./components/TourSection";
import { LoaderIndicator } from "components/LoaderIndicator";
import { useTitleChange } from "hooks/useTitleChange";

export const Quote = () => {
  useTitleChange("Cotización");
  const { code } = useParams<{ code: string }>();
  const [quote, setQuote] = useState<IQuote>();
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const GetData = async (codeQuote: string) => {
      try {
        const data = await QuoteByCode(codeQuote);
        setQuote(data);
        setVisible(true);
      } catch (e) {
        console.error(e, "Quote->GetData");
      }
    };
    if (code) GetData(code);
  }, [code]);
  if (!visible) return <LoaderIndicator isLoading={true} />;
  else if (!quote) return null;
  return (
    <Box display="flex" alignItems="center" flexDirection="column" gap={5} mt={30} mb={20}>
      <FirstSection quote={quote} />
      {quote.tours.map((t, i) => (
        <TourSection index={i} quote={quote} key={t.id} />
      ))}
      <FourthSection quote={quote} />
      <FifthSection />
    </Box>
  );
};
