import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import OtherTourImage from "assets/OtherTour.png";
import { OtherTour } from "components/OtherTour";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../css/arrows.css";
export const OtherTours = () => {
  const { breakpoints } = useTheme();
  const isTable = useMediaQuery(breakpoints.up("sm"));
  const isTableH = useMediaQuery(breakpoints.up("md"));
  const sliderView = isTableH ? 3 : isTable ? 2 : 1;
  const spaceBetween = 0;
  const sliderOff = isTableH ? 0 : 15;
  return (
    <Box mt={25} mb={35}>
      <Typography
        fontFamily="Zilla Slab"
        fontSize="1.7rem"
        lineHeight="2rem"
        textAlign="center"
        fontWeight="400"
        mb={10}>
        Descubre Más
      </Typography>
      <Swiper
        slidesOffsetBefore={sliderOff}
        slidesPerView={sliderView}
        spaceBetween={spaceBetween}
        initialSlide={isTableH ? 1 : undefined}
        centeredSlides={!isTableH}
        navigation={false}
        pagination={{
          clickable: true
        }}
        modules={[Navigation]}>
        <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
          <OtherTour
            description="Enpalast, Bahnhof Friedrichstraße, Bundespresseamt"
            title="PERÚ IMPERDIBLE"
            image={OtherTourImage}
          />
        </SwiperSlide>
        <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
          <OtherTour
            description="Enpalast, Bahnhof Friedrichstraße, Bundespresseamt"
            title="PERÚ IMPERDIBLE"
            image={OtherTourImage}
          />
        </SwiperSlide>
        <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
          <OtherTour
            description="Enpalast, Bahnhof Friedrichstraße, Bundespresseamt"
            title="PERÚ IMPERDIBLE"
            image={OtherTourImage}
          />
        </SwiperSlide>
      </Swiper>
    </Box>
  );
};
