import { SvgIcon, SvgIconProps } from "@mui/material";

export const Culture = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5754 1.78041C14.4356 1.96461 14.3034 2.13931 14.1528 2.3387C13.9092 1.67407 13.6738 1.0335 13.4302 0.369511C14.1313 0.414452 14.812 0.45876 15.5232 0.504968C15.3789 0.699924 15.2499 0.874625 15.1177 1.05312C17.83 2.78621 19.4794 5.2181 19.8958 8.42538C20.1862 10.6693 19.7331 12.7847 18.5785 14.7323C16.3369 18.5118 11.9106 20.374 7.66585 19.3486C3.36538 18.3093 0.406908 14.6526 0.0576568 10.5288C-0.328195 5.9745 2.28103 2.52479 4.96734 1.0354C4.96996 1.06642 4.9763 1.09237 4.97429 1.11895C4.95977 1.39683 4.94077 1.67534 4.93135 1.95385C4.9281 2.05006 4.89458 2.10829 4.81426 2.16589C2.70736 3.66098 1.40475 5.6808 1.01689 8.23359C0.568326 11.1833 1.39904 13.7734 3.44509 15.9445C4.88826 17.4763 6.68206 18.3669 8.76549 18.6536C13.3582 19.2872 17.6851 16.3591 18.8195 11.8599C19.7932 8.00002 18.1286 3.93569 14.7285 1.86966C14.6831 1.84181 14.6361 1.81586 14.5754 1.78041"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.41457 13.1827C6.41457 12.9249 6.41889 12.6665 6.41395 12.4087C6.40437 11.9381 6.59478 11.5612 6.94269 11.2578C7.37663 10.8803 7.8978 10.6757 8.44106 10.5199C8.47396 10.5104 8.53789 10.5389 8.55935 10.5693C9.1894 11.4807 10.8181 11.4712 11.4355 10.5667C11.4557 10.5376 11.5139 10.5078 11.543 10.5167C12.1381 10.6909 12.7106 10.9113 13.161 11.3629C13.4418 11.6448 13.5829 11.9849 13.5817 12.3846C13.5803 12.8799 13.5696 13.3759 13.5847 13.8706C13.6013 14.421 13.3798 14.8657 13.0142 15.2489C12.5753 15.7094 12.0161 15.952 11.4038 16.0615C10.3829 16.244 9.36019 16.2528 8.34748 16.0052C7.72701 15.8532 7.18437 15.5561 6.79399 15.0304C6.5449 14.6953 6.39171 14.3254 6.41256 13.8947C6.42399 13.6578 6.41457 13.4202 6.41457 13.1827Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7328 14.757C13.9036 14.4461 13.9207 14.1251 13.915 13.7971C13.9061 13.3139 13.9011 12.8308 13.9157 12.3483C13.9314 11.7999 13.7158 11.3617 13.2919 11.0394C13.0363 10.845 12.7402 10.7032 12.4619 10.5398C12.4233 10.5164 12.381 10.5012 12.3191 10.4727C12.4012 10.4163 12.4607 10.3619 12.5303 10.3308C12.853 10.189 13.1781 10.0516 13.5052 9.9205C13.5381 9.9072 13.612 9.93316 13.6341 9.96356C14.1819 10.7443 15.5856 10.7507 16.1354 9.96102C16.155 9.93253 16.2138 9.9053 16.2435 9.91416C16.7104 10.0484 17.1582 10.2232 17.5346 10.543C17.8458 10.8076 18.0185 11.1375 18.0141 11.5555C18.009 12.038 18.0236 12.5218 18.0097 13.0037C17.997 13.4444 17.7737 13.7952 17.4676 14.0928C17.0545 14.4943 16.539 14.6887 15.9842 14.7767C15.2693 14.8907 14.5513 14.8837 13.8347 14.7849C13.8068 14.7811 13.7802 14.771 13.7328 14.757"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.22342 14.7259C5.93582 14.7505 5.69321 14.7828 5.44937 14.7904C4.8414 14.8087 4.23404 14.8119 3.63874 14.6557C3.09849 14.514 2.61715 14.2736 2.26878 13.8207C2.03963 13.524 1.90464 13.1951 1.91732 12.8074C1.93124 12.3709 1.92614 11.9338 1.91856 11.4973C1.91222 11.0988 2.07318 10.78 2.36387 10.5213C2.74255 10.1835 3.20301 10.0026 3.6825 9.86664C3.71543 9.85715 3.77743 9.88688 3.80094 9.91597C4.3449 10.6301 5.54755 10.7054 6.17656 10.0615C6.19682 10.0406 6.21646 10.0191 6.2347 9.99631C6.36211 9.84386 6.36722 9.82995 6.55648 9.90838C6.87129 10.0381 7.17775 10.1867 7.48622 10.3297C7.52612 10.348 7.55905 10.3815 7.61425 10.422C7.55782 10.4479 7.51916 10.4669 7.47988 10.4846C7.10492 10.651 6.75037 10.8496 6.46216 11.1488C6.17271 11.4505 6.01685 11.8048 6.02009 12.2273C6.02395 12.672 6.02133 13.1173 6.02071 13.562C6.02071 13.9478 5.98391 14.3381 6.22342 14.7259"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4622 8.2201C11.5021 9.00084 11.221 9.64681 10.6334 10.1549C10.177 10.5497 9.81175 10.549 9.35593 10.153C8.76068 9.63732 8.48092 8.97996 8.53347 8.19226C8.55186 7.91515 8.57706 7.63487 8.63981 7.36534C8.76826 6.81048 9.12918 6.49097 9.69908 6.42264C9.9858 6.38784 10.2727 6.39353 10.5531 6.47705C10.8886 6.57701 11.1261 6.7896 11.2672 7.1091C11.4236 7.46341 11.4471 7.84112 11.4623 8.2201"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9739 8.38466C11.97 8.33279 11.9682 8.28534 11.9624 8.23789C11.922 7.89562 11.9093 7.54703 11.8347 7.21236C11.6505 6.39055 11.1725 5.96604 10.3333 5.88379C10.0307 5.85406 9.71489 5.86861 9.4162 5.92175C8.77569 6.03499 8.38768 6.44748 8.20225 7.05989C8.08265 7.4534 8.04788 7.85893 8.03706 8.26699C8.03582 8.30052 8.0352 8.33342 8.03258 8.36695C8.03196 8.37328 8.02377 8.3796 8.00476 8.40744C7.90293 8.31507 7.79523 8.23219 7.70545 8.1335C7.44785 7.85007 7.29456 7.52615 7.30986 7.12885C7.32315 6.78026 7.32176 6.42977 7.30986 6.08118C7.2958 5.66489 7.47304 5.35173 7.79461 5.10753C8.10041 4.87408 8.45103 4.73363 8.81864 4.63367C8.8497 4.62545 8.90858 4.64696 8.92758 4.67353C9.39718 5.33845 10.6023 5.34224 11.0739 4.66973C11.0903 4.64569 11.1371 4.61975 11.1606 4.62671C11.6151 4.75894 12.053 4.92469 12.3922 5.27645C12.5923 5.48459 12.6904 5.73195 12.6892 6.02108C12.6859 6.44116 12.6898 6.86187 12.6878 7.28195C12.6859 7.66977 12.3118 8.25307 11.9739 8.38466"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35217 7.83044C6.36097 8.57389 6.09341 9.17687 5.50115 9.62357C5.17968 9.8659 4.92031 9.86653 4.59823 9.62673C4.07363 9.23508 3.78197 8.70929 3.77178 8.05126C3.76807 7.76653 3.79016 7.47612 3.84392 7.19645C3.96224 6.58461 4.33685 6.2866 4.9645 6.27078C5.08854 6.26762 5.21444 6.27331 5.33725 6.2904C5.78462 6.35367 6.09203 6.59727 6.21036 7.03574C6.28064 7.29453 6.3066 7.56533 6.35217 7.83044Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5896 7.92468C13.6138 7.71391 13.6257 7.50188 13.6631 7.29365C13.7778 6.65819 14.1558 6.33792 14.8012 6.3202C14.9217 6.31704 15.0434 6.3221 15.1626 6.33792C15.5443 6.38856 15.8613 6.56198 15.9893 6.93541C16.2449 7.681 16.2887 8.42659 15.848 9.13231C15.6914 9.38295 15.4898 9.59055 15.2343 9.74436C14.9953 9.8874 14.7587 9.88867 14.5267 9.73803C13.9947 9.39308 13.6943 8.90003 13.6124 8.27469C13.5972 8.16013 13.6105 8.04241 13.6105 7.92595L13.5896 7.92468Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1166 2.92925C11.1166 3.5302 10.8946 4.02107 10.4289 4.39429C10.1189 4.64352 9.87201 4.64162 9.56138 4.39049C9.06475 3.98818 8.85405 3.45808 8.90333 2.82488C8.91862 2.63131 8.94009 2.43458 8.98566 2.24608C9.08437 1.83807 9.35516 1.60339 9.77208 1.55658C9.93149 1.53823 10.0974 1.54329 10.2568 1.5629C10.6668 1.61414 10.9192 1.85831 11.0136 2.25367C11.0661 2.47444 11.0837 2.70406 11.1166 2.92925Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.34249 4.72626C7.35082 5.16648 7.18771 5.53069 6.86087 5.81953C6.59035 6.05895 6.37045 6.05895 6.09792 5.82016C5.72571 5.49459 5.58466 5.0759 5.62648 4.58945C5.63466 4.49063 5.64484 4.39056 5.66182 4.29301C5.75411 3.77615 6.06706 3.6064 6.52847 3.63047C7.02274 3.65644 7.24897 3.88383 7.31225 4.37852C7.32675 4.4938 7.33246 4.61035 7.34249 4.72626Z"
        fill="#606A78"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3878 4.71726C14.4138 5.18036 14.2378 5.55944 13.879 5.84943C13.6371 6.04465 13.4425 6.04528 13.2024 5.85196C12.8539 5.5727 12.6726 5.2069 12.6777 4.75832C12.6796 4.60543 12.6929 4.45127 12.7176 4.3009C12.7944 3.83338 13.1054 3.59456 13.5806 3.62804C14.1091 3.66532 14.3385 3.9073 14.3872 4.48096C14.3935 4.55931 14.3878 4.63828 14.3878 4.71726"
        fill="#606A78"
      />
    </SvgIcon>
  );
};
