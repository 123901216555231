import { ContentTitle } from "components/ContentTitle";
import HomeBackground from "assets/HomeBackground.png";
import { BreadCrumbs } from "components/BreadCrumbs";
type HeaderPostDetailProps = {
  title?: string;
  id?: string;
  slug?: string;
};
export const HeaderPostDetail = ({ title, id, slug }: HeaderPostDetailProps) => {
  return (
    <>
      <ContentTitle title={title} img={HomeBackground} />
      <BreadCrumbs
        items={[
          {
            label: "Home",
            to: "./"
          },
          {
            label: "Noticias",
            to: "/blog"
          },
          {
            label: `${title}`,
            to: `/blog/${slug}/${id}`
          }
        ]}
        pl={{ lg: 41, md: 4, sm: 10, xs: 4 }}
      />
    </>
  );
};
