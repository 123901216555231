import { Box, Button, Typography, Link } from "@mui/material";
import { useTheme } from "@mui/material";
import { CustomTheme } from "../../../helpers/Theme";
import { Link as RouterLink } from "react-router-dom";
import { DestinationMenuMobile } from "components/DestinationMenuMobile";
import { useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IDestination } from "types/Destination";
import { GetAllDestinations } from "services/Destination";

export const MobileContent = ({
  isTransparent,
  setShowContent
}: {
  isTransparent: boolean;
  setShowContent: (b: boolean) => void;
}) => {
  const showContent = () => setShowContent(false);
  const { palette, transitions } = useTheme<CustomTheme>();
  const borderColor = isTransparent ? palette.neutral["100"] : palette.neutral["400"];
  const color = isTransparent ? "common.white" : palette.neutral["900"];
  const bgColor = isTransparent ? "#797979" : "common.white";
  const transition = transitions.create("background-color", {
    easing: transitions.easing.easeInOut,
    duration: transitions.duration.short
  });
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    const GetData = async () => {
      try {
        const data = await GetAllDestinations();
        setDestinations(data);
      } catch (e) {
        console.error(e, "MobileContent->GetData");
      }
    };
    GetData();
  }, []);
  const handleClose = () => {
    setOpen(false);
    showContent();
  };
  const toggleMenu: React.MouseEventHandler<SVGSVGElement> = e => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(v => !v);
  };
  return (
    <Box
      zIndex={10}
      top={0}
      pt="62px"
      position="fixed"
      width="100%"
      height="100%"
      sx={{ transition }}
      bgcolor={bgColor}>
      <Box
        m={5}
        borderBottom={1}
        borderColor={borderColor}
        display="flex"
        flex={1}
        flexDirection="column">
        <Link
          aria-label="nosotros"
          component={RouterLink}
          to="/nosotros"
          underline="none"
          color={color}
          sx={{ mb: 7 }}
          variant="body1"
          onClick={showContent}>
          Nosotros
        </Link>

        <Link
          aria-label="destinos"
          component={RouterLink}
          to="/destinos"
          underline="none"
          color={color}
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            mb: 7,
            "&:hover": { cursor: "pointer" }
          }}
          variant="body1"
          onClick={showContent}>
          Destinos
          {!destinations.length ? null : isOpen ? (
            <KeyboardArrowUp
              onClick={toggleMenu}
              htmlColor={palette.secondary.main}
              fontSize="small"
            />
          ) : (
            <KeyboardArrowDown
              onClick={toggleMenu}
              htmlColor={palette.secondary.main}
              fontSize="small"
            />
          )}
        </Link>

        {destinations.length > 0 && isOpen ? (
          <DestinationMenuMobile
            destinations={destinations}
            onClose={handleClose}
            isTransparent={isTransparent}
          />
        ) : null}

        <Link
          aria-label="tour"
          component={RouterLink}
          to="/tour"
          underline="none"
          color={color}
          sx={{ mb: 7 }}
          variant="body1"
          onClick={showContent}>
          Tours
        </Link>
        <Link
          aria-label="blog"
          component={RouterLink}
          to="/blog"
          underline="none"
          color={color}
          sx={{ mb: 7 }}
          variant="body1"
          onClick={showContent}>
          Blog
        </Link>
      </Box>
      <Box
        m={5}
        borderBottom={1}
        borderColor={borderColor}
        flex={1}
        display="flex"
        alignItems="center"
        flexDirection="column">
        <Typography color={color} variant="body1">
          Comuniquese con nosotros
        </Typography>
        <Link
          to="/contactanos"
          aria-label="contactanos"
          underline="none"
          component={RouterLink}
          onClick={showContent}>
          <Button
            aria-label="contactanos"
            color="secondary"
            variant="outlined"
            sx={{ borderColor: color, borderRadius: 0, width: 160, color: color, marginBlock: 5 }}>
            Contacto
          </Button>
        </Link>
      </Box>
    </Box>
  );
};
