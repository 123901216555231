import { SvgIcon, SvgIconProps } from "@mui/material";
export const Path = (props: SvgIconProps) => {
  return (
    <SvgIcon
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.1898 40.8311L20.3987 40.9256C25.0647 33.5055 29.7286 26.0854 34.5056 18.4879C34.2514 18.6016 34.2233 18.607 34.2064 18.6238C29.6277 23.524 25.0464 28.4241 20.4785 33.335C20.3214 33.5047 20.2045 33.7842 20.2022 34.0145C20.1823 36.2865 20.1898 38.5592 20.1898 40.8311ZM8.39042 31.7672C8.75334 31.8662 8.88643 31.9338 9.01914 31.9338C11.9592 31.9399 14.8992 31.9522 17.8388 31.92C18.1309 31.9169 18.4908 31.7004 18.6988 31.4723C23.8755 25.7791 29.0365 20.0704 34.1963 14.361C34.3467 14.1951 34.452 13.9871 34.5772 13.7989C34.5142 13.7398 34.4513 13.6807 34.3875 13.6208C25.7711 19.6351 17.1565 25.6493 8.39042 31.7672ZM42.6846 14.4178C42.7307 14.0654 42.6662 13.5317 41.9996 13.3283C41.357 13.1325 40.9922 13.4987 40.6634 14.0001C36.2002 20.7852 31.7257 27.5634 27.2576 34.3455C26.6495 35.2699 26.7747 35.7421 27.7659 36.2788C31.2601 38.1707 34.7546 40.058 38.2528 41.9414C39.3929 42.5556 39.9864 42.2639 40.1168 40.9693C40.5723 36.4708 41.0012 31.9691 41.4369 27.469C41.8481 23.1969 42.2538 18.9248 42.6846 14.4178ZM40.8295 45.7712C40.4111 45.5685 40.0763 45.4257 39.7592 45.2522C35.0117 42.6555 30.2598 40.0664 25.5262 37.4428C24.869 37.0789 24.5234 37.0935 24.0967 37.7829C22.6383 40.1309 21.1143 42.4381 19.5934 44.7454C19.4085 45.0249 19.085 45.2122 18.8256 45.4418C18.7449 45.127 18.5991 44.8122 18.5954 44.4959C18.5538 41.1536 18.5054 37.8113 18.5301 34.4706C18.5376 33.5823 18.2527 33.3742 17.3968 33.3711C13.4209 33.3588 9.44467 33.2774 5.46879 33.1976C5.16324 33.1915 4.86069 33.0118 4.55664 32.9127C4.76396 32.6663 4.92892 32.3599 5.18385 32.181C18.2561 22.9827 31.3362 13.7943 44.4219 4.61367C44.6847 4.43016 45.0217 4.35492 45.325 4.229C45.3655 4.55224 45.4701 4.88241 45.4364 5.19797C44.7852 11.2091 44.1148 17.218 43.4524 23.2276C42.8435 28.7512 42.2369 34.2748 41.6288 39.7984C41.4399 41.5153 41.2532 43.2321 41.053 44.9481C41.0237 45.1915 40.9255 45.4265 40.8295 45.7712Z"
        fill="#606A78"
      />
    </SvgIcon>
  );
};
