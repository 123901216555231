import { Box, IconButton } from "@mui/material";
import { ArrowCircleLeft, ArrowCircleRight } from "icons";
import { useRef, useState, useCallback } from "react";
import { useResizeDetector } from "react-resize-detector";
import { IEnumData } from "types/Common";
import { Place } from "../Place/Place";
const scrollStep = 120;

export const TourPlaceList = ({ places }: { places?: IEnumData[] }) => {
  const [showButtons, setShowButtons] = useState(false);
  const listScrollRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback(() => {
    setShowButtons(
      (listScrollRef.current?.scrollWidth ?? 0) > (listScrollRef.current?.offsetWidth ?? 0)
    );
  }, []);
  useResizeDetector({ onResize, targetRef: listScrollRef });

  const handleScroll = (step: number, dir: boolean) => {
    if (listScrollRef.current) {
      const op = dir ? 1 : -1;
      listScrollRef.current.scrollLeft += step * op;
    }
  };

  return (
    <Box display="flex" alignItems="center" mt={5}>
      {showButtons && (
        <IconButton onClick={() => handleScroll(scrollStep, false)}>
          <ArrowCircleLeft />
        </IconButton>
      )}
      <Box
        ref={listScrollRef}
        bgcolor={"white"}
        display="flex"
        sx={{
          overflowX: "hidden",
          scrollBehavior: "smooth",
          width: "100%"
        }}>
        {places?.map(p => (
          <Place description={p.name} key={p.id} />
        ))}
      </Box>
      {showButtons && (
        <IconButton onClick={() => handleScroll(scrollStep, true)}>
          <ArrowCircleRight />
        </IconButton>
      )}
    </Box>
  );
};
