import { Author } from "./components/Author";
import { Box, Button, Typography } from "@mui/material";
import { SocialMedia } from "./components/SocialMedia";
import { AddCircle } from "icons";
import { HeaderPostDetail } from "./components/HeaderPostDetail";
import { RelatedPosts } from "./components/RelatedPosts";
import { PostRichContent } from "./components/PostRichContent";
import { ParamsType } from "types/Common";
import { useParams } from "react-router-dom";
import { GetPost } from "services/Contentful";
import { PageBlogPost } from "types/Posts";
import { useEffect, useState } from "react";
import { LoaderIndicator } from "components/LoaderIndicator";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { HelmetSEO } from "./components/HelmetSEO";
dayjs.locale("es");
dayjs.extend(localizedFormat);

export const DetailPost = () => {
  const { id } = useParams<ParamsType>();

  const [data, setData] = useState<PageBlogPost>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const GetData = async (idPost: string) => {
      try {
        const data = await GetPost(idPost);
        setData(data.data.pageBlogPost);
      } catch (e) {
        console.error(e, "GetPost->GetData");
      }
      setLoading(false);
    };
    if (id) GetData(id);
  }, [id]);
  return (
    <>
      {!loading && (
        <HelmetSEO
          title={data?.seoFields.pageTitle}
          description={data?.seoFields.pageDescription}
          keyword={data?.seoFields.keyword}
        />
      )}
      <LoaderIndicator isLoading={loading} />
      <HeaderPostDetail title={data?.title} slug={data?.slug} id={data?.sys.id} />
      <Box display="flex" justifyContent="center" mt={10} mb={30}>
        <Box
          width={{ lg: "80%", md: "97%", sm: "95%", xs: "92%" }}
          display="flex"
          flexWrap={{ lg: "nowrap", sm: "nowrap", xs: "wrap" }}
          justifyContent="space-between"
          gap={{ lg: 20, sm: 5, xs: 10 }}>
          <Author
            avatar={data?.author.avatar.url}
            name={data?.author.name}
            date={dayjs(data?.publishedDate).format("DD/MM/YYYY")}
          />
          <Box display="flex" flexDirection="column" overflow="hidden" alignItems="center" gap={10}>
            <Typography variant="h2">{data?.title}</Typography>
            <PostRichContent data={data} />
            <Button endIcon={<AddCircle />}>
              <Typography variant="body2" sx={{ textTransform: "none" }}>
                Leer Más
              </Typography>
            </Button>
            <SocialMedia />
            <RelatedPosts item={data?.relatedBlogPostsCollection} />
          </Box>
        </Box>
      </Box>
    </>
  );
};
