import { SvgIcon, SvgIconProps } from "@mui/material";
export const Whatsapp = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0027 13.985C16.7544 13.8609 15.5369 13.2625 15.3102 13.1792C15.0836 13.0967 14.9186 13.0559 14.7527 13.3042C14.5877 13.5509 14.1136 14.1092 13.9694 14.2742C13.8244 14.44 13.6802 14.46 13.4327 14.3367C13.1852 14.2117 12.3869 13.9509 11.4411 13.1075C10.7052 12.4509 10.2077 11.64 10.0636 11.3917C9.9194 11.1442 10.0477 11.01 10.1719 10.8867C10.2836 10.7759 10.4194 10.5975 10.5436 10.4534C10.6677 10.3084 10.7086 10.205 10.7911 10.0392C10.8744 9.8742 10.8327 9.73004 10.7702 9.60587C10.7086 9.4817 10.2136 8.26254 10.0069 7.7667C9.80607 7.2842 9.6019 7.35004 9.45024 7.3417C9.30524 7.33504 9.14024 7.33337 8.97524 7.33337C8.81024 7.33337 8.5419 7.39504 8.31524 7.64337C8.08774 7.89087 7.44857 8.49004 7.44857 9.7092C7.44857 10.9275 8.33524 12.105 8.4594 12.2709C8.58357 12.4359 10.2052 14.9375 12.6894 16.01C13.2811 16.265 13.7419 16.4175 14.1011 16.5309C14.6944 16.72 15.2344 16.6934 15.6611 16.6292C16.1361 16.5584 17.1261 16.03 17.3327 15.4517C17.5386 14.8734 17.5386 14.3775 17.4769 14.2742C17.4152 14.1709 17.2502 14.1092 17.0019 13.985H17.0027ZM12.4844 20.1542H12.4811C11.0056 20.1545 9.55721 19.7578 8.28774 19.0059L7.98774 18.8275L4.8694 19.6459L5.7019 16.6059L5.50607 16.2942C4.68118 14.9812 4.24465 13.4615 4.2469 11.9109C4.24857 7.3692 7.94357 3.6742 12.4877 3.6742C14.6877 3.6742 16.7561 4.53254 18.3111 6.0892C19.0781 6.85302 19.686 7.76134 20.0996 8.76162C20.5133 9.76189 20.7245 10.8343 20.7211 11.9167C20.7194 16.4584 17.0244 20.1542 12.4844 20.1542ZM19.4944 4.9067C18.5762 3.98247 17.4838 3.24965 16.2803 2.7507C15.0769 2.25175 13.7864 1.9966 12.4836 2.00003C7.0219 2.00003 2.57523 6.44587 2.57357 11.91C2.57273 13.6567 3.02857 15.3617 3.89607 16.8642L2.49023 22L7.74357 20.6217C9.1968 21.4135 10.8253 21.8283 12.4802 21.8284H12.4844C17.9461 21.8284 22.3927 17.3825 22.3944 11.9175C22.3984 10.6153 22.1442 9.32512 21.6464 8.12173C21.1487 6.91834 20.4172 5.82559 19.4944 4.9067Z"
          fill="#606A78"
        />
      </svg>
    </SvgIcon>
  );
};
