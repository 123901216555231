import { Header } from "../components/Header/Header";
import { NavigateFunction, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Home } from "../pages/Home";
import { FrequentQuestions } from "../pages/FrequentQuestions";
import { PrivacyPolicy } from "../pages/PrivacyPolicy";
import { TipsRecommendations } from "../pages/TipsRecommendations";
import { Package } from "../pages/Package";
import { TermsConditions } from "../pages/TermsConditions";
import { Contact } from "../pages/Contact";
import { Footer } from "../components/Footer";
import { Box } from "@mui/material";
import { About } from "../pages/About";
import { Tour } from "../pages/Tour";
import { Destination } from "../pages/Destination";
import { Offer } from "../pages/Offers";
import { Test } from "../pages/Test";
import { Quote } from "../pages/Quote/Quote";
import { useEffect } from "react";
import { NotFound } from "pages/NotFound";
import { ServerError } from "pages/ServerError";
import { ClientError } from "pages/ClientError";
import { NewsPost } from "pages/NewsPost";
import { DetailPost } from "pages/DetailPost";
import { ContentError } from "pages/ContentError";
// eslint-disable-next-line @typescript-eslint/no-empty-function
export let Navigate: NavigateFunction = () => {};
export const RootNavigator = () => {
  Navigate = useNavigate();
  const location = useLocation();
  const isHome = location.pathname === "/";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Box width="100%" height="100%">
      <Header isTransparent={isHome} />

      <Routes>
        <Route index element={<Home />} />
        <Route path="/preguntas-frecuentes" element={<FrequentQuestions />} />
        <Route path="/politicas-de-privacidad" element={<PrivacyPolicy />} />
        <Route path="/consejos-recomendaciones" element={<TipsRecommendations />} />
        <Route path="/terminos-condiciones" element={<TermsConditions />} />
        <Route path="/contactanos" element={<Contact />} />
        <Route path="/nosotros" element={<About />} />
        <Route path="/blog" element={<NewsPost />} />
        <Route path="/blog/:slug/:id" element={<DetailPost />} />
        <Route path="/paquete/:id" element={<Package />} />
        <Route path="/tour" element={<Tour />} />
        <Route path="/destinos">
          <Route index element={<Destination />} />
          <Route path=":id/paquetes" element={<Offer />} />
        </Route>
        <Route path="/test" element={<Test />} />
        <Route path="/cotizacion/:code" element={<Quote />} />
        <Route path="/500" element={<ServerError />} />
        <Route path="/400" element={<ClientError />} />
        <Route path="/204" element={<ContentError />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {!isHome && <Footer />}
    </Box>
  );
};
