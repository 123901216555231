import { Box, Typography, Button, BoxProps } from "@mui/material";
import { Dislike } from "icons";
import { Like } from "icons/Like";
import { useState } from "react";
export const Reactions = (props: BoxProps) => {
  const [like, setLike] = useState<boolean>();
  return (
    <Box display="flex" alignItems="center" gap={1} my={1} justifyContent="center" {...props}>
      <Button
        variant="text"
        disableRipple
        startIcon={<Like active={like === true} />}
        onClick={() => setLike(true)}>
        {like === true && (
          <Typography variant="caption" sx={{ textTransform: "capitalize" }} color="secondary">
            Like
          </Typography>
        )}
      </Button>
      <Typography variant="caption" color="secondary">
        ¿Te gusto este tour ?
      </Typography>
      <Button
        variant="text"
        disableRipple
        startIcon={<Dislike active={like === false} />}
        onClick={() => setLike(false)}>
        {like === false && (
          <Typography variant="caption" sx={{ textTransform: "capitalize" }} color="secondary">
            Dislike
          </Typography>
        )}
      </Button>
    </Box>
  );
};
