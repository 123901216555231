import { Card, Box, Typography, CardMedia, CardContent, Link, SxProps } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PackageActions } from "state/actions/PackageActions";
import { RootDispatch, RootState } from "state/store";
import { IPackage } from "types/package";
import { CustomTheme } from "../../helpers/Theme";
const getStyles = (inverted: boolean) => {
  const image: SxProps<CustomTheme> = {
    borderRadius: {
      xs: 3.75,
      md: 5
    },
    width: {
      xs: "100%",
      sm: "61vw",
      md: "62vw",
      lg: "44vw",
      xl: "50vw"
    },
    height: {
      xs: 488,
      sm: 596,
      md: 789
    }
  };
  const container: SxProps<CustomTheme> = {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexDirection: {
      xs: "column",
      sm: inverted ? "row-reverse" : "row"
    }
  };
  const card: SxProps<CustomTheme> = {
    display: "flex",
    ...(inverted
      ? { left: { lg: "11%", md: "30%", sm: "calc(61vw / 1.7)", xs: 0 } }
      : { right: { lg: "11%", md: "30%", sm: "calc(61vw / 1.7)", xs: 0 } }),
    borderRadius: {
      xs: 5,
      sm: 1.25,
      md: 5
    },
    position: {
      sm: "relative"
    },
    width: {
      xs: "calc(100% - 28px)",
      sm: 450,
      md: 550
    },
    minWidth: {
      sm: 450,
      md: 550
    },
    height: {
      xs: "auto",
      sm: 460,
      md: 500
    },
    padding: {
      xs: 3.5,
      sm: 7.5,
      md: 12.5
    },
    paddingBottom: {
      xs: 0,
      sm: 1.5,
      md: 6.5
    },
    marginTop: {
      xs: -11.5,
      sm: 0
    },
    opacity: 0.95,
    boxShadow: "4px 65px 59px rgba(0, 0, 0, 0.05)",
    boxSizing: "border-box"
  };
  return { image, container, card };
};
type TourCardProps = {
  inverted?: boolean;
  description: string;
  title: string;
  image: string;
  id?: string;
};

export const TourCard = ({ inverted, description, title, image, id }: TourCardProps) => {
  const styles = getStyles(!!inverted);
  const navigate = useNavigate();
  const dispatch = useDispatch<RootDispatch>();
  const pack = useSelector(
    (state: RootState) => state.PackageReducer.packages.find(p => p.id === id) as IPackage
  );
  const goToPack = () => {
    dispatch(PackageActions.SetPackageSelected(pack));
    navigate(`/paquete/${id}`);
  };
  return (
    <Box sx={styles.container}>
      <CardMedia component="img" image={image} alt="Offert" sx={styles.image} />
      <Card elevation={0} sx={styles.card}>
        <CardContent sx={{ p: 0, display: "flex", flexDirection: "column", pb: 0 }}>
          <Typography variant="subtitle2" color="common.black">
            {title}
          </Typography>
          <Box mt={7.5}>
            <Typography
              variant="body1"
              whiteSpace="pre-line"
              color="#070707CC"
              overflow="hidden"
              textOverflow="ellipsis"
              maxHeight={{ md: 300, xs: "auto" }}>
              {description}
            </Typography>
          </Box>
          <Box flex={1} display="flex" justifyContent="end" alignItems="end" mt={7.5}>
            <Link
              onClick={goToPack}
              aria-label="TourCard"
              variant="button"
              underline="hover"
              sx={{ textUnderlineOffset: 10, cursor: "pointer" }}>
              Ver Itinerario
            </Link>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
