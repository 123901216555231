import { RichContent } from "types/Posts";

export const resolveRichContent = (content: RichContent | undefined) => {
  const resources = content?.links.entries.block;
  const resolvedContent = content?.json.content.map(c => {
    if (c.nodeType.startsWith("embedded") && c.data.target) {
      c.data.target.fields = resources?.find(r => r.sys.id === c.data.target?.sys.id)?.image;
      return c;
    } else return c;
  });
  return { ...content, content: resolvedContent };
};
